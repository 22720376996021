import Stack from '@mui/material/Stack'
import { FormEvent, useRef } from 'react'

import Button from '../../components/Button'
import Input from '../../components/Input'

interface PasswordFormProps {
  loading: boolean
  onSubmit: (password: string) => void
}

export default function PasswordForm({ loading, onSubmit }: PasswordFormProps) {
  const passwordRef = useRef<HTMLInputElement>(null)

  function submitPassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const passwordValue = passwordRef.current?.value

    if (passwordValue) {
      onSubmit(passwordValue)
    }
  }

  return (
    <form onSubmit={submitPassword}>
      <Stack spacing={2}>
        <Input
          label="Scan your QR code or type to input:"
          inputRef={passwordRef}
          placeholder="Password"
          fullWidth
          autoFocus
        />
        <Button type="submit" variant="contained" loading={loading} fullWidth>
          login
        </Button>
      </Stack>
    </form>
  )
}
