import { createContext, useContext, useState } from 'react'

import { CurrentStep, InspectionAnswers, InspectionSteps } from '../models/ReturnPackage'
interface InspectionQuestionsProviderProps {
  children: React.ReactNode
}

interface InspectionQuestionsContextData {
  currentStep: CurrentStep
  setCurrentStep: (currentStep: CurrentStep) => void
  setInspectionAnswers: React.Dispatch<React.SetStateAction<InspectionAnswers>>
  inspectionAnswers: InspectionAnswers
}

const InspectionQuestionsContext = createContext<InspectionQuestionsContextData | undefined>(
  undefined,
)

const initialInspectionAnswers: InspectionAnswers = {
  packageEmpty: null,
  doesItemDescriptionMatch: null,
  doesItemQuantityMatch: null,
  returnedItemQuantity: null,
  packageDamaged: null,
  comments: {
    packageDescription: '',
    packageDamaged: '',
  },
}

function InspectionQuestionsProvider({ children }: InspectionQuestionsProviderProps) {
  const [currentStep, setCurrentStep] = useState<CurrentStep>(InspectionSteps.PACKAGE_EMPTY)
  const [inspectionAnswers, setInspectionAnswers] =
    useState<InspectionAnswers>(initialInspectionAnswers)

  const data: InspectionQuestionsContextData = {
    currentStep,
    setCurrentStep,
    setInspectionAnswers,
    inspectionAnswers,
  }

  return (
    <InspectionQuestionsContext.Provider value={data}>
      {children}
    </InspectionQuestionsContext.Provider>
  )
}

function useInspectionQuestions() {
  const inspectionQuestionsContext = useContext(InspectionQuestionsContext)

  if (inspectionQuestionsContext === undefined) {
    throw new Error('useInspectionQuestion must be used within a InspectionQuestionProvider')
  }

  return inspectionQuestionsContext
}
export { InspectionQuestionsProvider, useInspectionQuestions }
