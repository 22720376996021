import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useBinFloorLocationsQuery() {
  const { binsGateway } = useGateways()

  return useQuery({
    queryKey: ['binFloorLocations'],
    queryFn: binsGateway.getBinFloorLocations,
    staleTime: Infinity,
  })
}
