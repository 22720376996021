import axios from 'axios'

import { isApiError } from '@/core/models/ApiError'
import { PackageMeasurements, PackageMeasurementsMetadata } from '@/core/models/Package'
import { MeasurementsResponse } from '@/gateways/api/models/Package'
import InductionResult, { InductionExceptionResult } from '@/induction/models/Induction'
import InductionsGateway from '@/induction/ports/induction'
import { WeightDimensionsFormData } from '@/induction/WeightDimensionsForm'
import { WeightFormData } from '@/induction/WeightForm'

import { InductionPackageResponse } from './models/Induction'

function scan(barcode: string): Promise<InductionResult> {
  return axios
    .post<InductionPackageResponse>(`/v1/inductions/packages/${barcode}/scan`)
    .then(({ data }) => ({
      status: data.package.instruction.slug,
      label: data.package.instruction.description,
      package: {
        id: data.package.id,
        firstMileTrackingNumber: data.package.first_mile_tracking_number,
        warehouseState: {
          value: data.package.warehouse_state,
          displayName: data.package.warehouse_state_display_name,
        },
        weight: {
          value: data.package.weight.value ?? 0,
          unit: data.package.weight.unit ?? 'lb',
        },
      },
    }))
    .catch((reason): InductionExceptionResult => {
      if (isApiError(reason) && reason.error.code === 'resource_not_found_error') {
        return { status: 'exception', label: 'Exception', message: reason.error.detail[0] }
      }
      return {
        status: 'call_problem_solver',
        label: 'Call a Problem Solver',
        message: isApiError(reason) ? reason.error.detail[0] : '',
      }
    })
}

function measureWeightDimensions(
  packageId: string,
  formData: WeightDimensionsFormData,
  metadata: PackageMeasurementsMetadata | undefined,
): Promise<InductionResult> {
  return axios
    .post<InductionPackageResponse>(`/v1/inductions/packages/${packageId}/measure`, {
      package: {
        weight: { value: formData.weight, unit: 'lb' },
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
          unit: 'in',
        },
        metadata: {
          measurement_id: metadata?.measurementId ?? null,
          scanned_on: metadata?.scannedOn ?? null,
        },
      },
    })
    .then(({ data }) => ({
      status: data.package.instruction.slug,
      label: data.package.instruction.description,
      package: {
        id: data.package.id,
        firstMileTrackingNumber: data.package.first_mile_tracking_number,
        warehouseState: {
          value: data.package.warehouse_state,
          displayName: data.package.warehouse_state_display_name,
        },
        weight: {
          value: data.package.weight.value ?? 0,
          unit: data.package.weight.unit ?? 'lb',
        },
      },
    }))
}

function measureWeight(packageId: string, formData: WeightFormData): Promise<InductionResult> {
  return axios
    .post<InductionPackageResponse>(`/v1/inductions/packages/${packageId}/measure`, {
      package: {
        weight: { value: formData.weight, unit: 'lb' },
        dimensions: {
          length: null,
          width: null,
          height: null,
          unit: null,
        },
        metadata: {
          measurement_id: null,
          scanned_on: null,
        },
      },
    })
    .then(({ data }) => ({
      status: data.package.instruction.slug,
      label: data.package.instruction.description,
      package: {
        id: data.package.id,
        firstMileTrackingNumber: data.package.first_mile_tracking_number,
        warehouseState: {
          value: data.package.warehouse_state,
          displayName: data.package.warehouse_state_display_name,
        },
        weight: {
          value: data.package.weight.value ?? 0,
          unit: data.package.weight.unit ?? 'lb',
        },
      },
    }))
}

function getPackageMeasurements(signal?: AbortSignal): Promise<PackageMeasurements> {
  return axios
    .get<MeasurementsResponse>('/v1/dimensioner/measurements', { signal })
    .then(({ data: { measurements } }) => ({
      weight: {
        value: measurements.weight,
        unit: measurements.weight_unit,
      },
      dimensions: {
        length: measurements.length,
        width: measurements.width,
        height: measurements.height,
        unit: measurements.dimension_unit,
      },
      measurementId: measurements.metadata.measurement_id,
      scannedOn: measurements.metadata.scanned_on,
    }))
}

function markAsUndersized(packageId: string): Promise<void> {
  return axios.post(`/v1/inductions/packages/${packageId}/mark_as_undersize`)
}

function markAsOversized(packageId: string): Promise<void> {
  return axios.post(`/v1/inductions/packages/${packageId}/mark_as_oversize`)
}

export default function createApiInductionsGateway(): InductionsGateway {
  return {
    scan,
    measureWeightDimensions,
    measureWeight,
    getPackageMeasurements,
    markAsUndersized,
    markAsOversized,
  }
}
