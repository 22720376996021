import Box from '@mui/material/Box'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import MuiFormLabel from '@mui/material/FormLabel'

interface FormLabelProps extends FormControlProps {
  label?: React.ReactNode
  noControl?: boolean
  children: JSX.Element
}

export default function FormLabel({
  label,
  noControl = false,
  children,
  ...props
}: FormLabelProps) {
  if (!label) {
    return children
  }

  return (
    <FormControl {...props}>
      <MuiFormLabel>
        <Box mb={0.5}>{label}</Box>
        {!noControl && children}
      </MuiFormLabel>
      {noControl && children}
    </FormControl>
  )
}
