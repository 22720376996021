import { Repack } from '@/core/models/Repack'
import { RepackDataResponse } from '@/gateways/api/models/Repack'

export function mapRepackFromApi(repack: RepackDataResponse): Repack {
  return {
    id: repack.id,
    ebayPackingMaterialId: repack.ebay_packing_material_id,
    packageId: repack.package_id,
    upc: repack.upc,
    height: repack.height,
    width: repack.width,
    length: repack.length,
    unit: repack.unit,
    typeOf: repack.type_of,
    reason: repack.reason,
  }
}
