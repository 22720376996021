import { useQuery } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

export default function useGetAllReadyForXRayQuery() {
  const { courierGateway } = useGateways()

  const query = useQuery({
    queryKey: ['AllReadyForXRay'],
    queryFn: () => courierGateway.getAllReadyForXRay(),
    onError: toastApiError,
  })

  return query
}
