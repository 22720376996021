import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useBinTypesQuery() {
  const { binsGateway } = useGateways()

  return useQuery({
    queryKey: ['binTypes'],
    queryFn: binsGateway.getBinTypes,
    staleTime: Infinity,
  })
}
