import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

import PackingIcon from '@/components/icons/PackingIcon'

import { usePackage } from '../PackageProvider'

export default function PlaceInBin() {
  const navigate = useNavigate()
  const { pack, bin } = usePackage()
  const packageHasForcedPlaceInBin = !!pack.bin

  if (
    !bin ||
    packageHasForcedPlaceInBin ||
    pack.warehouseState.value === 'ready_for_destroy_liquidate'
  ) {
    return null
  }

  return (
    <Stack
      data-testid="place-in-bin"
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        bgcolor: bin.bgColor,
        p: 2,
        borderRadius: 2,
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <PackingIcon htmlColor={bin.textColor} sx={{ fontSize: 40 }} />
        <Stack spacing={1}>
          <Typography variant="h2" color="ink.main">
            Place in Bin
          </Typography>
          <Typography variant="h1" color={bin.textColor}>
            {bin.name}
          </Typography>
        </Stack>
      </Stack>
      <Button
        onClick={() => navigate('/scan')}
        variant="contained"
        color="secondary"
        sx={{ minWidth: 100, height: 'initial' }}
      >
        back to scan
      </Button>
    </Stack>
  )
}
