import { useMemo } from 'react'

import CourierBin from './models/CourierBin'

export const ALL_BINS_FILTER: FilterLocation = {
  id: -1,
  name: 'All',
  binsCount: 0,
}

export interface FilterLocation {
  name: string
  binsCount: number
  id: number
}

export function useCourierBinsLocationFilters(courierBins: CourierBin[]) {
  const filters = useMemo(() => {
    function generateFilters(bins: CourierBin[]): FilterLocation[] {
      const allLocationsFilter: FilterLocation = { ...ALL_BINS_FILTER, binsCount: bins.length }
      const locationFilters = courierBins.reduce<FilterLocation[]>((filters, bin) => {
        const filterFound = filters.find((filter) => filter.name === bin.floorLocation.name)

        if (filterFound) {
          filterFound.binsCount++
        } else {
          filters.push({ ...bin.floorLocation, binsCount: 1 })
        }
        return filters
      }, [])
      return [allLocationsFilter, ...locationFilters]
    }

    return generateFilters(courierBins)
  }, [courierBins])

  return { filters }
}
