import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

import Button from '@/components/Button'
import Dialog, { DialogProps } from '@/components/Dialog'
import MultiScanInput from '@/components/MultiScanInput'
import BinDetails from '@/core/models/BinDetails'

import { useBin } from './BinProvider'

interface RemovePackagesDialogProps extends Omit<DialogProps, 'title'> {
  bin: BinDetails
}

export default function RemovePackagesDialog({ bin, ...props }: RemovePackagesDialogProps) {
  const { removePackages } = useBin()
  const [barcodes, setBarcodes] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsLoading(true)
    await removePackages(barcodes)
    setIsLoading(false)
    props.onClose?.()
  }

  useEffect(() => {
    props.open && setBarcodes([])
  }, [props.open])

  return (
    <Dialog title="Remove Packages from Bin" {...props} maxWidth="xs" fullWidth>
      <Stack spacing={2}>
        <Typography variant="h4">
          Scan to remove packages from bin <strong>{bin.name}</strong>
        </Typography>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <MultiScanInput value={barcodes} onChange={setBarcodes} autoFocus fullWidth />
            <Button
              type="submit"
              variant="contained"
              color="error"
              sx={{ alignSelf: 'center', height: 60 }}
              disabled={barcodes.length === 0}
              loading={isLoading}
            >
              remove {barcodes.length} packages
            </Button>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  )
}
