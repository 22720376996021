import { FormControlLabel, Radio } from '@mui/material'
import { Button } from 'easyship-components'
import { Exclaim } from 'easyship-components/icons'
import { useState } from 'react'

import Divider from '@/components/Divider'
import RadioGroup from '@/components/RadioGroup'
import { usePackage } from '@/pages/PackagePage/PackageProvider'

export interface MarkAsCourierFormData {
  reasonForReturn: 'duplicate_package' | 'other' | null
  matchingDescription: boolean | null
}

const defaultCourierReturnFormData: MarkAsCourierFormData = {
  reasonForReturn: null,
  matchingDescription: null,
}

export function MarkAsCourierReturn() {
  const { markAsCourierReturn, setBin, bin } = usePackage()
  const [showReasonForReturn, setShowReasonForReturn] = useState(false)
  const [formData, setFormData] = useState<MarkAsCourierFormData>(defaultCourierReturnFormData)

  const isFormDataValid = validateFormData(formData)

  function handleMarkAsCourierReturn() {
    setShowReasonForReturn((prevState) => !prevState)
  }
  function handleSubmit() {
    if (formData.matchingDescription === false) {
      setBin('exception')
    } else {
      markAsCourierReturn()
    }
  }

  function validateFormData(formData: {
    reasonForReturn: string | null
    matchingDescription: boolean | null
  }) {
    if (
      formData.reasonForReturn === 'other' ||
      (formData.reasonForReturn === 'duplicate_package' && formData.matchingDescription !== null)
    ) {
      return true
    }
    return false
  }

  if (bin) return null
  return (
    <div>
      <div className="flex justify-center">
        <Button
          onClick={handleMarkAsCourierReturn}
          color="primary"
          className="h-[80px] w-[270px] self-center"
        >
          Mark as courier return
        </Button>
      </div>
      <Divider />
      {showReasonForReturn && (
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <RadioGroup
            label="What is the Reason for Return?"
            value={formData.reasonForReturn}
            onChange={(e) =>
              setFormData({
                ...defaultCourierReturnFormData,
                reasonForReturn: e.target.value as 'duplicate_package' | 'other',
              })
            }
            className="!grid w-full grid-flow-row auto-rows-max grid-cols-2 gap-x-10"
          >
            <FormControlLabel
              key="duplicate_package"
              value="duplicate_package"
              control={<Radio />}
              label="Duplicate Package"
              className="py-2"
            />
            <FormControlLabel
              key="other"
              value="other"
              control={<Radio />}
              label="Other"
              className="py-2"
            />
          </RadioGroup>
          {formData.reasonForReturn === 'duplicate_package' && (
            <>
              <Divider />
              <>
                <div className="mb-3 flex items-center">
                  <Exclaim className="mr-2 fill-red-900" />
                  <div className="text-xl font-bold text-ink-500">Open & Inspect the Package</div>
                </div>
                <RadioGroup
                  label="Do the contents match the item description?"
                  value={formData.matchingDescription}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      matchingDescription: e.target.value === 'true' ? true : false,
                    })
                  }
                  className="!grid w-full grid-flow-row auto-rows-max grid-cols-2 gap-x-10"
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" className="py-2" />
                  <FormControlLabel value="false" control={<Radio />} label="No" className="py-2" />
                </RadioGroup>
              </>
            </>
          )}
          <Button
            type="submit"
            disabled={!isFormDataValid}
            className="mt-4 h-[80px] w-[270px] self-center"
            color="primary"
          >
            Confirm
          </Button>
        </form>
      )}
    </div>
  )
}
