import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import { toastApiError } from '@/components/Toastify'
import { useFeatureFlags } from '@/contexts/featureFlags'
import { useSession } from '@/contexts/session'

import LoginForm from './LoginForm'
import PasswordForm from './PasswordForm'

type LoginStep = 'login' | 'password'

interface LoginFormData {
  stationId: string
  password: string
}

export default function LoginPage() {
  const navigate = useNavigate()
  const { getFeatureFlags } = useFeatureFlags()
  const { login } = useSession()
  const [step, setStep] = useState<LoginStep>('login')
  const [isLoading, setIsLoading] = useState(false)
  const formData = useRef<LoginFormData>({ stationId: '', password: '' })

  function handleLoginSubmit(stationId: string) {
    formData.current.stationId = stationId
    setStep('password')
  }

  async function handlePasswordSubmit(password: string) {
    formData.current.password = password
    setIsLoading(true)
    try {
      await login(formData.current.stationId, formData.current.password)
      await getFeatureFlags()
      navigate('/')
    } catch (reason) {
      toastApiError(reason)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <AppBar>
        {step == 'password' && (
          <Button variant="text" onClick={() => setStep('login')}>
            back
          </Button>
        )}
      </AppBar>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <Stack>
          <Typography variant="h1" sx={{ color: 'ink.main', alignSelf: 'center', my: 7 }}>
            Exports Scanner
          </Typography>
          {step === 'login' && <LoginForm onSubmit={handleLoginSubmit} />}
          {step === 'password' && (
            <PasswordForm onSubmit={handlePasswordSubmit} loading={isLoading} />
          )}
        </Stack>
      </Container>
    </>
  )
}
