import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Button from '@/components/Button'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'
import ManifestCourier from '@/core/models/ManifestCourier'

import Bin from './Bin'
import BinsSummary from './BinsSummary'
import CourierBin from './models/CourierBin'
import { ALL_BINS_FILTER, useCourierBinsLocationFilters } from './useCourierBinsLocationFilters'
import useCourierBinsQuery from './useCourierBinsQuery'
import useManifestCouriersQuery from './useManifestCouriersQuery'

type ManifestStep = 'BinSelection' | 'Summary'

export default function ManifestV2Page() {
  const navigate = useNavigate()
  const [step, setStep] = useState<ManifestStep>('BinSelection')
  const [binsSelected, setBinsSelected] = useState<CourierBin[]>([])
  const [courierSelected, setCourierSelected] = useState<ManifestCourier>()
  const [selectedLocationId, setSelectedLocationId] = useState(ALL_BINS_FILTER.id)
  const { data: courierBins = [], isFetching: isFetchingBins } = useCourierBinsQuery(
    courierSelected?.slug
  )
  const { filters: locationFilters } = useCourierBinsLocationFilters(courierBins)

  const courierBinsFiltered = courierBins.filter(
    (bin) =>
      selectedLocationId === ALL_BINS_FILTER.id || bin.floorLocation.id === selectedLocationId
  )
  const areAllBinsSelected = binsSelected.length === courierBinsFiltered.length

  const { data: manifestCouriers = [], isFetching } = useManifestCouriersQuery()

  useEffect(() => {
    if (manifestCouriers.length > 0) {
      setCourierSelected(manifestCouriers[0])
    }
  }, [manifestCouriers])

  function handleCourierChange(courier: ManifestCourier) {
    setCourierSelected(courier)
    setBinsSelected([])
    setSelectedLocationId(ALL_BINS_FILTER.id)
  }

  function resetForm(): void {
    setStep('BinSelection')
    setBinsSelected([])
    setSelectedLocationId(ALL_BINS_FILTER.id)
  }

  function handleBinClick(bin: CourierBin) {
    const binIndexFound = binsSelected.findIndex((x) => x.id === bin.id)

    if (binIndexFound >= 0) {
      setBinsSelected([
        ...binsSelected.slice(0, binIndexFound),
        ...binsSelected.slice(binIndexFound + 1),
      ])
    } else {
      setBinsSelected([...binsSelected, bin])
    }
  }

  function handleSelectAll() {
    if (areAllBinsSelected) {
      setBinsSelected([])
    } else {
      setBinsSelected(courierBinsFiltered)
    }
  }

  return (
    <>
      <AppBar title="Manifest">
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      {isFetching ? (
        <Loading text="Fetching Couriers" />
      ) : (
        <Container maxWidth="md" sx={{ my: 3 }}>
          {manifestCouriers.length === 0 ? (
            <Typography variant="caption">No bins are waiting to be dropped</Typography>
          ) : (
            <>
              {step === 'BinSelection' && (
                <Stack spacing={3}>
                  <Stack direction="row" spacing={1}>
                    {manifestCouriers.map((courier) => (
                      <Chip
                        key={courier.slug}
                        label={`${courier.displayName} (${courier.binsCount})`}
                        color={courierSelected?.slug === courier.slug ? 'primary' : 'default'}
                        onClick={() => handleCourierChange(courier)}
                      />
                    ))}
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={handleSelectAll}
                      >
                        {areAllBinsSelected ? 'unselect' : 'select'} all bins
                      </Button>
                      <Typography variant="body1" sx={{ alignSelf: 'center' }}>
                        {binsSelected.length} bin{binsSelected.length > 1 && 's'} selected
                      </Typography>
                    </Stack>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={binsSelected.length === 0}
                      onClick={() => setStep('Summary')}
                    >
                      proceed to drop
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    {courierBins.length > 0 &&
                      locationFilters.map((location) => (
                        <Chip
                          key={location.name}
                          label={`${location.name} (${location.binsCount})`}
                          color={selectedLocationId === location.id ? 'primary' : 'default'}
                          onClick={() => {
                            setSelectedLocationId(location.id)
                            setBinsSelected([])
                          }}
                        />
                      ))}
                  </Stack>
                  <Stack spacing={2}>
                    {isFetchingBins ? (
                      <Loading text="Loading courier bins" />
                    ) : (
                      courierBinsFiltered.map((bin) => (
                        <Bin
                          key={bin.id}
                          bin={bin}
                          selected={binsSelected.includes(bin)}
                          onClick={handleBinClick}
                        />
                      ))
                    )}
                  </Stack>
                </Stack>
              )}
              {step === 'Summary' && courierSelected && (
                <BinsSummary
                  courierSelected={courierSelected}
                  binsSelected={binsSelected}
                  onBackClick={() => setStep('BinSelection')}
                  onSubmitSettled={resetForm}
                />
              )}
            </>
          )}
        </Container>
      )}
    </>
  )
}
