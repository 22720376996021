import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

export function useMarkXRayAsCompletedMutation() {
  const { courierGateway } = useGateways()

  return useMutation({
    mutationFn: (courierSlug: string) => courierGateway.markXRayAsCompleted(courierSlug),
    onError: (reason) => toastApiError(reason),
  })
}
