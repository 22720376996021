import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useBinSizesQuery() {
  const { binsGateway } = useGateways()

  return useQuery({
    queryKey: ['binSizes'],
    queryFn: binsGateway.getBinSizes,
    staleTime: Infinity,
  })
}
