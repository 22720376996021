import { z } from 'zod'

const errorCodes = [
  'not_found',
  'bad_request',
  'resource_not_saved_error',
  'bad_request_error',
  'unprocessable_entity_error',
  'resource_not_found_error',
  'measurement_error',
  'invalid_endpoint_error',
  'invalid_token_error',
] as const

const ErrorCodeZodEnum = z.enum(errorCodes)

const apiErrorSchema = z.object({
  error: z.object({
    code: ErrorCodeZodEnum,
    message: z.string(),
    detail: z.string().array(),
  }),
})

export type ApiError = z.infer<typeof apiErrorSchema>

export const isApiError = (error: unknown): error is ApiError => {
  return apiErrorSchema.safeParse(error).success
}
