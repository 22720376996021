import React, { createContext, useContext } from 'react'

import BinsGateway from '@/core/ports/bins'
import FeatureFlagsGateway from '@/core/ports/featureFlags'
import InspectionsGateway from '@/core/ports/inspections'
import OrdersGateway from '@/core/ports/orders'
import PackagesGateway from '@/core/ports/packages'
import SessionGateway from '@/core/ports/session'
import createApiCourierReturnGateway from '@/CourierReturn/gateways/api/apiCourierReturnGateway'
import createFakeCourierReturnGateway from '@/CourierReturn/gateways/fake/fakeCourierReturnGateway'
import createInMemoryCourierReturnGateway from '@/CourierReturn/gateways/inmem/inmemCourierReturnGateway'
import CourierReturnGateway from '@/CourierReturn/ports/courierReturn'
import createApiCourierSortGateway from '@/courierSort/gateways/api/apiCourierSortGateway'
import createFakeCourierSortGateway from '@/courierSort/gateways/fake/fakeCourierSortGateway'
import createInMemoryCourierSortGateway from '@/courierSort/gateways/inmem/inmemCourierSortGateway'
import CourierSortGateway from '@/courierSort/ports/courierSort'
import createApiBinsGateway from '@/gateways/api/apiBinsGateway'
import createApiFeatureFlagsGateWay from '@/gateways/api/apiFeatureFlagsGateway'
import createApiInspectionsGateway from '@/gateways/api/apiInspectionsGateway'
import createApiOrdersGateway from '@/gateways/api/apiOrdersGateway'
import createApiPackagesGateway from '@/gateways/api/apiPackagesGateway'
import createApiSessionGateway from '@/gateways/api/apiSessionGateway'
import createFakeBinsGateway from '@/gateways/fake/fakeBinsGateway'
import createFakeFeatureFlagsGateWay from '@/gateways/fake/fakeFeatureFlagsGateway'
import createFakeInspectionsGateway from '@/gateways/fake/fakeInspectionsGateway'
import createFakeOrdersGateway from '@/gateways/fake/fakeOrdersGateway'
import createFakePackagesGateway from '@/gateways/fake/fakePackagesGateway'
import createFakeSessionGateway from '@/gateways/fake/fakeSessionGateway'
import createInMemoryBinsGateway from '@/gateways/inmem/inmemBinsGateway'
import createInMemoryFeatureFlagsGateWay from '@/gateways/inmem/inmemFeatureFlagsGateway'
import createInMemoryInspectionsGateway from '@/gateways/inmem/inmemInspectionsGateway'
import createInMemoryOrdersGateway from '@/gateways/inmem/inmemOrdersGateway'
import createInMemoryPackagesGateway from '@/gateways/inmem/inmemPackagesGateway'
import createInMemorySessionGateway from '@/gateways/inmem/inmemSessionGateway'
import createApiInductionsGateway from '@/induction/gateway/api/apiInductionsGateway'
import createFakeInductionsGateway from '@/induction/gateway/fake/fakeInductionsGateway'
import createInMemoryInductionsGateway from '@/induction/gateway/inmem/inmemInductionsGateway'
import InductionsGateway from '@/induction/ports/induction'
import createApiLabelProcessingGateway from '@/labelProcessing/gateways/api/apiLabelProcessing'
import createFakeLabelProcessingGateway from '@/labelProcessing/gateways/fake/fakeLabelProcessingGateway'
import createInMemoryLabelProcessingGateway from '@/labelProcessing/gateways/inmem/inmemLabelProcessingGateway'
import LabelProcessingGateway from '@/labelProcessing/ports/labelProcessing'
import createApiManifestGateway from '@/Manifest/gateways/api/apiManifestGateway'
import createFakeManifestGateway from '@/Manifest/gateways/fake/fakeManifestGateway'
import createInMemoryManifestGateway from '@/Manifest/gateways/inmem/inmemManifestGateway'
import ManifestGateway from '@/Manifest/ports/manifest'
import createApiManifestV2Gateway from '@/manifestV2/gateways/api/apiManifestV2Gateway'
import createFakeManifestV2Gateway from '@/manifestV2/gateways/fake/fakeManifestV2Gateway'
import createInMemoryManifestV2Gateway from '@/manifestV2/gateways/inmem/inmemManifestV2Gateway'
import ManifestV2Gateway from '@/manifestV2/ports/manifestV2'
import createApiPreInspectionGateway from '@/preInspection/gateways/api/apiPreInspectionGateway'
import createFakePreInspectionGateway from '@/preInspection/gateways/fake/fakePreInspectionGateway'
import createInMemoryPreInspectionGateway from '@/preInspection/gateways/inmem/inmemPreInspectionGateway'
import PreInspectionGateway from '@/preInspection/ports/preInspection'
import createApiReturnPackagesGateway from '@/returnPackage/gateways/api/apiReturnPackagesGateway'
import createFakeReturnPackagesGateway from '@/returnPackage/gateways/fake/fakeReturnPackagesGateway'
import createInMemoryReturnPackagesGateway from '@/returnPackage/gateways/inmem/inmemReturnPackagesGateway'
import ReturnPackagesGateway from '@/returnPackage/ports/returnPackages'
import createApiReturnPackageResultGateway from '@/searchResult/gateways/api/apiSearchResultGateway'
import createFakeSearchResultGateway from '@/searchResult/gateways/fake/fakeSearchResultGateway'
import createInMemoryReturnPackageResultGateway from '@/searchResult/gateways/immem/inmemSearchResultGateway'
import SearchResultGateway from '@/searchResult/ports/searchResult'
import createApiCourierGateway from '@/XRay/gateways/api/apiCourierGateway'
import createFakeCourierGateway from '@/XRay/gateways/fake/fakeCourierGateway'
import createInMemoryCourierGateway from '@/XRay/gateways/inmem/inmemCourierGateway'
import CourierGateway from '@/XRay/ports/xRay'

export interface GatewayContextData {
  ordersGateway: OrdersGateway
  packagesGateway: PackagesGateway
  sessionGateway: SessionGateway
  manifestGateway: ManifestGateway
  manifestV2Gateway: ManifestV2Gateway
  courierGateway: CourierGateway
  inspectionsGateway: InspectionsGateway
  binsGateway: BinsGateway
  returnPackagesGateway: ReturnPackagesGateway
  inductionsGateway: InductionsGateway
  labelProcessingGateway: LabelProcessingGateway
  courierSortGateway: CourierSortGateway
  searchResultGateway: SearchResultGateway
  preInspectionGateway: PreInspectionGateway
  courierReturnGateway: CourierReturnGateway
  featureFlagsGateway: FeatureFlagsGateway
}

const GatewayContext = createContext<GatewayContextData | undefined>(undefined)

interface GatewayProviderProps {
  children: React.ReactNode
}

function GatewayProvider({ children }: GatewayProviderProps) {
  const data: GatewayContextData = {
    ordersGateway: createApiOrdersGateway(),
    packagesGateway: createApiPackagesGateway(),
    sessionGateway: createApiSessionGateway(),
    manifestGateway: createApiManifestGateway(),
    manifestV2Gateway: createApiManifestV2Gateway(),
    courierGateway: createApiCourierGateway(),
    inspectionsGateway: createApiInspectionsGateway(),
    binsGateway: createApiBinsGateway(),
    returnPackagesGateway: createApiReturnPackagesGateway(),
    inductionsGateway: createApiInductionsGateway(),
    labelProcessingGateway: createApiLabelProcessingGateway(),
    courierSortGateway: createApiCourierSortGateway(),
    searchResultGateway: createApiReturnPackageResultGateway(),
    preInspectionGateway: createApiPreInspectionGateway(),
    courierReturnGateway: createApiCourierReturnGateway(),
    featureFlagsGateway: createApiFeatureFlagsGateWay(),
  }

  return <GatewayContext.Provider value={data}>{children}</GatewayContext.Provider>
}

export interface FakeGatewayProviderProps {
  value?: Partial<GatewayContextData>
  children: React.ReactNode
}

function FakeGatewayProvider({ children, value = {} }: FakeGatewayProviderProps) {
  const fakeGatewaysDefault: GatewayContextData = {
    packagesGateway: createFakePackagesGateway(),
    ordersGateway: createFakeOrdersGateway(),
    sessionGateway: createFakeSessionGateway(),
    manifestGateway: createFakeManifestGateway(),
    manifestV2Gateway: createFakeManifestV2Gateway(),
    courierGateway: createFakeCourierGateway(),
    inspectionsGateway: createFakeInspectionsGateway(),
    binsGateway: createFakeBinsGateway(),
    returnPackagesGateway: createFakeReturnPackagesGateway(),
    inductionsGateway: createFakeInductionsGateway(),
    labelProcessingGateway: createFakeLabelProcessingGateway(),
    courierSortGateway: createFakeCourierSortGateway(),
    searchResultGateway: createFakeSearchResultGateway(),
    preInspectionGateway: createFakePreInspectionGateway(),
    courierReturnGateway: createFakeCourierReturnGateway(),
    featureFlagsGateway: createFakeFeatureFlagsGateWay(),
  }
  const data: GatewayContextData = {
    ...fakeGatewaysDefault,
    ...value,
  }

  return <GatewayContext.Provider value={data}>{children}</GatewayContext.Provider>
}

function InMemoryGatewayProvider({ children }: GatewayProviderProps) {
  const data: GatewayContextData = {
    ordersGateway: createInMemoryOrdersGateway(),
    packagesGateway: createInMemoryPackagesGateway(),
    sessionGateway: createInMemorySessionGateway(),
    manifestGateway: createInMemoryManifestGateway(),
    manifestV2Gateway: createInMemoryManifestV2Gateway(),
    courierGateway: createInMemoryCourierGateway(),
    inspectionsGateway: createInMemoryInspectionsGateway(),
    binsGateway: createInMemoryBinsGateway(),
    returnPackagesGateway: createInMemoryReturnPackagesGateway(),
    inductionsGateway: createInMemoryInductionsGateway(),
    labelProcessingGateway: createInMemoryLabelProcessingGateway(),
    courierSortGateway: createInMemoryCourierSortGateway(),
    searchResultGateway: createInMemoryReturnPackageResultGateway(),
    preInspectionGateway: createInMemoryPreInspectionGateway(),
    courierReturnGateway: createInMemoryCourierReturnGateway(),
    featureFlagsGateway: createInMemoryFeatureFlagsGateWay(),
  }

  return <GatewayContext.Provider value={data}>{children}</GatewayContext.Provider>
}

function useGateways() {
  const gatewayContext = useContext(GatewayContext)

  if (gatewayContext === undefined) {
    throw new Error('useGateways must be used within a GatewayProvider')
  }
  return gatewayContext
}

export { GatewayProvider, InMemoryGatewayProvider, FakeGatewayProvider, useGateways }
