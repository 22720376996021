import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ItemsList from '@/components/ItemsList'
import { Stat, StatLabel, StatValue } from '@/components/Stat'

import Order from '../models/Order'

interface RegularOrderDataProps {
  idx: number
  order: Omit<Order, 'packages'>
}

export default function RegularOrderData({ idx, order }: RegularOrderDataProps) {
  const { evtn, senderAddress, receiverAddress, items } = order
  const shipToAddress = [
    receiverAddress.addressLine1,
    receiverAddress.addressLine2,
    receiverAddress.city,
    receiverAddress.state,
    receiverAddress.postalCode,
    receiverAddress.countryCode,
  ]
    .filter((v) => !!v)
    .join(', ')

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '54px 146px 300px 1fr',
        gridGap: 20,
        mb: 2,
      }}
    >
      <Stack spacing={0.5}>
        <Typography variant="subtitle2" fontWeight="bold">
          Order
        </Typography>
        <Typography variant="h2">#{idx}</Typography>
      </Stack>

      <Stat>
        <StatLabel>EVTN</StatLabel>
        <StatValue variant="h1" color="blue.dark">
          {evtn}
        </StatValue>
      </Stat>

      <Stack spacing={1.25}>
        <Stat>
          <StatLabel>Items</StatLabel>
          <ItemsList items={items} />
        </Stat>
      </Stack>

      <Stack spacing={1.25}>
        <Stat horizontal>
          <StatLabel>Ship From - Name</StatLabel>
          <StatValue variant="h5">{senderAddress.fullName}</StatValue>
        </Stat>
        <Stat horizontal>
          <StatLabel>Ship To - Name</StatLabel>
          <StatValue variant="h5">{receiverAddress.fullName}</StatValue>
        </Stat>

        <Stat horizontal>
          <StatLabel>Ship To - Address</StatLabel>
          <StatValue variant="h5">{shipToAddress}</StatValue>
        </Stat>

        <Stat horizontal>
          <StatLabel>Ship To - Country</StatLabel>
          <StatValue variant="h5">{receiverAddress.country}</StatValue>
        </Stat>
      </Stack>
    </Box>
  )
}
