import { Routes, Route } from 'react-router-dom'

import CourierSortPage from './courierSort/CourierSortPage'
import InductionPage from './induction/InductionPage'
import LabelProcessingPage from './labelProcessing/LabelProcessingPage'
import ManifestPage from './Manifest/ManifestPage'
import ManifestV2Page from './manifestV2/ManifestV2Page'
import BinDetailsPage from './pages/BinDetailsPage'
import BinModulePage from './pages/BinModulePage'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import PackagePage from './pages/PackagePage'
import PackagesSelectionPage from './pages/PackagesSelectionPage'
import ScanPage from './pages/ScanPage'
import PreInspectionPage from './preInspection/PreInspectionPage'
import ReturnPackagePage from './returnPackage/ReturnPackagePage'
import SearchResultPage from './searchResult/SearchResultPage'
import XRayPage from './XRay/XRayPage'

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/scan" element={<ScanPage />} />
      <Route path="/search-result" element={<SearchResultPage />} />
      <Route path="/package" element={<PackagePage />} />
      <Route path="/courier-sort" element={<CourierSortPage />} />
      <Route path="/return-package" element={<ReturnPackagePage />} />
      <Route path="/packages-selection" element={<PackagesSelectionPage />} />
      <Route path="/manifest" element={<ManifestPage />} />
      <Route path="/manifest-v2" element={<ManifestV2Page />} />
      <Route path="/x-ray" element={<XRayPage />} />
      <Route path="/bin-module" element={<BinModulePage />} />
      <Route path="/bin-details" element={<BinDetailsPage />} />
      <Route path="/induction" element={<InductionPage />} />
      <Route path="/label-processing" element={<LabelProcessingPage />} />
      <Route path="/pre-inspection" element={<PreInspectionPage />} />
    </Routes>
  )
}

export default App
