import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { Toastify } from '@/components/Toastify'

import App from './App'
import { FeatureFlagsProvider } from './contexts/featureFlags'
import { GatewayProvider, InMemoryGatewayProvider } from './contexts/gateways'
import { SessionProvider } from './contexts/session'
import QueryClientProvider from './QueryClientProvider'
import { theme } from './theme'
import './gateways/api/axiosSetup'
import './gateways/api/axiosInterceptors'
import './plugins/dayjs'
import './main.css'

// https://docs.sentry.io/platforms/javascript/guides/react/
const isDevEnv = import.meta.env.MODE === 'development'

Sentry.init({
  enabled: !isDevEnv,
  environment: import.meta.env.MODE,
  dsn: 'https://3de47f8e58d34aae89ef59b0a9ea4999@o177970.ingest.sentry.io/4504234440982528',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', import.meta.env.VITE_API_BASE_URL],
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.06,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <GatewayProvider>
          <QueryClientProvider>
            <SessionProvider>
              <FeatureFlagsProvider>
                <App />
                <Toastify />
              </FeatureFlagsProvider>
            </SessionProvider>
          </QueryClientProvider>
        </GatewayProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
)
