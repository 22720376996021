import Chance from 'chance'

import Order, { OrderPackage } from '@/searchResult/models/Order'

const chance = new Chance()

const orderPackageStub = (): OrderPackage => ({
  id: chance.guid(),
  ebayPackageId: chance.string(),
  easyshipShipmentId: chance.string(),
  firstMileTrackingNumber: chance.string(),
  lastMileTrackingNumber: chance.string(),
  warehouseState: { value: 'received', displayName: 'received' },
  scanReceived: chance.date().toISOString(),
  lastStationId: chance.integer({ min: 1, max: 99 }),
  combinedShipment: false,
})

export const orderStub = (): Order => ({
  id: chance.guid(),
  evtn: chance.string(),
  isReturn: chance.bool(),
  senderName: chance.name(),
  combinedShipment: false,
  senderAddress: {
    fullName: chance.name(),
    addressLine1: chance.address(),
    addressLine2: chance.address(),
    city: chance.city(),
    state: chance.state(),
    postalCode: chance.postcode(),
    countryCode: chance.country(),
    country: chance.country({ full: true }),
  },
  receiverAddress: {
    fullName: chance.name(),
    addressLine1: chance.address(),
    addressLine2: chance.address(),
    city: chance.city(),
    state: chance.state(),
    postalCode: chance.postcode(),
    countryCode: chance.country(),
    country: chance.country({ full: true }),
  },
  items: [
    {
      ebayItemId: chance.string(),
      description: chance.string(),
      url: chance.url(),
      quantity: chance.natural(),
      sku: chance.string(),
    },
  ],
  packages: [orderPackageStub(), orderPackageStub()],
})
