import { Chance } from 'chance'

import { InductionExceptionResult, InductionPassResult } from '@/induction/models/Induction'

const chance = new Chance()

export const inductionPassResultStub = (): InductionPassResult => ({
  status: 'label_processing',
  label: chance.string(),
  package: {
    id: chance.guid(),
    firstMileTrackingNumber: chance.string(),
    warehouseState: {
      value: 'received',
      displayName: chance.string(),
    },
    weight: {
      value: 0,
      unit: 'lb',
    },
  },
})

export const inductionExceptionResultStub = (): InductionExceptionResult => ({
  status: 'exception',
  label: chance.string(),
  message: chance.string(),
})
