import Chance from 'chance'

import Package from '@/labelProcessing/models/Package'

const chance = new Chance()

export const packageStub = (): Package => ({
  id: chance.guid(),
  warehouseState: {
    value: 'label_generated',
    displayName: chance.string(),
  },
  firstMileTrackingNumber: chance.string(),
  instruction: {
    slug: 'label_printing',
    description: chance.string(),
  },
})
