import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { CourierReadyForXRay } from './models/Courier'
import { useMarkForInspectionMutation } from './useMarkForInspectionMutation'
import { useMarkXRayAsCompletedMutation } from './useMarkXRayAsCompletedMutation'
import XrayCompletionForm from './XrayCompletionForm'

interface CourierCardProps {
  courier: CourierReadyForXRay
  onXRayCompleted: () => void
  onMarkAsCompleted: () => void
}

export default function CourierCard({
  courier,
  onXRayCompleted,
  onMarkAsCompleted,
}: CourierCardProps) {
  const markXRayAsCompletedMutation = useMarkXRayAsCompletedMutation()
  const markForInspectionMutation = useMarkForInspectionMutation()

  async function handleMarkAsCompleted() {
    await markXRayAsCompletedMutation.mutate(courier.slug, { onSuccess: onXRayCompleted })
  }

  async function handleScanInspectionSubmit(barcodes: string[]) {
    markForInspectionMutation.mutate({ barcodes, source: 'xray' }, { onSuccess: onMarkAsCompleted })
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">{courier.displayName}</Typography>
            <div>
              <Typography fontWeight="bold" component="span">
                {courier.packagesCount}
              </Typography>
              <span> pcs</span>
            </div>
          </Stack>
          <Divider light />
          <XrayCompletionForm
            onMarkAsCompleted={handleMarkAsCompleted}
            onScanInspectionSubmit={handleScanInspectionSubmit}
          />
        </Stack>
      </CardContent>
    </Card>
  )
}
