import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'

import ManifestCard from './ManifestCard'
import useAllPendingQuery from './useAllPendingQuery'

export default function ManifestPage() {
  const navigate = useNavigate()

  const { data: manifests = [], isFetching, refetch } = useAllPendingQuery()

  return (
    <>
      <AppBar title="Manifest">
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <Stack spacing={2}>
          {isFetching ? (
            <Loading text="Fetching Manifests" />
          ) : (
            <>
              {manifests.length === 0 ? (
                <Typography variant="caption">No packages are waiting to be dropped</Typography>
              ) : (
                manifests.map((manifest) => (
                  <ManifestCard
                    key={manifest.courier.slug}
                    manifest={manifest}
                    onDropped={refetch}
                  />
                ))
              )}
            </>
          )}
        </Stack>
      </Container>
    </>
  )
}
