import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { useSession } from '../contexts/session'

interface AppBarProps {
  title?: React.ReactNode
  children: React.ReactNode
}

export default function AppBar({ title, children }: AppBarProps) {
  const { session } = useSession()

  return (
    <MuiAppBar position="static" color="default">
      <Container maxWidth="md">
        <Toolbar>
          <Box position="absolute" width="100%" display="flex" justifyContent="center">
            <Stack alignItems="center">
              {session && (
                <Typography variant="subtitle2" textTransform="uppercase">
                  {session.user.hub.name}-{session.stationId}-{session.user.firstName}
                </Typography>
              )}
              {title && <Typography variant="h5">{title}</Typography>}
            </Stack>
          </Box>
          {children}
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
}
