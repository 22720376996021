import axios, { AxiosResponse } from 'axios'

import { CourierReturn } from '@/CourierReturn/models/CourierReturn'
import CourierReturnGateway from '@/CourierReturn/ports/courierReturn'

import { mapFromApi } from './mappers/courierReturnMapper'
import { CourierReturnPayload, CourierReturnResponse } from './models/CourierReturn'

function updateCourierReturn(
  packageId: string,
  courierReturn: CourierReturn,
): Promise<CourierReturn> {
  return axios
    .put<CourierReturnResponse, AxiosResponse<CourierReturnResponse>, CourierReturnPayload>(
      `/v1/packages/${packageId}/courier_returns/${courierReturn.id}`,
      {
        return_type: courierReturn.returnType,
        return_reason: courierReturn.returnReason,
        package_action: courierReturn.packageAction,
        content_damaged: courierReturn.contentDamaged,
        packaging_damaged: courierReturn.packagingDamaged,
        label_usable: courierReturn.labelUsable,
      },
    )
    .then(({ data }) => mapFromApi(data.courier_return))
}

function requestCourierReturnInspection(packageId: string, courierReturnId: string): Promise<void> {
  return axios.post(
    `/v1/packages/${packageId}/courier_returns/${courierReturnId}/request_inspection`,
  )
}
export default function createApiCourierReturnGateway(): CourierReturnGateway {
  return {
    updateCourierReturn,
    requestCourierReturnInspection,
  }
}
