import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function LabelProcessingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 171 101">
      <g id="label" transform="translate(-214 -226)">
        <rect width="171" height="13" transform="translate(214 226)" />
        <rect width="52" height="12" transform="translate(237 270)" />
        <rect width="171" height="14" transform="translate(214 313)" />
        <g transform="translate(-4 -2)">
          <rect width="44" height="12" transform="translate(311 272)" />
          <path d="M13.561,0,27.122,29.746H0Z" transform="translate(375.873 264.439) rotate(90)" />
        </g>
      </g>
    </SvgIcon>
  )
}
