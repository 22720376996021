import { CourierReturn } from '@/CourierReturn/models/CourierReturn'

import { CourierReturnDataResponse } from '../models/CourierReturn'

export function mapFromApi(courierReturn: CourierReturnDataResponse): CourierReturn {
  return {
    id: courierReturn.id,
    packageId: courierReturn.package_id,
    returnType: courierReturn.return_type,
    returnReason: courierReturn.return_reason,
    packageAction: courierReturn.package_action,
    createdAt: courierReturn.created_at,
    updatedAt: courierReturn.updated_at,
    processed_at: courierReturn.processed_at,
    contentDamaged: courierReturn.content_damaged,
    packagingDamaged: courierReturn.packaging_damaged,
    label: courierReturn.label
      ? {
          id: courierReturn.label.id,
          active: courierReturn.label.active,
          alternateTrackingNumber: courierReturn.label.alternate_tracking_number,
          courierAdminName: courierReturn.label.courier_admin_name,
          courierId: courierReturn.label.courier_id,
          courierName: courierReturn.label.courier_name,
          courierUmbrellaName: courierReturn.label.courier_umbrella_name,
          createdAt: courierReturn.label.created_at,
          packageId: courierReturn.label.package_id,
          state: courierReturn.label.state,
          trackingNumber: courierReturn.label.tracking_number,
          updatedAt: courierReturn.label.updated_at,
        }
      : null,
    labelUsable: courierReturn.label_usable,
  }
}
