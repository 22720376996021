import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import WarningIcon from '@mui/icons-material/WarningAmberRounded'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import COLORS from '@/colors'
import { ScreeningFlagCategory } from '@/gateways/api/models/Package'

import { PreInspectionPackageScreeningFlags } from './models/PreInspection'

const CATEGORY_TITLE: Readonly<Record<ScreeningFlagCategory, string>> = {
  dangerous: 'dangerous goods',
  prohibited: 'prohibited goods',
  battery: 'battery',
  high_value: 'high value',
}

interface FlagsProps {
  flagIndex: number
  flag: PreInspectionPackageScreeningFlags | undefined
  totalFlags: number
}
interface InspectionAlertIconProps {
  category: ScreeningFlagCategory
}

function InspectionAlertIcon({ category }: InspectionAlertIconProps) {
  const Component = category === 'battery' ? BatteryChargingFullIcon : WarningIcon
  const color = COLORS.yellowDarkest
  return <Component fontSize="large" sx={{ color }} />
}

export default function FlagCard({ flag, totalFlags, flagIndex }: FlagsProps) {
  if (!flag) return <></>
  return (
    <Stack bgcolor={COLORS.yellowNormal} borderRadius={2} p={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <InspectionAlertIcon category={flag.category} />
          <Stack gap={1}>
            <Typography textTransform="uppercase" variant="h4" fontSize={15} fontWeight="bold">
              pre-inspection ({CATEGORY_TITLE[flag?.category]})
            </Typography>
            <Typography variant="h4" fontSize={18}>
              {flag.keyword}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography textTransform="uppercase" variant="h4" fontSize={15} fontWeight="bold">
            flag {flagIndex}/{totalFlags}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
