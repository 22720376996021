import axios from 'axios'

import Package from '@/labelProcessing/models/Package'
import LabelProcessingGateway from '@/labelProcessing/ports/labelProcessing'

import { PackageResponse } from './models/Package'

function scan(barcode: string): Promise<Package> {
  return axios.get<PackageResponse>(`/v1/label_processing/scan/${barcode}`).then(({ data }) => ({
    id: data.package.id,
    warehouseState: {
      value: data.package.warehouse_state,
      displayName: data.package.warehouse_state_display_name,
    },
    firstMileTrackingNumber: data.package.first_mile_tracking_number,
    instruction: {
      slug: data.package.instruction.slug,
      description: data.package.instruction.description,
    },
  }))
}

function reprintLabel(packageId: string): Promise<void> {
  return axios.post(`/v1/packages/${packageId}/reprint_label`)
}

export default function createApiLabelProcessingGateway(): LabelProcessingGateway {
  return {
    scan,
    reprintLabel,
  }
}
