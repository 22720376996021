import Chance from 'chance'

import ReturnPackage from '@/returnPackage/models/ReturnPackage'

const chance = new Chance()

export const returnPackageStub = (): ReturnPackage => ({
  id: chance.string(),
  warehouseState: { value: 'not_received', displayName: 'Not Received' },
  returnEvtn: chance.string(),
  trackingNumber: chance.string(),
  items: [],
  outboundEvtn: chance.string(),
  outboundEbayPackageIds: [],
  easyshipShipmentId: chance.string(),
  inspection: {
    id: chance.string(),
    status: 'to_inspect',
    zendeskId: chance.string(),
    packageEmpty: null,
    doesItemDescriptionMatch: null,
    doesItemQuantityMatch: null,
    returnedItemQuantity: chance.integer(),
    packageDamaged: null,
  },
})
