import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'
import ManifestCourier from '@/core/models/ManifestCourier'

export default function useManifestCouriersQuery(params?: UseQueryOptions<ManifestCourier[]>) {
  const { manifestV2Gateway } = useGateways()

  return useQuery({
    queryKey: ['manifestCouriers'],
    queryFn: manifestV2Gateway.getManifestCouriers,
    ...params,
  })
}
