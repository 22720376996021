import Stack from '@mui/material/Stack'

import Input from '@/components/Input'
import { InspectionSteps } from '@/returnPackage/models/ReturnPackage'

import BackButton from '../BackButton'
import ConfirmButton from '../ConfirmButton'
import { useInspectionQuestions } from '../InspectionQuestionsProvider'

export const ConfirmIncorrectItem = () => {
  const { setInspectionAnswers, setCurrentStep } = useInspectionQuestions()
  function handleBackClick() {
    setInspectionAnswers((prevState) => ({ ...prevState, comments: { packageDescription: '' } }))
    setCurrentStep(InspectionSteps.MATCH_DESCRIPTION)
  }
  return (
    <Stack spacing={6}>
      <h1>Inspection Result: Incorrect Item</h1>
      <Input
        label="Comments (optional)"
        type="string"
        autoFocus
        onChange={(e) => {
          setInspectionAnswers((prevState) => ({
            ...prevState,
            comments: {
              packageDescription: e.target.value,
            },
          }))
        }}
      />
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <BackButton onBackClick={handleBackClick} />
        <ConfirmButton type="liquidate" />
      </Stack>
    </Stack>
  )
}
