import Button from '@/components/Button'
import Package from '@/core/models/Package'

import useCreateNewPackageQuery from '../useCreateNewPackageQuery'

interface CreateNewPackageButtonProps {
  orderId: string
  onCreateNewPackageSuccess(pack: Package): void
}

export default function CreateNewPackageButton({
  orderId,
  onCreateNewPackageSuccess,
}: CreateNewPackageButtonProps) {
  const { refetch, isFetching } = useCreateNewPackageQuery(orderId)

  async function handleCreatePackageButtonClick() {
    const { data } = await refetch()
    data && onCreateNewPackageSuccess(data)
  }
  return (
    <Button
      variant="text"
      sx={{ alignSelf: 'flex-end' }}
      onClick={handleCreatePackageButtonClick}
      loading={isFetching}
    >
      + create a new package
    </Button>
  )
}
