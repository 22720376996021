import { PackageWarehouseState } from '@/core/models/Package'
import { WeightUnit } from '@/gateways/api/models/Package'

export const inductionPassStatuses = [
  'label_processing',
  'inspection',
  'oversize',
  'forced_inspection',
  'single_parcel_xray',
  'high_value',
] as const
export const inductionExceptionStatuses = ['exception', 'call_problem_solver'] as const
export const inductionStatuses = [...inductionPassStatuses, ...inductionExceptionStatuses]

type InductionPassStatus = (typeof inductionPassStatuses)[number]
type InductionExceptionStatus = (typeof inductionExceptionStatuses)[number]
export type InductionStatus = (typeof inductionStatuses)[number]

export interface InductionPassResult {
  status: InductionPassStatus
  label: string
  package: {
    id: string
    weight: {
      unit: WeightUnit
      value: number
    }
    firstMileTrackingNumber: string
    warehouseState: PackageWarehouseState
  }
}

export interface InductionExceptionResult {
  status: InductionExceptionStatus
  label: string
  message: string
}

type InductionResult = InductionPassResult | InductionExceptionResult

export default InductionResult
