import { SubmitInspectionOutcomeData } from '@/returnPackage/models/ReturnPackage'

import { ConfirmLiquidatePayload } from '../models/ReturnPackage'

export function mapToApi(data: SubmitInspectionOutcomeData): ConfirmLiquidatePayload {
  const comments = data.inspection.comments && {
    ...(!!data.inspection.comments.packageDamaged && {
      package_damaged: data.inspection.comments.packageDamaged,
    }),
    ...(!!data.inspection.comments.packageDescription && {
      does_item_description_match: data.inspection.comments.packageDescription,
    }),
  }
  return {
    inspection: {
      package_empty: data.inspection.packageEmpty,
      does_item_description_match: data.inspection.doesItemDescriptionMatch,
      does_item_quantity_match: data.inspection.doesItemQuantityMatch,
      returned_item_quantity: data.inspection.returnedItemQuantity,
      package_damaged: data.inspection.packageDamaged,
      comments: comments,
    },
    destroy_return_package: data.destroyReturnPackage,
  }
}
