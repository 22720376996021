import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ScanIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 19">
      <path
        d="M21.084,11.9H.917a.951.951,0,0,1,0-1.9H21.084a.951.951,0,0,1,0,1.9Z"
        transform="translate(0 -1.45)"
      />
      <g transform="translate(2.226)">
        <path d="M4.708,0H2.825A2.841,2.841,0,0,0,0,2.85v1.9a.942.942,0,1,0,1.883,0V2.85a.947.947,0,0,1,.942-.95H4.708a.95.95,0,0,0,0-1.9Z" />
      </g>
      <g transform="translate(14.383 13.3)">
        <path
          d="M22.708,18a.946.946,0,0,0-.942.95v1.9a.947.947,0,0,1-.942.95H18.942a.95.95,0,0,0,0,1.9h1.883a2.841,2.841,0,0,0,2.825-2.85v-1.9A.946.946,0,0,0,22.708,18Z"
          transform="translate(-18 -18)"
        />
      </g>
      <g transform="translate(2.226 13.3)">
        <path
          d="M4.708,21.8H2.825a.947.947,0,0,1-.942-.95v-1.9A.942.942,0,1,0,0,18.95v1.9A2.841,2.841,0,0,0,2.825,23.7H4.708a.95.95,0,0,0,0-1.9Z"
          transform="translate(0 -18)"
        />
      </g>
      <g transform="translate(14.383)">
        <path
          d="M20.825,0H18.942a.95.95,0,0,0,0,1.9h1.883a.947.947,0,0,1,.942.95v1.9a.942.942,0,1,0,1.883,0V2.85A2.841,2.841,0,0,0,20.825,0Z"
          transform="translate(-18 0)"
        />
      </g>
    </SvgIcon>
  )
}
