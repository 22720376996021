import React from 'react'

import { Stat, StatLabel, StatValue } from '@/components/Stat'

export interface PackageDataItem {
  label: string
  value: React.ReactNode
  isVisible?: boolean
}

interface PackageDataListProps {
  data: PackageDataItem[]
}

export default function PackageDataList({ data }: PackageDataListProps) {
  return (
    <>
      {data
        .filter((data) => data.value)
        .map(
          ({ label, value, isVisible = true }) =>
            isVisible && (
              <React.Fragment key={label}>
                <Stat className="mb-1" horizontal>
                  <StatLabel variant="h3">{label}</StatLabel>
                  <StatValue textTransform="capitalize" variant="h4">
                    {value}
                  </StatValue>
                </Stat>
              </React.Fragment>
            ),
        )}
    </>
  )
}
