import { MuiChipsInput, MuiChipsInputProps } from 'mui-chips-input'
import { useEffect, useState } from 'react'

import useDebounce from '@/hooks/useDebounce'

type MultiScanInputProps = MuiChipsInputProps

export default function MultiScanInput(props: MultiScanInputProps) {
  const [scanValue, setScanValue] = useState('')
  const scanValueDebounced = useDebounce(scanValue, 300)

  useEffect(() => {
    if (scanValueDebounced) {
      setScanValue('')
      props.onChange?.([...(props.value ?? []), scanValueDebounced])
    }
  }, [scanValueDebounced])

  return (
    <MuiChipsInput
      {...props}
      inputValue={scanValue}
      onInputChange={(v) => setScanValue(v)}
      onChange={(v) => props.onChange?.(v)}
      placeholder="9400 1000 0000 0000 0000 00"
      disableEdition
      renderChip={(Component,key, props) => (
        <Component {...props} key={key} variant="outlined" style={{ borderRadius: 8 }} />
      )}
    />
  )
}
