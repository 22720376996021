import CloseIcon from '@mui/icons-material/Close'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
  onClose?: () => void
  title: React.ReactNode
}

export default function Dialog({ children, title, ...props }: DialogProps) {
  return (
    <MuiDialog {...props}>
      <DialogTitle>
        {props.onClose ? (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {title}
            <IconButton onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        ) : (
          title
        )}
      </DialogTitle>
      <Divider light />
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  )
}
