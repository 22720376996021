import { keyframes, styled } from '@mui/material/styles'

const pulseKeyframes = keyframes`
  0% {
    transform: scale(0.0);
  }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
`

const Pulse = styled('div')(({ theme }) => ({
  width: 20,
  height: 20,
  backgroundColor: theme.palette.ink.main,
  borderRadius: '100%',
  animation: `${pulseKeyframes} 1s infinite ease-in-out`,
}))

Pulse.defaultProps = {
  role: 'progressbar',
}

export default Pulse
