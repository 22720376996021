import axios from 'axios'

import Package from '@/core/models/Package'
import { mapFromApi as mapPackageFromApi } from '@/gateways/api/mappers/packageMapper'
import { PackageResponse } from '@/gateways/api/models/Package'
import { mapFromApi } from '@/returnPackage/gateways/api/mappers/returnPackageMapper'
import { ReturnPackageResponse } from '@/returnPackage/gateways/api/models/ReturnPackage'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'
import SearchResultGateway from '@/searchResult/ports/searchResult'

export default function createApiReturnPackageResultGateway(): SearchResultGateway {
  function getReturnPackageById(packageId: string): Promise<ReturnPackage> {
    return axios
      .get<ReturnPackageResponse>(`/v1/return_packages/${packageId}`)
      .then(({ data }) => mapFromApi(data.return_package))
  }

  function markAsReceived(packageId: string): Promise<ReturnPackage> {
    return axios
      .post<ReturnPackageResponse>(`/v1/return_packages/${packageId}/mark_as_received`)
      .then(({ data }) => mapFromApi(data.return_package))
  }

  function getRegularPackageById(packageId: string): Promise<Package> {
    return axios
      .get<PackageResponse>(`/v1/packages/${packageId}`)
      .then(({ data }) => mapPackageFromApi(data.package))
  }

  function createPackage(orderId: string): Promise<Package> {
    return axios
      .post<PackageResponse>(`/v1/orders/${orderId}/packages`)
      .then(({ data }) => mapPackageFromApi(data.package))
  }

  return {
    getReturnPackageById,
    markAsReceived,
    getRegularPackageById,
    createPackage,
  }
}
