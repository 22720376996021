import axios from 'axios'

import FeatureFlagsGateway from '@/core/ports/featureFlags'

import { FeatureFlags } from './models/FeatureFlags'

function getFeatureFlags(packageId?: string): Promise<FeatureFlags> {
  return axios
    .get<FeatureFlags>(`/v1/feature_flags${packageId ? `/${packageId}` : ''}`)
    .then(({ data }) => data)
}

export default function createApiFeatureFlagsGateWay(): FeatureFlagsGateway {
  return {
    getFeatureFlags,
  }
}
