import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

import { CourierReturn } from './models/CourierReturn'

interface CourierReturnMutationParams {
  packageId: string
  courier: CourierReturn
}

export function useCourierReturnMutation() {
  const { courierReturnGateway } = useGateways()

  return useMutation({
    mutationFn: ({ packageId, courier }: CourierReturnMutationParams) =>
      courierReturnGateway.updateCourierReturn(packageId, courier),
    onError: (reason) => toastApiError(reason),
  })
}
