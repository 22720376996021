import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface DropForCourierMutationParams {
  courierUmbrellaName: string
  manifestReferenceId: string
}

export function useDropForCourierMutation() {
  const { manifestGateway } = useGateways()

  return useMutation({
    mutationFn: ({ courierUmbrellaName, manifestReferenceId }: DropForCourierMutationParams) =>
      manifestGateway.dropForCourier(courierUmbrellaName, manifestReferenceId),
    onError: (reason) => toastApiError(reason),
  })
}
