import { Chance } from 'chance'

import { RemovePackagesResult } from '@/core/models/Package'

const chance = new Chance()

export const multiScanResultStub = (): RemovePackagesResult => ({
  succeededPackageIds: [],
  failedBarcodes: [],
  errorMessage: chance.string(),
})
