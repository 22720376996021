import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { InspectionSteps } from '@/returnPackage/models/ReturnPackage'

import IconCheck from '../../../../assets/Icon-check.svg'
import IconCross from '../../../../assets/Icon-cross.svg'
import { useReturnPackage } from '../../ReturnPackageProvider'
import BackButton from '../BackButton'
import { useInspectionQuestions } from '../InspectionQuestionsProvider'

export const MatchQuantity = () => {
  const { pack } = useReturnPackage()
  const { setCurrentStep, setInspectionAnswers } = useInspectionQuestions()
  function handleBackClick() {
    setInspectionAnswers((prevState) => ({
      ...prevState,
      doesItemQuantityMatch: null,
      returnedItemQuantity: null,
    }))
    setCurrentStep(InspectionSteps.MATCH_DESCRIPTION)
  }
  const quantity = pack.items[0]?.quantity || 0
  return (
    <Stack alignItems="center" spacing={2}>
      <h1>Quantity={quantity}?</h1>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="secondary"
          aria-label="not match quantity"
          sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
          onClick={() => {
            setInspectionAnswers((prevState) => ({
              ...prevState,
              doesItemQuantityMatch: false,
              returnedItemQuantity: null,
            }))
            setCurrentStep(InspectionSteps.CONFIRM_INCORRECT_QUANTITY)
          }}
        >
          <img
            src={IconCross}
            height={36}
            style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
          />

          <Typography variant="h1">no</Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          aria-label="yes match quantity"
          sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
          onClick={() => {
            setInspectionAnswers((prevState) => ({
              ...prevState,
              doesItemQuantityMatch: true,
              returnedItemQuantity: quantity,
            }))
            setCurrentStep(InspectionSteps.PACKAGE_DAMAGE)
          }}
        >
          <img
            src={IconCheck}
            height={19}
            style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
          />

          <Typography variant="h1">yes</Typography>
        </Button>
      </Stack>
      <Stack alignItems="flex-start" width="100%">
        <BackButton onBackClick={handleBackClick} />
      </Stack>
    </Stack>
  )
}
