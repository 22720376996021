import axios, { AxiosResponse } from 'axios'

import Bin, { ProcessStage } from '@/core/models/Bin'
import BinDetails from '@/core/models/BinDetails'
import BinSize from '@/core/models/BinSize'
import BinType from '@/core/models/BinType'
import FloorLocation from '@/core/models/FloorLocation'
import { RemovePackagesResult } from '@/core/models/Package'
import BinsGateway from '@/core/ports/bins'
import { MoveBinFormData } from '@/pages/BinDetailsPage/MoveBinDialog'
import { NewBinFormData } from '@/pages/BinModulePage/NewBinDialog'

import { mapFromApi as mapBinDetailsFromApi } from './mappers/binDetailsMapper'
import { mapFromApi as mapBinFromApi } from './mappers/BinMapper'
import {
  BinDetailsResponse,
  BinMovePayload,
  BinSizesResponse,
  BinTypesResponse,
  FloorLocationsResponse,
  RemovePackagesPayload,
  NewBinPayload,
  NewBinResponse,
  ProcessStagesResponse,
} from './models/Bin'
import {
  BatchMarkForInspectionPayload,
  InspectionSource,
  PartialSuccessPackagesResponse,
} from './models/Package'

function getBinTypes(): Promise<BinType[]> {
  return axios.get<BinTypesResponse>('/v1/bins/properties/sort_types').then(({ data }) =>
    data.sort_types.map<BinType>((type) => ({
      id: type.id,
      name: type.name,
      rules: type.sort_rules.map((rule) => ({ id: rule.id, name: rule.value })),
    }))
  )
}

function getBinSizes(): Promise<BinSize[]> {
  return axios.get<BinSizesResponse>('/v1/bins/properties/sizes').then(({ data }) => data.sizes)
}

function getBinFloorLocations(): Promise<FloorLocation[]> {
  return axios
    .get<FloorLocationsResponse>('/v1/bins/properties/floor_areas')
    .then(({ data }) => data.floor_areas.sort((a, b) => a.name.localeCompare(b.name)))
}

function createNewBin(formData: NewBinFormData): Promise<Bin> {
  return axios
    .post<NewBinResponse, AxiosResponse<NewBinResponse>, NewBinPayload>('/v1/bins', {
      bin: {
        sort_type_id: formData.binTypeId,
        sort_rule_id: formData.binRuleId || null,
        size_id: formData.binSizeId,
        floor_area_id: formData.floorLocationId,
      },
    })
    .then(({ data }) => mapBinFromApi(data.bin))
}

function getBinDetailsByBarcode(barcode: string): Promise<BinDetails> {
  return axios
    .get<BinDetailsResponse>(`/v1/bins/${barcode}/details`)
    .then(({ data }) => mapBinDetailsFromApi(data.bin))
}

function moveBin(binId: number, formData: MoveBinFormData): Promise<void> {
  return axios.patch<void, void, BinMovePayload>(`/v1/bins/${binId}/floor_area`, {
    floor_area: { id: formData.floorLocationId },
  })
}

function printBarcode(binId: number): Promise<void> {
  return axios.post(`/v1/bins/${binId}/print_barcode`)
}

function getBinProcessStages(): Promise<ProcessStage[]> {
  return axios.get<ProcessStagesResponse>('/v1/bins/process_stages').then(({ data }) =>
    data.process_stages.map((processStage) => ({
      name: processStage.name,
      bins: processStage.bins.map((bin) => mapBinFromApi(bin)),
    }))
  )
}

function removePackages(binId: number, barcodes: string[]): Promise<RemovePackagesResult> {
  return axios
    .post<
      PartialSuccessPackagesResponse,
      AxiosResponse<PartialSuccessPackagesResponse>,
      RemovePackagesPayload
    >(`/v1/bins/${binId}/remove_packages`, { barcodes })
    .then(({ data }) => ({
      succeededPackageIds: data.succeeded_packages.map((pack) => pack.id),
      failedBarcodes: data.error?.failed_barcodes ?? [],
      errorMessage: data.error?.message ?? '',
    }))
}

async function markForInspection(
  binId: number,
  barcodes: string[],
  source: InspectionSource
): Promise<void> {
  await axios.post<
    PartialSuccessPackagesResponse,
    AxiosResponse<PartialSuccessPackagesResponse>,
    BatchMarkForInspectionPayload
  >(`/v1/bins/${binId}/request_inspection`, {
    last_mile_tracking_numbers: barcodes,
    inspection_source: source,
  })
}

function markXRayAsCompleted(binId: number): Promise<void> {
  return axios.post(`/v1/bins/${binId}/mark_as_xray_completed`)
}

export default function createApiBinsGateway(): BinsGateway {
  return {
    getBinTypes,
    getBinSizes,
    getBinFloorLocations,
    createNewBin,
    getBinDetailsByBarcode,
    moveBin,
    printBarcode,
    getBinProcessStages,
    removePackages,
    markForInspection,
    markXRayAsCompleted,
  }
}
