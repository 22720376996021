import axios from 'axios'

import { InspectionSource } from '@/gateways/api/models/Package'
import { CourierReadyForXRay } from '@/XRay/models/Courier'
import CourierGateway from '@/XRay/ports/xRay'

import { CourierReadyForXRayResponse } from './models/Courier'

function getAllReadyForXRay(): Promise<CourierReadyForXRay[]> {
  return axios
    .get<CourierReadyForXRayResponse>('/v1/couriers/packages_ready_for_xray')
    .then(({ data }) =>
      data.couriers.map<CourierReadyForXRay>((courier) => ({
        displayName: courier.courier_display_name,
        slug: courier.courier_slug,
        packagesCount: courier.packages_count,
      })),
    )
}

function markXRayAsCompleted(courierSlug: string): Promise<void> {
  return axios.post(`/v1/couriers/${courierSlug}/mark_as_xray_completed`)
}

async function markForInspection(barcodes: string[], source: InspectionSource): Promise<void> {
  await axios.post('/v1/batch/packages/request_inspection', {
    last_mile_tracking_numbers: barcodes,
    inspection_source: source,
  })
}

export default function createApiCourierGateway(): CourierGateway {
  return { getAllReadyForXRay, markXRayAsCompleted, markForInspection }
}
