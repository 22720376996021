import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import Box from '@mui/system/Box'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import AppBar from '@/components/AppBar'
import Dialog from '@/components/Dialog'
import Spacer from '@/components/Spacer'
import { Stat, StatLabel, StatValue } from '@/components/Stat'
import BinDetails from '@/core/models/BinDetails'
import XrayCompletionForm from '@/XRay/XrayCompletionForm'

import BinPackageCard from './BinPackageCard'
import BinPackageXRayCard from './BinPackageXRayCard'
import { BinProvider, useBin } from './BinProvider'
import MoveBinDialog from './MoveBinDialog'
import RemovePackagesDialog from './RemovePackagesDialog'

function BinDetailsPage() {
  const navigate = useNavigate()
  const { bin, printBarcode, markForInspection, markXRayAsCompleted } = useBin()
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [isMoveBinDialogOpen, setIsMoveBinDialogOpen] = useState(false)
  const [isRemovePackagesDialogOpen, setIsRemovePackagesDialogOpen] = useState(false)
  const [isXRayDialogOpen, setIsXRayDialogOpen] = useState(false)
  const [isXRayViewActive, setIsXRayViewActive] = useState(false)
  const isActionsMenuOpen = !!actionsMenuAnchorEl
  const hasPackagesReadyForXRay = bin.packages.some(
    (pack) => pack.warehouseState.value === 'ready_for_xray',
  )

  function handlePrintBarcodeClick() {
    printBarcode()
    setActionsMenuAnchorEl(null)
  }

  function handleRemovePackagesClick() {
    setIsRemovePackagesDialogOpen(true)
    setActionsMenuAnchorEl(null)
  }

  function handleXRayClick() {
    setIsXRayDialogOpen(true)
    setActionsMenuAnchorEl(null)
  }

  async function handleMarkAsCompleted() {
    await markXRayAsCompleted()
    setIsXRayDialogOpen(false)
  }

  return (
    <>
      <AppBar title={bin.name}>
        <Stack direction="row" spacing={1}>
          <Button variant="text" onClick={() => navigate('/bin-module')}>
            back to bin module
          </Button>
          <ToggleButton
            selected={isXRayViewActive}
            onChange={() => {
              setIsXRayViewActive(!isXRayViewActive)
            }}
            value="xray view active"
            size="small"
            color="primary"
          >
            x-ray view
          </ToggleButton>
        </Stack>
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Box bgcolor="white">
        <Container maxWidth="md" sx={{ py: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Stat>
              <StatLabel variant="h4">{bin.processStage}</StatLabel>
              <StatValue variant="h5">{bin.displayName}</StatValue>
            </Stat>
            <Stat>
              <StatLabel>Location</StatLabel>
              <StatValue variant="h3">
                <Stack direction="row" spacing={1} alignItems="center">
                  <span>{bin.floorLocation.name}</span>
                  <Button onClick={() => setIsMoveBinDialogOpen(true)}>move bin</Button>
                </Stack>
              </StatValue>
            </Stat>
            {bin.droppedAt ? (
              <Stat>
                <StatLabel>Dropped at</StatLabel>
                <StatValue variant="h3">
                  {bin.droppedAt ? dayjs(bin.droppedAt).fromNow() : '-'}
                </StatValue>
              </Stat>
            ) : (
              <Stat>
                <StatLabel>Oldest Package Received</StatLabel>
                <StatValue variant="h3">
                  {bin.oldestPackageAddedDateTime
                    ? dayjs(bin.oldestPackageAddedDateTime).fromNow()
                    : '-'}
                </StatValue>
              </Stat>
            )}

            <Stat>
              <StatLabel>Total Weight</StatLabel>
              <StatValue variant="h3">
                {bin.packagesWeight.value} {bin.packagesWeight.unit}
              </StatValue>
            </Stat>
            <Stat>
              <StatLabel>Package Count</StatLabel>
              <StatValue variant="h3">{bin.packagesCount} pcs</StatValue>
            </Stat>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => setActionsMenuAnchorEl(e.currentTarget)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              actions
            </Button>
            <Menu
              open={isActionsMenuOpen}
              anchorEl={actionsMenuAnchorEl}
              onClose={() => setActionsMenuAnchorEl(null)}
            >
              <MenuItem onClick={handlePrintBarcodeClick}>Print Barcode</MenuItem>
              <MenuItem onClick={handleRemovePackagesClick}>Remove Packages</MenuItem>
              <MenuItem onClick={handleXRayClick} disabled={!hasPackagesReadyForXRay}>
                X-ray
              </MenuItem>
            </Menu>
          </Stack>
        </Container>
      </Box>
      <Divider light />
      <Container maxWidth="md" sx={{ py: 2 }}>
        <Stack spacing={2}>
          {bin.packages.map((pack) =>
            isXRayViewActive ? (
              <BinPackageXRayCard key={pack.id} role="listitem" binPackage={pack} />
            ) : (
              <BinPackageCard key={pack.id} role="listitem" binPackage={pack} />
            ),
          )}
        </Stack>
      </Container>
      <MoveBinDialog
        bin={bin}
        open={isMoveBinDialogOpen}
        onClose={() => setIsMoveBinDialogOpen(false)}
      />
      <RemovePackagesDialog
        bin={bin}
        open={isRemovePackagesDialogOpen}
        onClose={() => setIsRemovePackagesDialogOpen(false)}
      />
      <Dialog
        title="X-ray"
        open={isXRayDialogOpen}
        onClose={() => setIsXRayDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <XrayCompletionForm
          onMarkAsCompleted={handleMarkAsCompleted}
          onScanInspectionSubmit={markForInspection}
        />
      </Dialog>
    </>
  )
}

export type NavigationStateParams = Partial<{ binDetails: BinDetails }> | undefined

export default function BinPageWrapper() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams

  useEffect(() => {
    if (!navigationState?.binDetails) {
      navigate('/bin-module')
    }
  }, [])

  if (!navigationState?.binDetails) return null

  return (
    <BinProvider initialBin={navigationState.binDetails}>
      <BinDetailsPage />
    </BinProvider>
  )
}
