import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

import { ScanPackageResult } from './models/scanPackageResult'

interface ScanPackageQueryParams extends UseQueryOptions<ScanPackageResult> {
  barcode: string
}

export default function useScanPackageQuery({
  barcode,
  enabled,
  ...params
}: ScanPackageQueryParams) {
  const { courierSortGateway } = useGateways()

  const query = useQuery({
    queryKey: ['courierSortPackageScan', barcode],
    queryFn: () => courierSortGateway.scanPackage(barcode),
    cacheTime: 0,
    staleTime: 0,
    enabled: !!barcode && enabled,
    ...params,
  })

  return query
}
