import Stack, { StackProps } from '@mui/material/Stack'

export default function PhotoInstruction(props: StackProps) {
  return (
    <Stack {...props} spacing={1}>
      <div>Take clear photos of the following:</div>
      <Stack
        spacing={1}
        component="ol"
        sx={{ p: 0, m: 0, listStylePosition: 'inside', 'li::marker': { fontWeight: 'bold' } }}
      >
        <li>
          <b>Entire outer box</b>
        </li>
        <li>
          <b>Shipping Label</b>
        </li>
        <li>
          <b>Item:</b> Items should be taken out from the box and packing materials removed. DO NOT
          REMOVE manufacturer packaging for new items.
        </li>
        <li>
          <b>Serial Number:</b> Only required if the item has a visible serial number. Serial
          numbers DO NOT need to be captured if they can only be accessed by turning on the device.
        </li>
        <li>
          <b>Additional internal documents (if any)</b>
        </li>
      </Stack>
    </Stack>
  )
}
