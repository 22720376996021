import MuiStack, { StackProps as MuiStackProps } from '@mui/material/Stack'
import React from 'react'

function isFunction<T>(value: T | CallableFunction): value is CallableFunction {
  return value instanceof Function
}

export default function Stack({ children, ...props }: MuiStackProps) {
  const childrenNonEmpty = React.Children.map(children, (child) => {
    const isChild =
      React.isValidElement(child) && isFunction(child.type) ? !!child.type(child.props) : !!child
    return isChild ? child : null
  })

  return <MuiStack {...props}>{childrenNonEmpty}</MuiStack>
}
