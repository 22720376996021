import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { FormEvent, useRef } from 'react'

interface LoginFormProps {
  onSubmit: (stationId: string) => void
}

export default function LoginForm({ onSubmit }: LoginFormProps) {
  const stationIdRef = useRef<HTMLInputElement>(null)

  function submitStationId(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const stationIdValue = stationIdRef.current?.value

    if (stationIdValue) {
      onSubmit(stationIdValue)
    }
  }

  return (
    <form onSubmit={submitStationId}>
      <Stack spacing={2}>
        <TextField
          inputProps={{ 'data-hj-allow': true }}
          inputRef={stationIdRef}
          placeholder="Station ID"
          fullWidth
          autoFocus
        />
        <Button type="submit" variant="contained" color="secondary" fullWidth>
          next
        </Button>
      </Stack>
    </form>
  )
}
