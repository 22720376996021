import Stack from '@mui/material/Stack'

import Dialog, { DialogProps } from '@/components/Dialog'
import ScanInput from '@/components/ScanInput'
import { Stat, StatLabel, StatValue } from '@/components/Stat'
import { ScanPackageResult } from '@/courierSort/models/scanPackageResult'

import { CourierStyleCard } from './CourierStyleCard'
import { ScanBinResult } from './models/scanBinResult'
import { useBinValidationMutation } from './useBinValidationMutation'

interface ScanResultDialogProps extends Omit<DialogProps, 'title'> {
  result: ScanPackageResult | undefined
  onBinScanSuccess: (binResult: ScanBinResult) => void
  packageBarcode: string
}

export default function PackageResultDialog({
  result,
  onBinScanSuccess,
  packageBarcode,
  ...props
}: ScanResultDialogProps) {
  const binValidateMutation = useBinValidationMutation()

  function handleBarcodeChange(binBarcode: string) {
    binValidateMutation.mutate({ packageBarcode, binBarcode }, { onSuccess: onBinScanSuccess })
  }

  return (
    <Dialog title="Scan to Place Package in Bin" maxWidth="xs" fullWidth {...props}>
      {result && (
        <Stack spacing={2}>
          <CourierStyleCard courier={result.courier} />
          <Stat horizontal>
            <StatLabel variant="h4">Tracking #</StatLabel>
            <StatValue>{result.package.lastMileTrackingNumber}</StatValue>
          </Stat>
          <Stat horizontal>
            <StatLabel variant="h4">Package ID</StatLabel>
            <StatValue>{result.package.ebayPackageId}</StatValue>
          </Stat>
          <ScanInput
            placeholder="Scan bin ID to place package in bin"
            onChange={handleBarcodeChange}
            autoFocus
          />
        </Stack>
      )}
    </Dialog>
  )
}
