import { CourierReturnReason } from './gateways/api/models/CourierReturn'

interface ReasonOption {
  label: string
  value: CourierReturnReason
}

export const SHIPPED_REASON_OPTIONS: ReasonOption[] = [
  {
    label: 'Unclaimed',
    value: 'unclaimed',
  },
  {
    label: 'Insufficient Address',
    value: 'insufficient_address',
  },
  {
    label: 'Refused',
    value: 'refused',
  },
  {
    label: 'Import not authorized',
    value: 'import_not_authorized',
  },
  {
    label: 'Refused by customs - Dangerous Goods/Prohibited Goods',
    value: 'refused_by_customs',
  },
  {
    label: 'Unknown/Other',
    value: 'unknown',
  },
]

export const UNSHIPPED_REASON_OPTIONS: ReasonOption[] = [
  {
    label: 'Duplicate Package',
    value: 'duplicate_package',
  },
  {
    label: 'Denied Party',
    value: 'denied_party',
  },
  {
    label: 'Dangerous Goods/Prohibited Goods Restriction',
    value: 'dg_pg_restriction',
  },
  {
    label: 'Battery',
    value: 'battery_restriction',
  },
  {
    label: 'Restricted Countries/Undeliverable',
    value: 'restricted_country',
  },
  {
    label: 'Damaged',
    value: 'damaged',
  },
  {
    label: 'Missort',
    value: 'missorted',
  },
  {
    label: 'INR (Item Not Received)',
    value: 'item_not_received',
  },
  {
    label: 'Unknown/Other',
    value: 'unknown',
  },
]
