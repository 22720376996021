import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'

import { Stat, StatLabel, StatValue } from '@/components/Stat'
import { BinPackage } from '@/core/models/BinDetails'

import BinPackageItemsList from './BinPackageItemsList'

interface BinPackageCardProps extends CardProps {
  binPackage: BinPackage
}

export default function BinPackageXRayCard({ binPackage, ...props }: BinPackageCardProps) {
  return (
    <Card {...props}>
      <CardContent>
        <Stack direction="row" gap={6}>
          <Stat>
            <StatLabel>Tracking No</StatLabel>
            <StatValue variant="h3">{binPackage.lastMileTrackingNumber}</StatValue>
          </Stat>
          <Stat>
            <StatLabel>Items</StatLabel>
            <StatValue variant="h5">
              <BinPackageItemsList items={binPackage.items} />
            </StatValue>
          </Stat>
        </Stack>
      </CardContent>
    </Card>
  )
}
