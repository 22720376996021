import { useGateways } from '../contexts/gateways'

export default function useBins() {
  const { binsGateway } = useGateways()

  return {
    createNewBin: binsGateway.createNewBin,
    getBinDetailsByBarcode: binsGateway.getBinDetailsByBarcode,
    getBinProcessStages: binsGateway.getBinProcessStages,
  }
}
