import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useLocation, useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Button from '@/components/Button'
import Spacer from '@/components/Spacer'

import { SearchFormData } from '../pages/ScanPage/ScanPage'
import Order from './models/Order'
import RegularPackageResult from './regularPackageResult/RegularPackageResult'
import ReturnPackageResult from './returnPackageResult/ReturnPackageResult'

export type NavigationStateParams =
  | Partial<{
      orders: Order[]
      searchParams: Partial<SearchFormData>
    }>
  | undefined

export default function SearchResultPage() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams
  const orders = navigationState?.orders ?? []

  return (
    <>
      <AppBar>
        <Button variant="text" onClick={() => navigate('/scan')}>
          back to scan
        </Button>
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="md">
        <Stack spacing={2.5} sx={{ my: 2 }}>
          <Typography variant="body1" sx={{ ml: 3 }}>
            <strong>{orders.length}</strong> orders found
          </Typography>
          {orders.map(({ ...order }, index) => (
            <Paper key={order.id} sx={{ p: 3 }}>
              {order.isReturn ? (
                <ReturnPackageResult order={order} index={index} />
              ) : (
                <RegularPackageResult order={order} index={index} />
              )}
            </Paper>
          ))}
        </Stack>
      </Container>
    </>
  )
}
