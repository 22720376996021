import axios, { AxiosResponse } from 'axios'

import { ScanBinResult } from '@/courierSort/models/scanBinResult'
import { ScanPackageResult } from '@/courierSort/models/scanPackageResult'
import CourierSortGateway from '@/courierSort/ports/courierSort'

import { ScanBinResponse } from './models/ScanBinResponse'
import { ScanPackageResponse } from './models/ScanPackageResponse'
import { ValidateBinPayload } from './models/ValidateBinPayload'

function scanPackage(barcode: string): Promise<ScanPackageResult> {
  return axios
    .get<ScanPackageResponse>(`/v1/courier_sort/packages/${barcode}`)
    .then(({ data }) => ({
      courier: {
        slug: data.package.courier_slug,
        name: data.package.courier_display_name,
        number: data.package.courier_display_number,
      },
      package: {
        lastMileTrackingNumber: data.package.last_mile_tracking_number,
        ebayPackageId: data.package.ebay_package_id,
      },
    }))
}

function validateBin(packageBarcode: string, binBarcode: string): Promise<ScanBinResult> {
  return axios
    .post<ScanBinResponse, AxiosResponse<ScanBinResponse>, ValidateBinPayload>(
      `/v1/courier_sort/packages/${packageBarcode}/sort`,
      {
        bin_barcode: binBarcode,
      }
    )
    .then(({ data }) => ({
      courier: {
        slug: data.package.courier_slug,
        name: data.package.courier_display_name,
        number: data.package.courier_display_number,
      },
      package: {
        lastMileTrackingNumber: data.package.last_mile_tracking_number,
      },
    }))
}

export default function createApiCourierSortGateway(): CourierSortGateway {
  return { scanPackage, validateBin }
}
