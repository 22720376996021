import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useScanQuery(barcode: string) {
  const { labelProcessingGateway } = useGateways()

  const query = useQuery({
    queryKey: ['labelProcessingScan', barcode],
    queryFn: () => labelProcessingGateway.scan(barcode),
    cacheTime: 0,
    staleTime: 0,
    enabled: !!barcode,
  })

  return query
}
