import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '../../components/AppBar'
import Button from '../../components/Button'
import Spacer from '../../components/Spacer'
import { useSession } from '../../contexts/session'

export default function HomePage() {
  const navigate = useNavigate()
  const { logout } = useSession()
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)

  async function handleLogout() {
    setIsLogoutLoading(true)
    try {
      await logout()
    } finally {
      setIsLogoutLoading(false)
    }
    navigate('/login')
  }

  return (
    <>
      <AppBar>
        <Spacer />
        <Button variant="text" onClick={handleLogout} loading={isLogoutLoading}>
          log out
        </Button>
      </AppBar>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h1" sx={{ color: 'ink.main', alignSelf: 'center' }}>
            Exports Scanner
          </Typography>
          <Button onClick={() => navigate('/scan')} variant="contained">
            scan package
          </Button>
          <Button onClick={() => navigate('/x-ray')} variant="contained" color="secondary">
            x-ray
          </Button>
          <Button onClick={() => navigate('/manifest')} variant="contained" color="secondary">
            manifest
          </Button>
          <Button onClick={() => navigate('/manifest-v2')} variant="contained" color="secondary">
            manifest v2
          </Button>
          <Button onClick={() => navigate('/bin-module')} variant="contained" color="secondary">
            bin module
          </Button>
          <Button onClick={() => navigate('/induction')} variant="contained" color="secondary">
            induction
          </Button>
          <Button
            onClick={() => navigate('/label-processing')}
            variant="contained"
            color="secondary"
          >
            label processing
          </Button>
          <Button onClick={() => navigate('/courier-sort')} variant="contained" color="secondary">
            courier sort
          </Button>
          <Button onClick={() => navigate('/pre-inspection')} variant="contained" color="secondary">
            pre-inspection
          </Button>
        </Stack>
      </Container>
    </>
  )
}
