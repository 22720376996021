import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import ScanIcon from '@/components/icons/ScanIcon'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'
import { useScan } from '@/hooks/useScan'
import PackageNotFoundResult from '@/labelProcessing/PackageNotFoundResult'

import { CourierStyleCard } from './CourierStyleCard'
import { ScanBinResult } from './models/scanBinResult'
import PackageResultDialog from './ScanResultDialog'
import useScanPackageQuery from './useScanPackageQuery'

export default function CourierSortPage() {
  const navigate = useNavigate()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const packageBarcode = useScan({ enabled: !isDialogOpen })
  const scanPackageQuery = useScanPackageQuery({
    barcode: packageBarcode,
  })

  useEffect(() => {
    if (scanPackageQuery.isSuccess) {
      setIsDialogOpen(true)
    }
  }, [scanPackageQuery.isSuccess])

  const [scanBinResult, setScanBinResult] = useState<ScanBinResult>()
  const hasBinResult = !!scanBinResult
  const showSmallScanText = hasBinResult || scanPackageQuery.isError

  return (
    <>
      <Stack height="100%">
        <AppBar title="Courier Sort">
          <Spacer />
          <Button variant="text" onClick={() => navigate('/')}>
            home
          </Button>
        </AppBar>
        <Stack flex={1} alignItems="center" justifyContent="space-around">
          {scanPackageQuery.isFetching ? (
            <Loading />
          ) : (
            <>
              {hasBinResult && scanPackageQuery.isSuccess && (
                <Stack spacing={2}>
                  <Stack alignItems="center" justifyContent="space-around">
                    <Typography fontSize={30} fontWeight="bold">
                      To be dropped
                    </Typography>
                    <Typography fontSize={30}>
                      {scanBinResult.package.lastMileTrackingNumber}
                    </Typography>
                  </Stack>
                  <CourierStyleCard courier={scanBinResult.courier} width={407} />
                </Stack>
              )}
              {scanPackageQuery.isError && <PackageNotFoundResult barcode={packageBarcode} />}
              <Stack direction="row" alignItems="center" spacing={1}>
                <ScanIcon sx={{ fontSize: showSmallScanText ? 24 : 40 }} />
                <Typography fontSize={showSmallScanText ? 20 : 36}>
                  Scan to process {showSmallScanText && 'the next '}package
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <PackageResultDialog
        open={isDialogOpen}
        onBinScanSuccess={(data) => {
          setIsDialogOpen(false)
          setScanBinResult(data)
        }}
        result={scanPackageQuery.data}
        packageBarcode={packageBarcode}
      />
    </>
  )
}
