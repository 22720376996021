import CloseIcon from '@mui/icons-material/Close'
import { createTheme } from '@mui/material/styles'

import COLORS from './colors'

const SHADOW_SM = '0 2px 4px rgba(40, 78, 102, 0.2)'
const SHADOW_LG = '0 4px 4px 0 rgba(40, 78, 102, 0.2)'

export const theme = createTheme({
  typography: {
    fontFamily: "'Karla', sans-serif",
    fontSize: 13,
    h1: {
      fontSize: 20,
      color: COLORS.inkDarkest,
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      color: COLORS.inkDarkest,
    },
    h3: {
      fontSize: 16,
      color: COLORS.inkDarkest,
      fontWeight: 700,
    },
    h4: {
      fontSize: 15,
      color: COLORS.inkDarkest,
    },
    h5: {
      fontSize: 13,
      color: COLORS.inkNormal,
      fontWeight: 700,
    },
    body1: {
      fontSize: 13,
      color: COLORS.inkNormal,
    },
    body2: {
      fontSize: 13,
      color: COLORS.inkDark,
    },
    subtitle1: {
      fontSize: 16,
      color: COLORS.inkNormal,
    },
    subtitle2: {
      fontSize: 11,
      color: COLORS.inkNormal,
    },
    caption: {
      fontSize: 13,
      color: COLORS.inkLight,
      fontStyle: 'italic',
    },
  },
  palette: {
    background: { default: COLORS.skyNormal },
    success: {
      main: COLORS.success,
    },
    error: {
      main: COLORS.failure,
    },
    teal: {
      main: COLORS.tealNormal,
      dark: COLORS.tealDark,
      light: COLORS.tealLight,
      contrastText: COLORS.white,
    },
    blue: {
      main: COLORS.blueNormal,
      dark: COLORS.blueDark,
      light: COLORS.blueLight,
    },
    green: {
      main: COLORS.greenNormal,
      dark: COLORS.greenDark,
      light: COLORS.greenLight,
    },
    yellow: {
      main: COLORS.yellowNormal,
      dark: COLORS.yellowDark,
      light: COLORS.yellowLight,
    },
    red: {
      main: COLORS.redNormal,
      dark: COLORS.redDark,
      light: COLORS.redLight,
    },
    ink: {
      main: COLORS.inkNormal,
      dark: COLORS.inkDark,
      light: COLORS.inkLight,
    },
    sky: {
      main: COLORS.skyNormal,
      dark: COLORS.skyDark,
      light: COLORS.skyLight,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.skyLight,
          boxShadow: 'none',
          border: `1px solid ${COLORS.skyDark}`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            '&[type=number]': {
              MozAppearance: 'textfield',
            },
            '&[type=number]::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '&[type=number]::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '50px !important',
          padding: '0 !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          transition: 'none',
        },
        contained: {
          height: 35,
          boxShadow: SHADOW_SM,

          '&.Mui-disabled': {
            backgroundImage: 'none',
            backgroundColor: COLORS.skyDark,
          },

          '&:hover': {
            backgroundImage: 'none',
            boxShadow: SHADOW_SM,
          },

          '&:active': {
            backgroundImage: 'none !important',
            boxShadow: SHADOW_LG,
          },
        },
        containedPrimary: {
          backgroundImage: `linear-gradient(-180deg, ${COLORS.greenNormal} 0%, ${COLORS.greenDark} 100%)`,
          color: COLORS.white,

          '&:hover': { backgroundColor: COLORS.greenNormal },
          '&:active': { backgroundColor: COLORS.greenDark },
        },
        containedSecondary: {
          backgroundImage: `linear-gradient(-180deg, ${COLORS.white} 0%, ${COLORS.skyNormal} 100%)`,
          color: COLORS.inkNormal,

          '&:hover': { backgroundColor: COLORS.white },
          '&:active': { backgroundColor: COLORS.skyDark },
        },
        containedError: {
          backgroundImage: `linear-gradient(-180deg, ${COLORS.redNormal} 0%, ${COLORS.redDark} 100%)`,
          color: COLORS.white,

          '&:hover': { backgroundColor: COLORS.redNormal },
          '&:active': { backgroundColor: COLORS.redDark },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          '&.MuiToggleButton-primary.Mui-selected': {
            borderColor: COLORS.blueNormal,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: COLORS.inkDark,
          borderColor: COLORS.skyDark,
          borderWidth: 1,
          borderStyle: 'solid',
          width: '100%',
          backgroundColor: COLORS.white,

          '&.Mui-focused': {
            borderColor: COLORS.inkLight,
          },

          '&.Mui-disabled': {
            color: COLORS.inkDark,
            backgroundColor: COLORS.skyLight,
          },

          '&.Mui-error': {
            borderColor: COLORS.redDark,
            backgroundColor: COLORS.redLight,
          },
        },

        input: {
          padding: 10,
        },

        multiline: {
          padding: 0,
        },

        inputMultiline: {
          padding: 10,
        },

        notchedOutline: {
          borderWidth: '0 !important',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 70,
          height: 50,
          padding: 0,
        },
        switchBase: {
          padding: 2,
          color: `${COLORS.tealNormal} !important`,
        },
        thumb: {
          width: 16,
          height: 16,
          margin: 15,
          backgroundColor: COLORS.tealNormal,
          boxShadow: 'none',
        },
        track: {
          width: 40,
          height: 20,
          margin: 15,
          border: 'none',
          boxShadow: '0px 2px 15px rgba(40, 78, 102, 0.1)',
          borderRadius: 10,
          opacity: 1,
          backgroundColor: 'white !important',
        },
        checked: {},
      },
    },
    MuiFormHelperText: {
      styleOverrides: { root: { marginLeft: 0, marginRight: 0, fontStyle: 'initial' } },
    },
    MuiSvgIcon: {
      styleOverrides: { colorError: { color: COLORS.redDark } },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiAlert-root': {
            minWidth: 400,
            backgroundColor: COLORS.black,
            '& .MuiSvgIcon-root': {
              fill: COLORS.white,
            },
            '& .MuiAlert-message': {
              color: COLORS.white,
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: COLORS.inkLight,
        },
        light: {
          borderColor: COLORS.skyDark,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLORS.inkNormal,
          fontSize: 14,
          '&.Mui-focused': {
            color: 'inherit !important',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: SHADOW_SM,
          borderRadius: 8,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px !important',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseIcon />,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          fontWeight: 'bold',
        },
        colorPrimary: {
          backgroundColor: COLORS.blueNormal,
        },
        outlined: {
          border: `1px solid ${COLORS.skyDark}`,
        },
        deleteIcon: {
          color: COLORS.skyDark,
          '&:hover': {
            color: COLORS.skyDarkest,
          },
        },
      },
    },
  },
})
