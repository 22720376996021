import Chance from 'chance'

import { PackageMeasurements } from '@/core/models/Package'

const chance = new Chance()

export const packageMeasurementsStub = (): PackageMeasurements => ({
  weight: {
    value: chance.floating({ min: 0 }),
    unit: 'lb',
  },
  dimensions: {
    length: chance.integer({ min: 0 }),
    width: chance.integer({ min: 0 }),
    height: chance.integer({ min: 0 }),
    unit: 'in',
  },
  measurementId: chance.integer({ min: 0 }),
  scannedOn: chance.date().toISOString(),
})
