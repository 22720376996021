import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Session from '../core/models/Session'
import SessionGateway from '../core/ports/session'
import { sessionStub } from '../gateways/fake/stubs/Session'
import { useGateways } from './gateways'

interface SessionContextData extends SessionGateway {
  session: Session | null
  login(stationId: string, password: string): Promise<Session>
  logout(): Promise<void>
}

const SessionContext = createContext<SessionContextData | undefined>(undefined)

interface SessionProviderProps {
  children: React.ReactNode
}

function SessionProvider({ children }: SessionProviderProps) {
  const navigate = useNavigate()
  const { sessionGateway } = useGateways()
  const [session, setSession] = useState<Session | null>(getSession())

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    function initSessionTimeout(session: Session) {
      const currentTimeMilli = new Date().getTime()
      const expiryTimeMilli = new Date(session.expiresAt).getTime()
      const oneMinuteMilli = 60000

      timeoutId = setTimeout(resetLoginSession, expiryTimeMilli - currentTimeMilli - oneMinuteMilli)
    }

    if (session) initSessionTimeout(session)
    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
  }, [session])

  function getSession(): Session | null {
    const data = sessionStorage.getItem('auth')
    if (data) {
      const session: Session = JSON.parse(data)
      return session
    }
    return null
  }

  async function login(stationId: string, password: string): Promise<Session> {
    const session = await sessionGateway.login(stationId, password)
    sessionStorage.setItem('auth', JSON.stringify(session))
    setSession(session)
    return session
  }

  async function logout(): Promise<void> {
    await sessionGateway.logout()
    resetLoginSession()
  }

  function resetLoginSession() {
    emptySession()
    navigate('/login')
  }

  function emptySession() {
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('featureFlags')
    setSession(null)
  }

  return (
    <SessionContext.Provider value={{ session, login, logout }}>{children}</SessionContext.Provider>
  )
}

export interface FakeGatewayProviderProps {
  session?: Session
  children: React.ReactNode
}

function FakeSessionProvider({ session, children }: FakeGatewayProviderProps) {
  const { sessionGateway } = useGateways()
  const data = {
    session: session ?? sessionStub(),
    ...sessionGateway,
  }
  return <SessionContext.Provider value={data}>{children}</SessionContext.Provider>
}

function useSession() {
  const sessionContext = useContext(SessionContext)

  if (sessionContext === undefined) {
    throw new Error('useSession must be used within a SessionProvider')
  }
  return sessionContext
}

export { SessionProvider, FakeSessionProvider, useSession }
