import Stack from '@mui/material/Stack'
import React, { useState } from 'react'

import Button from '@/components/Button'
import MultiScanInput from '@/components/MultiScanInput'

interface ScanInspectionFormProps {
  onSubmit(barcodes: string[]): Promise<void>
}

export default function ScanInspectionForm({ onSubmit }: ScanInspectionFormProps) {
  const [barcodes, setBarcodes] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const isFormValid = barcodes.length > 0
  const buttonLabel =
    barcodes.length > 0
      ? `mark ${barcodes.length} package${barcodes.length > 1 ? 's' : ''} for inspection`
      : `mark packages for inspection`

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsLoading(true)
    try {
      await onSubmit(barcodes)
      setBarcodes([])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1}>
        <MultiScanInput value={barcodes} onChange={setBarcodes} disabled={isLoading} fullWidth />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!isFormValid}
          loading={isLoading}
          fullWidth
        >
          {buttonLabel}
        </Button>
      </Stack>
    </form>
  )
}
