import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CheckboxRounded from '@/components/CheckboxRounded'
import Spacer from '@/components/Spacer'

import CourierBin from './models/CourierBin'

interface CourierBinProps {
  bin: CourierBin
  selected: boolean
  onClick(bin: CourierBin): void
}

export default function Bin({ bin, selected, onClick }: CourierBinProps) {
  return (
    <Card key={bin.id} onClick={() => onClick(bin)}>
      <CardActionArea>
        <CardContent>
          <Stack direction="row" bgcolor="white" alignItems="center" spacing={1}>
            <CheckboxRounded checked={selected} />
            <Typography variant="h3">{bin.name}</Typography>
            <Typography variant="h4">{bin.floorLocation.name}</Typography>
            <Spacer />
            <Typography variant="h4">
              <strong>{bin.packagesCount}</strong> pcs
            </Typography>

            <Typography variant="h4">/</Typography>
            <Typography variant="h4">
              <strong>{bin.packagesWeight.value.toFixed(2)}</strong> {bin.packagesWeight.unit}s
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
