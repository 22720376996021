import Box from '@mui/material/Box'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'

import Pulse from './Pulse'

interface ButtonProps extends MuiButtonProps {
  loading?: boolean
}

export default function Button({ loading = false, disabled, children, ...props }: ButtonProps) {
  const theme = useTheme()
  let bgcolor = ''

  if (loading && !disabled) {
    if (props.variant === 'contained') {
      switch (props.color) {
        case 'secondary':
          bgcolor = `${theme.palette.sky.dark} !important`
          break
        case 'info':
          bgcolor = `${theme.palette.info.main} !important`
          break
        case 'error':
          bgcolor = `${theme.palette.red.dark} !important`
          break
        default:
          bgcolor = `${theme.palette.green.dark} !important`
      }
    } else {
      bgcolor = `${theme.palette.sky.dark} !important`
    }
  }

  return (
    <MuiButton {...props} disabled={disabled || loading} sx={{ ...props.sx, bgcolor }}>
      {loading && <Pulse sx={{ position: 'absolute' }} />}
      <Box visibility={loading ? 'hidden' : 'visible'}>{children}</Box>
    </MuiButton>
  )
}
