import Chance from 'chance'

import Order, { OrderPackage } from '@/searchResult/models/Order'

const chance = new Chance()

const orderPackageStub = (): OrderPackage => ({
  id: chance.guid(),
  ebayPackageId: 'ZYU89YT_ESUS12345678',
  combinedShipment: false,
  firstMileTrackingNumber: chance.string({
    length: 16,
    casing: 'upper',
    alpha: true,
    numeric: true,
  }),
  lastMileTrackingNumber: chance.string({
    length: 12,
    casing: 'upper',
    alpha: true,
    numeric: true,
  }),
  warehouseState: { value: 'not_received', displayName: 'not received' },
  easyshipShipmentId: 'EEUS000000147FR',
  scanReceived: '2022-10-20T10:53:06Z',
  lastStationId: 12,
})

export const orderStub = (): Order => ({
  id: chance.guid(),
  isReturn: false,
  evtn: chance.string({ length: 7, casing: 'upper', alpha: true }),
  senderName: chance.name(),
  combinedShipment: false,
  senderAddress: {
    fullName: chance.name(),
    addressLine1: chance.address(),
    addressLine2: '',
    city: chance.city(),
    state: chance.state(),
    postalCode: chance.postcode(),
    countryCode: chance.country(),
    country: chance.country({ full: true }),
  },
  receiverAddress: {
    fullName: chance.name(),
    addressLine1: chance.address(),
    addressLine2: '',
    city: chance.city(),
    state: chance.state(),
    postalCode: chance.postcode(),
    countryCode: chance.country(),
    country: chance.country({ full: true }),
  },
  items: [
    {
      ebayItemId: chance.string({ length: 15, numeric: true }),
      description: 'Rolex Date just 41mm Stainless Steel Blue Roman Dial Automatic-BM-below 2000',
      url: 'http://cgi.qa.ebay.com/ws/eBayISAPI.dll?ViewItem&item=170027633176',
      quantity: chance.integer({ min: 1, max: 100 }),
      sku: chance.string({ length: 10, numeric: true }),
    },
    {
      ebayItemId: chance.string({ length: 15, numeric: true }),
      description: 'Rolex Date just 41mm Stainless Steel Blue Roman Dial Automatic-BM-below 2000',
      url: 'http://cgi.qa.ebay.com/ws/eBayISAPI.dll?ViewItem&item=170027633176',
      quantity: chance.integer({ min: 1, max: 100 }),
      sku: chance.string({ length: 10, numeric: true }),
    },
  ],
  packages: [orderPackageStub(), orderPackageStub()],
})
