import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'

import { Stat, StatLabel, StatValue } from '@/components/Stat'
import { BinPackage } from '@/core/models/BinDetails'

import BinPackageItemsList from './BinPackageItemsList'

interface BinPackageCardProps extends CardProps {
  binPackage: BinPackage
}

export default function BinPackageCard({ binPackage, ...props }: BinPackageCardProps) {
  return (
    <Card {...props}>
      <CardContent>
        <Stack direction="row" gap={2}>
          <Stat>
            <StatLabel>EVTN</StatLabel>
            <StatValue variant="h3">{binPackage.evtn}</StatValue>
          </Stat>
          <Stack gap={2} flexShrink={0}>
            <Stat>
              <StatLabel>Easyship Shipment ID</StatLabel>
              <StatValue variant="h3">{binPackage.easyshipShipmentId}</StatValue>
            </Stat>
            <Stat>
              <StatLabel>Scan Received</StatLabel>
              <StatValue variant="h3">
                {binPackage.scanReceivedDateTime
                  ? dayjs(binPackage.scanReceivedDateTime).format('DD MMM YYYY, h:mm A')
                  : '-'}
              </StatValue>
            </Stat>
          </Stack>
          <Stack gap={2}>
            <Stat>
              <StatLabel>Warehouse State</StatLabel>
              <StatValue variant="h3">{binPackage.warehouseState.displayName}</StatValue>
            </Stat>
            <Stat>
              <StatLabel>Items</StatLabel>
              <StatValue variant="h5">
                <BinPackageItemsList items={binPackage.items} />
              </StatValue>
            </Stat>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
