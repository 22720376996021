import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useAllPendingQuery() {
  const { manifestGateway } = useGateways()

  return useQuery({
    queryKey: ['AllPendingManifest'],
    queryFn: manifestGateway.getAllPending,
  })
}
