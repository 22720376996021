import {
  QueryCache,
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastOptions } from 'react-toastify'

import { toastApiError } from './components/Toastify'

export function getQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
    queryCache: new QueryCache({
      onError: (error, query) =>
        toastApiError(error, query.meta?.errorToastOptions as ToastOptions),
    }),
  })
}

interface QueryClientProviderProps {
  children: React.ReactNode
}

export default function QueryClientProvider({ children }: QueryClientProviderProps) {
  return (
    <ReactQueryClientProvider client={getQueryClient()}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </ReactQueryClientProvider>
  )
}
