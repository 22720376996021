import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import AppBar from '@/components/AppBar'
import ScanInput from '@/components/ScanInput'
import Spacer from '@/components/Spacer'
import Bin, { ProcessStage } from '@/core/models/Bin'
import useBins from '@/core/useBins'

import { NavigationStateParams as BinDetailsPageNavigationStateParams } from '../BinDetailsPage/BinDetailsPage'
import BinProcessStages from './BinProcessStages'
import NewBinDialog from './NewBinDialog'

export default function BinModulePage() {
  const navigate = useNavigate()
  const { getBinDetailsByBarcode, getBinProcessStages } = useBins()
  const [isNewBinDialogOpen, setIsNewBinDialogOpen] = useState(false)
  const [processStages, setProcessStages] = useState<ProcessStage[]>([])

  function handleNewBinCreated(newBin: Bin) {
    setProcessStages([
      ...processStages.map<ProcessStage>((processStage) => {
        const isNewBinProcessStage = processStage.name === newBin.processStage

        if (isNewBinProcessStage) {
          return { ...processStage, bins: processStage.bins.concat([newBin]) }
        }
        return processStage
      }),
    ])
  }

  useEffect(() => {
    getBinProcessStages().then(setProcessStages)
  }, [])

  function handleScanChange(barcode: string) {
    getBinDetailsByBarcode(barcode).then((binDetails) => {
      const navigationState: BinDetailsPageNavigationStateParams = {
        binDetails,
      }

      navigate('/bin-details', { state: navigationState })
    })
  }

  return (
    <>
      <AppBar title="Bin Module">
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="md" sx={{ my: 3 }}>
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="space-between">
            <ScanInput
              placeholder="Scan to see bin details"
              autoFocus
              sx={{ maxWidth: 230 }}
              onChange={handleScanChange}
            />
            <Button
              onClick={() => setIsNewBinDialogOpen(true)}
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
            >
              open new bin
            </Button>
          </Stack>
          <BinProcessStages data={processStages} />
        </Stack>
      </Container>
      <NewBinDialog
        open={isNewBinDialogOpen}
        onClose={() => setIsNewBinDialogOpen(false)}
        onNewBinCreated={handleNewBinCreated}
      />
    </>
  )
}
