import Stack from '@mui/material/Stack'

import { InspectionSteps } from '@/returnPackage/models/ReturnPackage'

import BackButton from '../BackButton'
import ConfirmButton from '../ConfirmButton'
import { useInspectionQuestions } from '../InspectionQuestionsProvider'

export const ConfirmPackageEmpty = () => {
  const { setCurrentStep } = useInspectionQuestions()

  function handleBackClick() {
    setCurrentStep(InspectionSteps.PACKAGE_EMPTY)
  }
  return (
    <Stack spacing={6}>
      <h1>Inspection Result: Package empty</h1>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <BackButton onBackClick={handleBackClick} />
        <ConfirmButton type="destroy" />
      </Stack>
    </Stack>
  )
}
