import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

interface LoadingProps {
  text?: string
}

export default function Loading({ text = '' }: LoadingProps) {
  return (
    <Stack py={10} justifyContent="center" alignItems="center" spacing={2}>
      {text && (
        <Typography variant="h5" color="sky.dark">
          {text}
        </Typography>
      )}
      <CircularProgress sx={{ color: 'sky.dark' }} />
    </Stack>
  )
}
