import { InspectionSteps } from '../models/ReturnPackage'
import { useReturnPackage } from '../ReturnPackageProvider'
import { InspectionQuestionsProvider, useInspectionQuestions } from './InspectionQuestionsProvider'
import { ConfirmIncorrectItem } from './steps/ConfirmIncorrectItem'
import { ConfirmIncorrectQuantity } from './steps/ConfirmIncorrectQuantity'
import { ConfirmPackageEmpty } from './steps/ConfirmPackageEmpty'
import { ConfirmQuantityAndDamage } from './steps/ConfirmQuantityAndDamage'
import { MatchDescription } from './steps/MatchDescription'
import { MatchQuantity } from './steps/MatchQuantity'
import PackageDamage from './steps/PackageDamage'
import { PackageEmpty } from './steps/PackageEmpty'

function InspectionQuestions() {
  const { pack } = useReturnPackage()
  const { currentStep } = useInspectionQuestions()

  const showPackageEmpty =
    pack.inspection?.status === 'to_inspect' && currentStep === InspectionSteps.PACKAGE_EMPTY

  return (
    <div>
      {showPackageEmpty && <PackageEmpty />}
      {currentStep === InspectionSteps.CONFIRM_PACKAGE_EMPTY && <ConfirmPackageEmpty />}
      {currentStep === InspectionSteps.MATCH_DESCRIPTION && <MatchDescription />}
      {currentStep === InspectionSteps.CONFIRM_INCORRECT_ITEM && <ConfirmIncorrectItem />}
      {currentStep === InspectionSteps.QUANTITY_MATCH && <MatchQuantity />}
      {currentStep === InspectionSteps.CONFIRM_INCORRECT_QUANTITY && <ConfirmIncorrectQuantity />}
      {currentStep === InspectionSteps.PACKAGE_DAMAGE && <PackageDamage />}
      {currentStep === InspectionSteps.CONFIRM_QUANTITY_AND_DAMAGE && <ConfirmQuantityAndDamage />}
    </div>
  )
}

export default function InspectionQuestionsWrapper() {
  return (
    <InspectionQuestionsProvider>
      <InspectionQuestions />
    </InspectionQuestionsProvider>
  )
}
