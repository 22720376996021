import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'
import { InspectionSource } from '@/gateways/api/models/Package'

interface MarkForInspectionMutation {
  barcodes: string[]
  source: InspectionSource
}
export function useMarkForInspectionMutation() {
  const { courierGateway } = useGateways()

  return useMutation({
    mutationFn: ({ barcodes, source }: MarkForInspectionMutation) =>
      courierGateway.markForInspection(barcodes, source),
    onError: (reason) => toastApiError(reason),
  })
}
