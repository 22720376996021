import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import WarningIcon from '@mui/icons-material/WarningAmberRounded'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import COLORS from '@/colors'
import { ScreeningFlag } from '@/core/models/Package'
import { ScreeningFlagCategory } from '@/gateways/api/models/Package'

import Collapse from '../Collapse'
import PhotoInstruction from './PhotoInstruction'

const CATEGORY_TITLE: Readonly<Record<ScreeningFlagCategory, string>> = {
  dangerous: 'dangerous goods',
  prohibited: 'prohibited goods',
  battery: 'battery',
  high_value: 'high value',
}

interface ScreeningFlagDisplay {
  iconColor: string
  boxColor: string
  title: string
  keyword: string
}

function getScreeningFlagDisplay(screeningFlag: ScreeningFlag): ScreeningFlagDisplay {
  if (screeningFlag.category === 'high_value') {
    return {
      iconColor: COLORS.pinkDark,
      boxColor: COLORS.pinkLight,
      title: 'Exception',
      keyword: 'High Value Goods',
    }
  }

  return {
    iconColor: screeningFlag.status === 'confirmed' ? 'red.dark' : COLORS.yellowDarkest,
    boxColor: screeningFlag.status === 'confirmed' ? 'red.light' : 'yellow.main',
    title: `Inspection (${CATEGORY_TITLE[screeningFlag.category]})${
      screeningFlag.status === 'confirmed' ? ' - confirmed' : ''
    }`,
    keyword: screeningFlag.keyword,
  }
}

interface InspectionAlertIconProps {
  category: ScreeningFlagCategory
  color: string
}

function InspectionAlertIcon({ category, color }: InspectionAlertIconProps) {
  const Component = category === 'battery' ? BatteryChargingFullIcon : WarningIcon

  return <Component fontSize="large" sx={{ color }} />
}

interface InspectionAlertProps {
  screeningFlag: ScreeningFlag
}

export default function InspectionAlert({ screeningFlag }: InspectionAlertProps) {
  const flagDisplay = getScreeningFlagDisplay(screeningFlag)

  return (
    <Box p={2} borderRadius={2} bgcolor={flagDisplay.boxColor}>
      <Stack direction="row" spacing={1}>
        <InspectionAlertIcon category={screeningFlag.category} color={flagDisplay.iconColor} />
        <Stack flex={1} spacing={1} divider={<Divider />}>
          <>
            <Typography variant="h3" textTransform="uppercase">
              {flagDisplay.title}
            </Typography>
            <Typography variant="h2">{flagDisplay.keyword}</Typography>
          </>
          {!!screeningFlag.guidelines && (
            <Collapse
              title="Screening Guidelines"
              defaultOpen={screeningFlag.status === 'to_inspect'}
            >
              {screeningFlag.guidelines}
            </Collapse>
          )}
          {!!screeningFlag.operatingProcedures && (
            <Collapse
              title="Operating Procedures"
              defaultOpen={screeningFlag.status === 'to_inspect'}
            >
              {screeningFlag.operatingProcedures}
            </Collapse>
          )}
          {screeningFlag.category === 'high_value' && (
            <Collapse title="Photo Guidelines" defaultOpen>
              <PhotoInstruction sx={{ my: 1 }} />
            </Collapse>
          )}
          {screeningFlag.comments && (
            <Collapse title="Comments" defaultOpen={screeningFlag.status === 'confirmed'}>
              {screeningFlag.comments}
            </Collapse>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
