import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import ScanIcon from '@/components/icons/ScanIcon'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'
import { useScan } from '@/hooks/useScan'

import LabelProcessingResult from './LabelProcessingResult'
import Package from './models/Package'
import PackageNotFoundResult from './PackageNotFoundResult'
import useScanQuery from './useScanQuery'

interface PackageInfoProps {
  pack: Package
}

function PackageInfo({ pack }: PackageInfoProps) {
  return (
    <Stack alignItems="center" justifyContent="center">
      <Typography variant="h1" fontSize={30}>
        {pack.warehouseState.displayName}
      </Typography>
      <Typography variant="h2" fontSize={30}>
        {pack.firstMileTrackingNumber}
      </Typography>
    </Stack>
  )
}

export default function LabelProcessingPage() {
  const navigate = useNavigate()
  const barcode = useScan()
  const scanQuery = useScanQuery(barcode)
  const hasResult = !!scanQuery.data

  return (
    <Stack height="100%">
      <AppBar title="Label Processing">
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Stack flex={1} alignItems="center" justifyContent="space-around">
        {scanQuery.isFetching ? (
          <Loading />
        ) : (
          scanQuery.isSuccess && (
            <>
              {scanQuery.data.instruction.slug === 'package_not_found' ? (
                <PackageNotFoundResult barcode={barcode} />
              ) : (
                <>
                  <PackageInfo pack={scanQuery.data} />
                  <LabelProcessingResult
                    label={scanQuery.data.instruction.description}
                    scanResult={scanQuery.data.instruction.slug}
                    packageId={scanQuery.data.id}
                  />
                </>
              )}
            </>
          )
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <ScanIcon sx={{ fontSize: hasResult ? 24 : 40 }} />
          <Typography fontSize={hasResult ? 20 : 36}>
            Scan to process {hasResult && 'the next '}package
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
