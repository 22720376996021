import axios, { AxiosResponse } from 'axios'

import ReturnPackage, { SubmitInspectionOutcomeData } from '@/returnPackage/models/ReturnPackage'
import ReturnPackagesGateway from '@/returnPackage/ports/returnPackages'

import { mapToApi } from './mappers/confirmLiquidatedMapper'
import { mapFromApi } from './mappers/returnPackageMapper'
import {
  ConfirmLiquidatePayload,
  ConfirmZendeskPayload,
  ReturnPackageResponse,
} from './models/ReturnPackage'

function getReturnPackageById(packageId: string): Promise<ReturnPackage> {
  return axios
    .get<ReturnPackageResponse>(`/v1/return_packages/${packageId}`)
    .then(({ data }) => mapFromApi(data.return_package))
}

function markAsReceived(packageId: string): Promise<ReturnPackage> {
  return axios
    .post<ReturnPackageResponse>(`/v1/return_packages/${packageId}/mark_as_received`)
    .then(({ data }) => mapFromApi(data.return_package))
}

function markForDestruction(packageId: string): Promise<ReturnPackage> {
  return axios
    .post<ReturnPackageResponse>(`/v1/return_packages/${packageId}/mark_for_destroy`)
    .then(({ data }) => mapFromApi(data.return_package))
}

function markForLiquidation(packageId: string): Promise<ReturnPackage> {
  return axios
    .post<ReturnPackageResponse>(`/v1/return_packages/${packageId}/mark_for_liquidation`)
    .then(({ data }) => mapFromApi(data.return_package))
}

function confirmZendesk(packageId: string, zendeskId: string): Promise<ReturnPackage> {
  return axios
    .post<ReturnPackageResponse, AxiosResponse<ReturnPackageResponse>, ConfirmZendeskPayload>(
      `/v1/return_packages/${packageId}/return_inspection`,
      {
        zendesk_id: zendeskId,
      }
    )
    .then(({ data }) => mapFromApi(data.return_package))
}

function submitInspectionOutcome(
  packageId: string,
  data: SubmitInspectionOutcomeData
): Promise<ReturnPackage> {
  return axios
    .put<ReturnPackageResponse, AxiosResponse<ReturnPackageResponse>, ConfirmLiquidatePayload>(
      `/v1/return_packages/${packageId}/return_inspection`,
      mapToApi(data)
    )
    .then(({ data }) => mapFromApi(data.return_package))
}

function printBarcode(packageId: string): Promise<void> {
  return axios.post(`/v1/return_packages/${packageId}/print_barcode`)
}

export default function createApiReturnPackagesGateway(): ReturnPackagesGateway {
  return {
    getReturnPackageById,
    markAsReceived,
    markForDestruction,
    markForLiquidation,
    confirmZendesk,
    submitInspectionOutcome,
    printBarcode,
  }
}
