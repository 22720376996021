import Button from '@/components/Button'
import Package from '@/core/models/Package'

import { OrderPackage } from '../models/Order'
import useRegularPackageQuery from '../useRegularPackageQuery'

interface ActionButtonProp {
  pack: OrderPackage
  routeToPackagePage(data: Package): void
}

export default function ActionButton({ pack, routeToPackagePage }: ActionButtonProp) {
  const { refetch, isFetching } = useRegularPackageQuery(pack.id)

  async function handleClick() {
    const { data } = await refetch()
    data && routeToPackagePage(data)
  }
  return (
    <Button
      loading={isFetching}
      onClick={handleClick}
      variant="contained"
      size="large"
      color={pack.warehouseState.value === 'not_received' ? 'primary' : 'info'}
      sx={{
        gridArea: 'action',
        alignSelf: 'center',
        minHeight: 80,
      }}
    >
      {pack.warehouseState.value === 'not_received' ? 'Mark As Received' : 'Select'}
    </Button>
  )
}
