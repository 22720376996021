import Package from '@/core/models/Package'
import { packageStub } from '@/gateways/inmem/stubs/Package'
import { returnPackageStub } from '@/returnPackage/gateways/inmem/stubs/ReturnPackage'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'
import SearchResultGateway from '@/searchResult/ports/searchResult'

const packages = [packageStub()]

function getReturnPackageById(packageId: string): Promise<ReturnPackage> {
  const pack = returnPackageStub()
  pack.warehouseState.value = 'received'
  pack.inspection = null
  return new Promise((resolve) => setTimeout(() => resolve(pack), 1000))
}

function markAsReceived(packageId: string): Promise<ReturnPackage> {
  const pack = returnPackageStub()
  pack.warehouseState.value = 'received'
  pack.warehouseState.displayName = 'Received'
  pack.inspection = null
  return new Promise((resolve) => setTimeout(() => resolve(pack), 1000))
}

function getRegularPackageById(packageId: string): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function createPackage(orderId: string): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve(packageStub()), 1000))
}

export default function createInMemoryReturnPackageResultGateway(): SearchResultGateway {
  return {
    getReturnPackageById,
    markAsReceived,
    getRegularPackageById,
    createPackage,
  }
}
