import axios, { AxiosError, AxiosRequestHeaders } from 'axios'

import Session from '@/core/models/Session'

const HTTP_STATUS_UNAUTHORIZED = 401

axios.interceptors.request.use(
  (config) => {
    const data = sessionStorage.getItem('auth')
    if (data) {
      const session: Session = JSON.parse(data)
      config.headers = {
        Authorization: `Bearer ${session.token}`,
        ...config.headers,
      } as AxiosRequestHeaders
    }
    return config
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const isUnauthorized = error.response?.status === HTTP_STATUS_UNAUTHORIZED

    if (isUnauthorized) {
      sessionStorage.removeItem('auth')
      window.location.replace('/login')
    }

    return Promise.reject(error.response?.data)
  },
)
