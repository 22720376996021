import Chance from 'chance'

import Bin from '@/core/models/Bin'

const chance = new Chance()

export const binStub = (): Bin => ({
  id: chance.integer(),
  name: chance.string(),
  displayName: chance.string(),
  processStage: chance.string(),
  floorLocation: {
    id: chance.integer(),
    name: chance.string(),
  },
  sizeName: chance.string(),
  typeName: chance.string(),
  barcode: chance.string(),
  oldestPackageAddedDateTime: chance.string(),
  droppedAt: chance.date().toISOString(),
})
