import MenuItem from '@mui/material/MenuItem'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'

import COLORS from '@/colors'

import FormLabel from './FormLabel'

interface SelectProps<T> extends MuiSelectProps<T> {
  label?: string
  noPlaceholder?: boolean
}

export default function Select<T>({
  label,
  disabled,
  children,
  placeholder,
  noPlaceholder = false,
  ...props
}: SelectProps<T>) {
  return (
    <FormLabel label={label} disabled={disabled}>
      <MuiSelect
        {...props}
        displayEmpty
        SelectDisplayProps={{
          style: { color: props.value ? 'initial' : COLORS.inkLight },
        }}
      >
        {noPlaceholder === false && (
          <MenuItem key="default" value="" disabled>
            {placeholder ?? 'Choose one'}
          </MenuItem>
        )}
        {children}
      </MuiSelect>
    </FormLabel>
  )
}
