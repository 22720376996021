import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface CourierReturnMutationParams {
  packageId: string
  courierReturnId: string
}

export function useCourierReturnInspectionMutation() {
  const { courierReturnGateway } = useGateways()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: ({ packageId, courierReturnId }: CourierReturnMutationParams) =>
      courierReturnGateway.requestCourierReturnInspection(packageId, courierReturnId),
    onError: (reason) => toastApiError(reason),
    onSuccess: () => {
      toast.success('Inspection requested')
      navigate('/scan')
    },
  })
}
