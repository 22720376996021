import Chance from 'chance'

import BinDetails from '@/core/models/BinDetails'

const chance = new Chance()

export const binDetailsStub = (): BinDetails => ({
  id: chance.integer(),
  name: chance.string(),
  processStage: chance.string(),
  typeName: chance.string(),
  droppedAt: chance.date().toISOString(),
  displayName: chance.string(),
  floorLocation: { id: chance.integer(), name: chance.string() },
  packagesWeight: { value: chance.natural(), unit: 'lb' },
  packagesCount: chance.natural(),
  oldestPackageAddedDateTime: chance.string(),
  packages: [],
})
