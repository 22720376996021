import Package from '@/core/models/Package'
import { packageStub } from '@/gateways/fake/stubs/Package'
import { returnPackageStub } from '@/returnPackage/gateways/fake/stubs/ReturnPackage'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'
import SearchResultGateway from '@/searchResult/ports/searchResult'

const packages = [packageStub()]

function getReturnPackageById(packageId: string): Promise<ReturnPackage> {
  return Promise.resolve(returnPackageStub())
}

function markAsReceived(packageId: string): Promise<ReturnPackage> {
  return Promise.resolve(returnPackageStub())
}

function getRegularPackageById(packageId: string): Promise<Package> {
  return Promise.resolve(packages[0])
}

function createPackage(orderId: string): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve(packageStub()), 1000))
}

export default function createFakeSearchResultGateway(): SearchResultGateway {
  return {
    getReturnPackageById,
    markAsReceived,
    getRegularPackageById,
    createPackage,
  }
}
