import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { PreInspectionActionOutcome } from './models/PreInspection'

interface ActionButton {
  label: string
  color: 'secondary' | 'primary'
  disabled?: boolean
  callback: () => void
}
interface PreInspectionActionsProps {
  onActionClick: (outcome: string) => void
}

export default function PreInspectionActions({ onActionClick }: PreInspectionActionsProps) {
  const actionButtons: ActionButton[] = [
    {
      label: 'dismiss',
      color: 'primary',
      callback: () => onActionClick(PreInspectionActionOutcome.DISMISSED),
    },
    {
      label: 'to inspect',
      color: 'secondary',
      callback: () => onActionClick(PreInspectionActionOutcome.TO_INSPECT),
    },
  ]

  return (
    <Box display="flex" flexWrap="wrap" rowGap={2}>
      {actionButtons.map((actionButton, index) => (
        <Box key={actionButton.label} maxWidth="50%" flex="50%" pr={index % 2 === 0 ? 3 : 0}>
          <Button
            onClick={actionButton.callback}
            variant="contained"
            color={actionButton.color}
            disabled={actionButton.disabled}
            sx={{ height: 80 }}
            fullWidth
          >
            {actionButton.label}
          </Button>
        </Box>
      ))}
    </Box>
  )
}
