import {
  CourierPackageAction,
  CourierReturnReason,
  CourierReturnType,
} from '../gateways/api/models/CourierReturn'

export interface CourierReturn {
  id: string
  packageId: string
  returnType: CourierReturnType
  returnReason: CourierReturnReason
  packageAction: CourierPackageAction | null
  createdAt: string
  updatedAt: string
  processed_at: string
  contentDamaged: boolean
  packagingDamaged: boolean
  label: CourierReturnReshopLabel | null
  labelUsable: boolean | null
}

interface CourierReturnReshopLabel {
  id: string
  active: boolean
  alternateTrackingNumber: string
  courierAdminName: string
  courierId: string
  courierName: string
  courierUmbrellaName: string
  createdAt: string
  packageId: string
  state: string
  trackingNumber: string
  updatedAt: string
}

export enum CourierReturnLabelReason {
  denied_party = 'Denied Party',
  duplicate_package = 'Duplicate Package',
  import_not_authorized = 'Import Not Authorized',
  insufficient_address = 'Insufficient Address',
  refused = 'Refused',
  refused_by_customs = 'Refused by Customs',
  unclaimed = 'Unclaimed',
  battery_restriction = 'Battery Restriction',
  damaged = 'Damaged',
  item_not_received = 'Item Not Received',
  missorted = 'Missort',
  dg_pg_restriction = 'Dangerous Goods/Prohibited Goods Restriction',
  restricted_country = 'Restricted Country',
  unknown = 'Unknown',
}
