import FormLabel from '@mui/material/FormLabel'
import Stack from '@mui/material/Stack'
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

interface SwitchProps extends MuiSwitchProps {
  leftLabel: string
  rightLabel: string
}

export default function Switch({ leftLabel, rightLabel, ...props }: SwitchProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <FormLabel sx={{ cursor: 'pointer', userSelect: 'none' }}>
        <Typography component="span" fontWeight={!props.checked ? 'bold' : 'initial'}>
          {leftLabel}
        </Typography>
        <MuiSwitch {...props} />
        <Typography component="span" fontWeight={props.checked ? 'bold' : 'initial'}>
          {rightLabel}
        </Typography>
      </FormLabel>
    </Stack>
  )
}
