import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useGetPackageMeasurementsQuery() {
  const { inductionsGateway } = useGateways()

  const query = useQuery({
    queryKey: ['inductionPackageMeasurements'],
    queryFn: ({ signal }) => inductionsGateway.getPackageMeasurements(signal),
    meta: { errorToastOptions: { autoClose: 5000 } },
    cacheTime: 0,
    staleTime: 0,
    enabled: false,
    retry: 3,
    retryDelay: (attemptIndex) => Math.max(300 - 100 * attemptIndex, 0),
  })

  return query
}
