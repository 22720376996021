import PhotoGuideLine from '@/components/PhotoGuideLine'
import Stack from '@/components/Stack'
import ZendeskForm from '@/components/ZendeskForm'

import { useReturnPackage } from '../ReturnPackageProvider'

export default function ReturnInspectionFlag() {
  const { pack, confirmZendesk } = useReturnPackage()

  const showDestroyLiquidateButtons = pack.warehouseState.value === 'received'

  if (!showDestroyLiquidateButtons) {
    return null
  }

  return (
    <Stack spacing={2}>
      <PhotoGuideLine Category="Returned goods" />
      <ZendeskForm onZendeskCompleted={confirmZendesk} />
    </Stack>
  )
}
