import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useLocation, useNavigate } from 'react-router-dom'

import AppBar from '../../components/AppBar'
import Spacer from '../../components/Spacer'
import Package from '../../core/models/Package'
import { SearchFormData } from '../ScanPage/ScanPage'
import PackageInfoData from './PackageInfoData'

export type NavigationStateParams =
  | Partial<{
      packages: Package[]
      searchParams: SearchFormData
    }>
  | undefined

export default function PackagesSelectionPage() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const navigationState = state as NavigationStateParams
  const packages = navigationState?.packages ?? []

  return (
    <>
      <AppBar>
        <Button variant="text" onClick={() => navigate('/scan')}>
          back to scan
        </Button>
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="md" sx={{ my: 2 }}>
        <Stack spacing={4}>
          <Box display="flex" justifyContent="center">
            <Typography component="span">
              <strong>{packages.length}</strong> packages were found
            </Typography>
          </Box>
          <Stack divider={<Divider light />} spacing={3}>
            {packages.map((pack, index) => (
              <div className="bg-white p-3" key={pack.ebayPackageId}>
                <PackageInfoData pack={pack} idx={index + 1} />
              </div>
            ))}
          </Stack>
        </Stack>
      </Container>
    </>
  )
}
