import Chance from 'chance'

import { CourierReadyForXRay } from '@/XRay/models/Courier'

const chance = new Chance()

export const courierReadyForXRayStub = (): CourierReadyForXRay => ({
  displayName: chance.string(),
  slug: chance.string(),
  packagesCount: chance.integer({ min: 1, max: 999 }),
})
