import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'

export default function CheckboxRounded(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<CheckCircleIcon />}
      {...props}
    />
  )
}
