import KeyboardHideOutlinedIcon from '@mui/icons-material/KeyboardHideOutlined'
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useReducer, useRef, useState } from 'react'

import Input from '@/components/Input'
import useDebounce from '@/hooks/useDebounce'

export interface WeightFormData {
  weight: number
}

export interface WeightFormProps {
  onSubmit: (formData: WeightFormData) => void
}

export default function WeightForm({ onSubmit }: WeightFormProps) {
  const [weightValue, setWeightValue] = useState('')
  const weightValueDebounced = useDebounce(weightValue, 300)
  const [isManualInput, toggleManualInput] = useReducer((state) => !state, false)
  const measureInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (weightValueDebounced && !isManualInput) {
      onSubmit({ weight: parseFloat(weightValueDebounced) })
    }
  }, [weightValueDebounced, isManualInput])

  function handleKeyUp(e: React.KeyboardEvent<HTMLDivElement>): void {
    if (e.key === 'Enter') {
      onSubmit({ weight: parseFloat(weightValue) })
    }
  }

  return (
    <Stack direction="row" alignItems="flex-end" spacing={1}>
      <Input
        data-hj-allow
        label="Weight (lb)"
        placeholder="0.00 LB"
        type="number"
        autoFocus
        inputRef={measureInputRef}
        onKeyUp={handleKeyUp}
        onChange={(e) => setWeightValue(e.currentTarget.value)}
      />

      <Tooltip title="Toggle Manual Input" arrow>
        <ToggleButton
          selected={isManualInput}
          color="primary"
          onClick={() => {
            measureInputRef.current?.focus()
            toggleManualInput()
          }}
          value="manual input"
          sx={{ minWidth: 42, height: 42, px: 1 }}
        >
          {isManualInput ? <KeyboardHideOutlinedIcon /> : <KeyboardOutlinedIcon />}
        </ToggleButton>
      </Tooltip>
    </Stack>
  )
}
