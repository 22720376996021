import Chance from 'chance'

import Session from '../../../core/models/Session'

const chance = new Chance()

export const sessionStub = (): Session => ({
  token: chance.string(),
  expiresAt: chance.date().toISOString(),
  stationId: 49,
  user: {
    hub: {
      id: chance.integer({ min: 1, max: 99 }),
      name: chance.string(),
    },
    firstName: chance.first(),
    lastName: chance.last(),
    state: 'active',
  },
})
