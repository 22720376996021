import Button from '@/components/Button'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'

import useMarkAsReceivedMutation from '../useMarkAsReceivedMutation'

interface MarkAsReceivedButtonProps {
  packageId: string
  onRouteToReturnPackagePage(pack: ReturnPackage): void
}

export default function MarkAsReceivedButton({
  packageId,
  onRouteToReturnPackagePage,
}: MarkAsReceivedButtonProps) {
  const markAsReceivedMutation = useMarkAsReceivedMutation()

  function handleClick() {
    markAsReceivedMutation.mutate(packageId, {
      onSuccess: onRouteToReturnPackagePage,
    })
  }

  return (
    <Button
      loading={markAsReceivedMutation.isLoading}
      onClick={handleClick}
      variant="contained"
      size="large"
      color="primary"
      sx={{
        gridArea: 'action',
        alignSelf: 'center',
        minHeight: 80,
      }}
    >
      Mark As Received
    </Button>
  )
}
