import Chance from 'chance'

import CourierBin from '@/manifestV2/models/CourierBin'

const chance = new Chance()

export const courierBinStub = (): CourierBin => ({
  id: chance.natural(),
  name: chance.string(),
  barcode: chance.string(),
  floorLocation: { id: chance.natural(), name: chance.string({ alpha: true }) },
  packagesCount: chance.integer({ min: 1, max: 150 }),
  packagesWeight: { value: chance.integer({ min: 1, max: 999 }), unit: 'lb' },
})
