import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

import ManifestV2Gateway from './ports/manifestV2'

function getCourierBins(manifestV2Gateway: ManifestV2Gateway, courierSlug: string | undefined) {
  return typeof courierSlug === 'undefined'
    ? Promise.reject(new Error('Invalid courierSlug'))
    : manifestV2Gateway.getCourierBins(courierSlug)
}

export default function useCourierBinsQuery(courierSlug: string | undefined) {
  const { manifestV2Gateway } = useGateways()

  return useQuery({
    queryKey: ['courierBins', courierSlug],
    queryFn: () => getCourierBins(manifestV2Gateway, courierSlug),
    enabled: !!courierSlug,
  })
}
