import ReturnPackage from '@/returnPackage/models/ReturnPackage'

import { ReturnPackageDataResponse } from '../models/ReturnPackage'

export function mapFromApi(pack: ReturnPackageDataResponse): ReturnPackage {
  return {
    id: pack.id,
    warehouseState: { value: pack.warehouse_state, displayName: pack.warehouse_state_display_name },
    returnEvtn: pack.return_evtn ?? '',
    trackingNumber: pack.tracking_number,
    easyshipShipmentId: pack.easyship_shipment_id,
    items: pack.items.map((item) => ({
      ebayItemId: item.ebay_item_id,
      description: item.description,
      url: item.listing_url,
      quantity: item.quantity,
      sku: item.sku ?? '',
    })),
    outboundEvtn: pack.outbound_evtn,
    outboundEbayPackageIds: pack.outbound_ebay_package_ids,
    inspection: pack.inspection
      ? {
          id: pack.inspection.id ?? null,
          status: pack.inspection.status ?? null,
          zendeskId: pack.inspection.zendesk_id ?? null,
          packageEmpty: pack.inspection?.package_empty ?? null,
          doesItemDescriptionMatch: pack.inspection.does_item_description_match ?? null,
          doesItemQuantityMatch: pack.inspection.does_item_quantity_match ?? null,
          packageDamaged: pack.inspection.package_damaged ?? null,
          returnedItemQuantity: pack.inspection.returned_item_quantity ?? null,
        }
      : null,
  }
}
