import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import FormLabel from '../../../components/FormLabel'

interface YesNoSelectProps {
  label?: string
  value: boolean | undefined
  disabled?: boolean
  onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean | undefined) => void
}

export default function YesNoSelect({
  label = '',
  value,
  disabled = false,
  onChange,
}: YesNoSelectProps) {
  function handleChange(event: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean | null) {
    onChange(event, value ?? undefined)
  }

  return (
    <FormLabel label={label} noControl disabled={disabled}>
      <ToggleButtonGroup value={value} exclusive onChange={handleChange} disabled={disabled}>
        <ToggleButton aria-label={`${label}-no`} value={false} sx={{ minWidth: 100 }}>
          no
        </ToggleButton>
        <ToggleButton aria-label={`${label}-yes`} value={true} sx={{ minWidth: 100 }}>
          yes
        </ToggleButton>
      </ToggleButtonGroup>
    </FormLabel>
  )
}
