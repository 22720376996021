import Stack from '@mui/material/Stack'

import Input from '@/components/Input'
import { InspectionSteps } from '@/returnPackage/models/ReturnPackage'

import BackButton from '../BackButton'
import ConfirmButton from '../ConfirmButton'
import { useInspectionQuestions } from '../InspectionQuestionsProvider'

export const ConfirmQuantityAndDamage = () => {
  const { inspectionAnswers, setInspectionAnswers, setCurrentStep } = useInspectionQuestions()
  const { packageDamaged, doesItemQuantityMatch } = inspectionAnswers

  function handleBackClick() {
    setInspectionAnswers((prevState) => ({
      ...prevState,
      comments: {
        packageDamaged: '',
      },
    }))
    setCurrentStep(InspectionSteps.PACKAGE_DAMAGE)
  }

  return (
    <Stack alignItems="center" spacing={2}>
      <h1>
        Inspection Result: {!packageDamaged && 'No'} Damage+Qty{' '}
        {doesItemQuantityMatch ? 'Correct' : 'Incorrect'}
      </h1>
      {packageDamaged && (
        <Input
          label="Comment (optional)"
          type="string"
          autoFocus
          onChange={(e) => {
            setInspectionAnswers((prevState) => ({
              ...prevState,
              comments: {
                packageDamaged: e.target.value,
              },
            }))
          }}
        />
      )}

      <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
        <BackButton onBackClick={handleBackClick} />
        <ConfirmButton type="liquidate" />
      </Stack>
    </Stack>
  )
}
