import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { InspectionSteps } from '@/returnPackage/models/ReturnPackage'

import IconCheck from '../../../../assets/Icon-check.svg'
import IconCross from '../../../../assets/Icon-cross.svg'
import { useInspectionQuestions } from '../InspectionQuestionsProvider'

export const PackageEmpty = () => {
  const { setCurrentStep, setInspectionAnswers } = useInspectionQuestions()

  return (
    <Stack alignItems="center" spacing={2}>
      <h1>Package empty?</h1>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="secondary"
          aria-label="empty package"
          sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
          onClick={() => {
            setInspectionAnswers((prevState) => ({ ...prevState, packageEmpty: true }))
            setCurrentStep(InspectionSteps.CONFIRM_PACKAGE_EMPTY)
          }}
        >
          <img
            src={IconCross}
            height={36}
            style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
          />

          <Typography variant="h1">Empty</Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          aria-label="not empty"
          sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
          onClick={async () => {
            setInspectionAnswers((prevState) => ({ ...prevState, packageEmpty: false }))
            setCurrentStep(InspectionSteps.MATCH_DESCRIPTION)
          }}
        >
          <img
            src={IconCheck}
            height={19}
            style={{ maxWidth: '100%', objectFit: 'contain', marginRight: 10 }}
          />

          <Typography variant="h1">Not Empty</Typography>
        </Button>
      </Stack>
    </Stack>
  )
}
