import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ButtonBase from '@mui/material/ButtonBase'
import MuiCollapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

interface CollapseProps {
  title: string
  defaultOpen?: boolean
  children: React.ReactNode
}

export default function Collapse({ title, defaultOpen = false, children }: CollapseProps) {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Stack>
      <Stack>
        <ButtonBase onClick={() => setOpen(!open)} sx={{ justifyContent: 'space-between' }}>
          <Typography textTransform="uppercase" component="span">
            {title}
          </Typography>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ButtonBase>
      </Stack>
      <MuiCollapse in={open}>{children}</MuiCollapse>
    </Stack>
  )
}
