import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'

import { Stat, StatLabel, StatValue } from '@/components/Stat'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'

import { OrderPackage } from '../models/Order'
import { NavigationStateParams } from '../SearchResultPage'
import MarkAsReceivedButton from './MarkAsReceivedButton'
import SelectReturnPackageButton from './SelectReturnPackageButton'

interface PackageDataItem {
  label: string
  value: React.ReactNode
  isHighlight: boolean
}

interface ReturnPackageDataProps {
  pack: OrderPackage
  idx: number
}

export default function ReturnPackageData({ pack, idx }: ReturnPackageDataProps) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams
  const isWarehouseStateNotReceived = pack.warehouseState.value === 'not_received'
  const packageData: PackageDataItem[] = [
    {
      label: 'Easyship Shipment ID',
      value: pack.easyshipShipmentId,
      isHighlight: false,
    },
    {
      label: 'Warehouse State',
      value: pack.warehouseState.displayName,
      isHighlight: true,
    },
    {
      label: 'Scan Received',
      value: dayjs(pack.scanReceived).format('DD MMM YYYY, h:mm A'),
      isHighlight: false,
    },
    {
      label: 'First Mile Tracking No.',
      value: pack.firstMileTrackingNumber,
      isHighlight: false,
    },
    {
      label: 'Last Mile Tracking No.',
      value: pack.lastMileTrackingNumber,
      isHighlight: false,
    },
    {
      label: 'Last Station ID',
      value: pack.lastStationId,
      isHighlight: false,
    },
  ]

  function routeToReturnPackagePage(returnPackage: ReturnPackage) {
    navigate('/return-package', {
      state: { package: returnPackage, searchParams: navigationState?.searchParams },
    })
  }

  return (
    <Paper
      elevation={0}
      sx={{
        border: 1,
        borderColor: 'sky.dark',
        bgcolor: 'sky.light',
        py: 2.5,
        px: 1.25,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '44px repeat(3, 1fr) 120px',
          gridGap: 20,
          gridTemplateAreas: `
            "package . . . action"
            "package . . . action"
          `,
        }}
      >
        <Stack sx={{ gridArea: 'package' }}>
          <Typography variant="subtitle2" fontWeight="bold">
            Package
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            #{idx}
          </Typography>
        </Stack>

        {packageData.map((pack) => (
          <Stat key={pack.label}>
            <StatLabel>{pack.label}</StatLabel>
            <StatValue variant="h3" {...(pack.isHighlight && { color: 'blue.dark' })}>
              {pack.value || '-'}
            </StatValue>
          </Stat>
        ))}
        {isWarehouseStateNotReceived ? (
          <MarkAsReceivedButton
            packageId={pack.id}
            onRouteToReturnPackagePage={routeToReturnPackagePage}
          />
        ) : (
          <SelectReturnPackageButton
            packageId={pack.id}
            onRouteToReturnPackagePage={routeToReturnPackagePage}
          />
        )}
      </Box>
    </Paper>
  )
}
