import Button from '@/components/Button'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'

import useReturnPackageDetailQuery from '../useReturnPackageDetailQuery'

interface SelectReturnPackageButtonProps {
  packageId: string
  onRouteToReturnPackagePage(pack: ReturnPackage): void
}

export default function SelectReturnPackageButton({
  packageId,
  onRouteToReturnPackagePage,
}: SelectReturnPackageButtonProps) {
  const { refetch, isFetching: isFetchingPackageDetail } = useReturnPackageDetailQuery(packageId)

  async function handleClick() {
    const { data } = await refetch()
    data && onRouteToReturnPackagePage(data)
  }

  return (
    <Button
      loading={isFetchingPackageDetail}
      onClick={handleClick}
      variant="contained"
      size="large"
      color="info"
      sx={{
        gridArea: 'action',
        alignSelf: 'center',
        minHeight: 80,
      }}
    >
      Select
    </Button>
  )
}
