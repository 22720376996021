import Package from '../../core/models/Package'
import InspectionsGateway from '../../core/ports/inspections'
import { BatteryInspectionFormData } from '../../pages/PackagePage/PackageInspection/BatteryInspection'
import { InspectionCategoryDefined } from '../api/models/Package'
import { packageStub } from './stubs/Package'

const pack = packageStub()

function dismiss(id: string, comments: string): Promise<Package> {
  pack.warehouseState.value = 'xray_completed'
  pack.inspections[0].status = 'dismissed'
  return new Promise((resolve) => setTimeout(() => resolve(pack), 1000))
}

function categorize(id: string, category: InspectionCategoryDefined): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve(pack), 1000))
}

function confirm(id: string, comments: string): Promise<Package> {
  pack.warehouseState.value = 'dg_confirmed'
  pack.inspections[0].status = 'confirmed'
  return new Promise((resolve) => setTimeout(() => resolve(pack), 1000))
}

function classifyBattery(packageId: string, formData: BatteryInspectionFormData): Promise<Package> {
  pack.warehouseState.value = 'battery_confirmed_non_compliant'
  pack.batteryInspectionOutcome = {
    result: 'unshippable',
    classifications: [],
    shippingDocumentInstructions: [],
    stickers: [],
    unshippableReasons: [],
  }
  pack.inspections[0].status = 'confirmed'
  return new Promise((resolve) => setTimeout(() => resolve(pack), 1000))
}

export default function createInMemoryInspectionsGateway(): InspectionsGateway {
  return {
    dismiss,
    categorize,
    confirm,
    classifyBattery,
  }
}
