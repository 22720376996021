import Stack from '@mui/material/Stack'

import FormLabel from '@/components/FormLabel'
import Input from '@/components/Input'

export interface WeightDimensionsEditFormData {
  weight: number | ''
  length: number | ''
  width: number | ''
  height: number | ''
}

export interface WeightDimensionsFormData {
  weight: number
  length: number
  width: number
  height: number
}

export const defaultMeasureFormData: WeightDimensionsEditFormData = {
  weight: '',
  length: '',
  width: '',
  height: '',
}

export interface WeightDimensionsFormProps {
  value: WeightDimensionsEditFormData
  loading?: boolean
  onChange: (value: WeightDimensionsEditFormData) => void
}

export default function WeightDimensionsForm({
  value,
  loading = false,
  onChange,
}: WeightDimensionsFormProps) {
  function updateFormData(updates: Partial<WeightDimensionsEditFormData>) {
    onChange({ ...value, ...updates })
  }

  return (
    <form>
      <Stack spacing={2}>
        <Input
          label="Weight (lb)"
          placeholder="0.00 LB"
          type="number"
          value={value.weight}
          onChange={(e) =>
            updateFormData({ weight: e.currentTarget.value ? +e.currentTarget.value : '' })
          }
          loading={loading}
          disabled
        />
        <FormLabel label="Package Size (in)" noControl disabled>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Input
              type="number"
              placeholder="L"
              value={value.length}
              onChange={(e) =>
                updateFormData({ length: e.currentTarget.value ? +e.currentTarget.value : '' })
              }
              loading={loading}
              disabled
            />
            <span>x</span>
            <Input
              type="number"
              placeholder="W"
              value={value.width}
              onChange={(e) =>
                updateFormData({ width: e.currentTarget.value ? +e.currentTarget.value : '' })
              }
              loading={loading}
              disabled
            />
            <span>x</span>
            <Input
              type="number"
              placeholder="H"
              value={value.height}
              onChange={(e) =>
                updateFormData({ height: e.currentTarget.value ? +e.currentTarget.value : '' })
              }
              loading={loading}
              disabled
            />
          </Stack>
        </FormLabel>
      </Stack>
    </form>
  )
}
