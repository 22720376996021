import { PackageItem, PackageWarehouseState } from '@/core/models/Package'

import { InspectionStatus } from '../gateways/api/models/ReturnPackage'

export default interface ReturnPackage {
  id: string
  warehouseState: PackageWarehouseState
  returnEvtn: string
  trackingNumber: string
  items: PackageItem[]
  outboundEvtn: string
  outboundEbayPackageIds: string[]
  inspection: null | Inspection
  easyshipShipmentId: string
}

export interface ReturnInspectionInspectionOutcome extends ReturnPackage {
  inspection: Inspection
}

export interface Inspection {
  id: string
  status: InspectionStatus
  zendeskId: string
  packageEmpty: boolean | null
  doesItemDescriptionMatch: boolean | null
  doesItemQuantityMatch: boolean | null
  returnedItemQuantity: number | null
  packageDamaged: boolean | null
  comments?: Comments | null
}

export interface InspectionAnswers {
  packageEmpty: boolean | null
  doesItemDescriptionMatch: boolean | null
  doesItemQuantityMatch: boolean | null
  returnedItemQuantity: number | null
  packageDamaged: boolean | null
  comments: Comments | null
}

export interface Comments {
  packageDescription?: string
  packageDamaged?: string
}

export interface SubmitInspectionOutcomeData {
  inspection: InspectionAnswers
  destroyReturnPackage: boolean
}

export type CurrentStep =
  | 'package_empty'
  | 'confirm_package_empty'
  | 'match_description'
  | 'quantity_match'
  | 'package_damage'
  | 'confirm_quantity_and_damage'
  | 'confirm_incorrect_item'
  | 'confirm_incorrect_quantity'

export enum InspectionSteps {
  PACKAGE_EMPTY = 'package_empty',
  CONFIRM_PACKAGE_EMPTY = 'confirm_package_empty',
  MATCH_DESCRIPTION = 'match_description',
  QUANTITY_MATCH = 'quantity_match',
  PACKAGE_DAMAGE = 'package_damage',
  CONFIRM_QUANTITY_AND_DAMAGE = 'confirm_quantity_and_damage',
  CONFIRM_INCORRECT_ITEM = 'confirm_incorrect_item',
  CONFIRM_INCORRECT_QUANTITY = 'confirm_incorrect_quantity',
}
