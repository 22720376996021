import KeyboardHideOutlinedIcon from '@mui/icons-material/KeyboardHideOutlined'
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useReducer, useRef, useState } from 'react'

import Button from '@/components/Button'
import { PackageMeasurements, PackageMeasurementsMetadata } from '@/core/models/Package'
import useDebounce from '@/hooks/useDebounce'

import FormLabel from '../../../components/FormLabel'
import Input from '../../../components/Input'
import useGetPackageMeasurementsQuery from '../useGetPackageMeasurementsQuery'

export interface MeasureEditFormData {
  weight: number | ''
  length: number | ''
  width: number | ''
  height: number | ''
}

export interface MeasureFormData {
  weight: number
  length: number | ''
  width: number | ''
  height: number | ''
}

const defaultMeasureFormData: MeasureEditFormData = {
  weight: '',
  length: '',
  width: '',
  height: '',
}

export interface MeasureFormProps {
  onSubmit: (formData: MeasureFormData, metadata: PackageMeasurementsMetadata | undefined) => void
}

export default function MeasureForm({ onSubmit }: MeasureFormProps) {
  const measurePackageQuery = useGetPackageMeasurementsQuery()
  const [formData, setFormData] = useState<MeasureEditFormData>(defaultMeasureFormData)
  const weightValueDebounced = useDebounce(formData.weight, 300)
  const [isManualInput, toggleManualInput] = useReducer((state) => !state, false)
  const measureInputRef = useRef<HTMLInputElement>(null)
  const packageMeasurementsMetadataRef = useRef<PackageMeasurementsMetadata>()

  useEffect(() => {
    if (weightValueDebounced && !isManualInput) {
      isFormValid(formData) && onSubmit(formData, packageMeasurementsMetadataRef.current)
    }
  }, [weightValueDebounced, isManualInput])

  function isFormValid(formData: MeasureEditFormData): formData is MeasureFormData {
    return !!formData.weight && formData.weight > 0
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    isFormValid(formData) && onSubmit(formData, packageMeasurementsMetadataRef.current)
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.key === 'Enter') {
      isFormValid(formData) && onSubmit(formData, packageMeasurementsMetadataRef.current)
    }
  }

  function updateFormData(updates: Partial<MeasureEditFormData>) {
    setFormData({ ...formData, ...updates })
  }

  function resetFormData(formData?: Partial<PackageMeasurements>) {
    setFormData({
      weight: formData?.weight?.value ?? '',
      length: formData?.dimensions?.length ?? '',
      width: formData?.dimensions?.width ?? '',
      height: formData?.dimensions?.height ?? '',
    })
  }

  function getPackageMeasurements() {
    resetFormData()
    measurePackageQuery.refetch().then(({ data }) => {
      if (!data) return
      resetFormData(data)
      packageMeasurementsMetadataRef.current = {
        measurementId: data.measurementId,
        scannedOn: data.scannedOn,
      }
    })
  }

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Input
            label="Weight (lb)"
            placeholder="0.00 LB"
            type="number"
            autoFocus
            inputRef={measureInputRef}
            value={formData.weight}
            onChange={(e) =>
              updateFormData({ weight: e.currentTarget.value ? +e.currentTarget.value : '' })
            }
            loading={measurePackageQuery.isFetching}
          />
          <Tooltip title="Toggle Manual Input" arrow>
            <ToggleButton
              selected={isManualInput}
              color="primary"
              onClick={() => {
                measureInputRef.current?.focus()
                toggleManualInput()
              }}
              value="manual input"
              sx={{ minWidth: 42, height: 42, px: 1 }}
            >
              {isManualInput ? <KeyboardHideOutlinedIcon /> : <KeyboardOutlinedIcon />}
            </ToggleButton>
          </Tooltip>
        </Stack>
        {isManualInput && (
          <>
            <FormLabel
              label="Package Size (in)"
              noControl
              disabled={measurePackageQuery.isFetching}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Input
                  type="number"
                  placeholder="L"
                  value={formData.length}
                  onChange={(e) =>
                    updateFormData({ length: e.currentTarget.value ? +e.currentTarget.value : '' })
                  }
                  loading={measurePackageQuery.isFetching}
                />
                <span>x</span>
                <Input
                  type="number"
                  placeholder="W"
                  value={formData.width}
                  onChange={(e) =>
                    updateFormData({ width: e.currentTarget.value ? +e.currentTarget.value : '' })
                  }
                  loading={measurePackageQuery.isFetching}
                />
                <span>x</span>
                <Input
                  type="number"
                  placeholder="H"
                  value={formData.height}
                  onChange={(e) =>
                    updateFormData({ height: e.currentTarget.value ? +e.currentTarget.value : '' })
                  }
                  loading={measurePackageQuery.isFetching}
                />
              </Stack>
            </FormLabel>
            <Stack direction="row" justifyContent="space-between">
              <Button onClick={getPackageMeasurements} sx={{ alignSelf: 'flex-start' }}>
                measure package
              </Button>
              <Button
                sx={{ height: 80, minWidth: 200 }}
                type="submit"
                variant="contained"
                disabled={!isFormValid(formData)}
              >
                Confirm
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </form>
  )
}
