import axios from 'axios'

import CourierBin from '@/manifestV2/models/CourierBin'
import ManifestV2Courier from '@/manifestV2/models/ManifestV2Courier'
import ManifestV2Gateway from '@/manifestV2/ports/manifestV2'

import { BinsResponse } from './models/BinsResponse'
import { DropBinsPayload } from './models/DropBinsPayload'
import { ManifestCouriersResponse } from './models/ManifestCourier'

function getCourierBins(courierSlug: string): Promise<CourierBin[]> {
  return axios
    .get<BinsResponse>(`/v1/couriers/${courierSlug}/bins/ready_to_be_dropped`)
    .then(({ data }) =>
      data.bins.map((bin) => ({
        id: bin.id,
        name: bin.name,
        floorLocation: {
          id: bin.floor_area.id,
          name: bin.floor_area.name,
        },
        packagesWeight: {
          value: bin.packages_weight ?? 0,
          unit: bin.packages_weight_unit,
        },
        packagesCount: bin.packages_count,
        barcode: bin.barcode,
      }))
    )
}

function getManifestCouriers(): Promise<ManifestV2Courier[]> {
  return axios
    .get<ManifestCouriersResponse>('v1/couriers/bins/ready_to_be_dropped')
    .then(({ data }) =>
      data.couriers.map((courier) => ({
        displayName: courier.courier_display_name,
        slug: courier.courier_slug,
        binsCount: courier.bins_count,
        requiresManifestId: courier.requires_manifest_id,
      }))
    )
}

function markBinsDropped(
  courierSlug: string,
  manifestReferenceId: string,
  binIds: number[]
): Promise<void> {
  return axios.post<void, void, DropBinsPayload>('/v1/bins/drop', {
    manifest_reference_id: manifestReferenceId,
    courier_slug: courierSlug,
    bin_ids: binIds,
  })
}

export default function createInMemoryManifestV2Gateway(): ManifestV2Gateway {
  return { getCourierBins, getManifestCouriers, markBinsDropped }
}
