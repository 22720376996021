import Chance from 'chance'

import Session from '../../../core/models/Session'

const chance = new Chance()

export const sessionStub = (): Session => {
  const date = new Date()
  const twelveHoursMilli = 12 * 60 * 60 * 1000
  date.setTime(date.getTime() + twelveHoursMilli)

  return {
    token: chance.string(),
    expiresAt: date.toISOString(),
    stationId: chance.integer({ min: 1, max: 99 }),
    user: {
      hub: {
        id: chance.integer({ min: 1, max: 99 }),
        name: 'ORD',
      },
      firstName: chance.first(),
      lastName: chance.last(),
      state: 'active',
    },
  }
}
