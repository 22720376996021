import { createContext, useContext, useState } from 'react'

import { FeatureFlags } from '@/gateways/api/models/FeatureFlags'
import { featureFlagsStub } from '@/gateways/fake/stubs/FeatureFlags'

import { useGateways } from './gateways'

interface FeatureFlagsContextData {
  featureFlags: FeatureFlags | null
  isFeatureEnabled: (feature: string) => boolean
  getFeatureFlags: () => Promise<void>
}

interface FeatureFlagsProviderProps {
  children: React.ReactNode
}

const FeatureFlagsContext = createContext<FeatureFlagsContextData | undefined>(undefined)

function FeatureFlagsProvider({ children }: FeatureFlagsProviderProps) {
  const { featureFlagsGateway } = useGateways()
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(getFeatureFlagsSession())

  function handleSetFeatureFlags(flags: FeatureFlags) {
    sessionStorage.setItem('featureFlags', JSON.stringify(flags))
    setFeatureFlags(flags)
  }

  function getFeatureFlagsSession() {
    const data = sessionStorage.getItem('featureFlags')
    if (data) {
      const flags: FeatureFlags = JSON.parse(data)
      return flags
    }
    return null
  }

  async function getFeatureFlags() {
    await featureFlagsGateway.getFeatureFlags().then(handleSetFeatureFlags)
  }

  function isFeatureEnabled(feature: string) {
    return (featureFlags && featureFlags[feature]) ?? false
  }

  const data: FeatureFlagsContextData = {
    featureFlags,
    isFeatureEnabled,
    getFeatureFlags,
  }
  return <FeatureFlagsContext.Provider value={data}>{children}</FeatureFlagsContext.Provider>
}

export interface FakeFeatureFlagsProviderProps {
  featureFlags?: FeatureFlags
  children: React.ReactNode
}

function FakeFeatureFlagsProvider({ featureFlags, children }: FakeFeatureFlagsProviderProps) {
  const data = {
    getFeatureFlags: () => Promise.resolve(),
    featureFlags: featureFlagsStub(),
    isFeatureEnabled: (feature: string) => (featureFlags && featureFlags[feature]) ?? true,
  }
  return <FeatureFlagsContext.Provider value={data}>{children}</FeatureFlagsContext.Provider>
}
function useFeatureFlags() {
  const featureFlagsContext = useContext(FeatureFlagsContext)

  if (featureFlagsContext === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider')
  }
  return featureFlagsContext
}

export { FeatureFlagsProvider, FakeFeatureFlagsProvider, useFeatureFlags }
