import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ItemsList from '@/components/ItemsList'
import { Stat, StatLabel, StatValue } from '@/components/Stat'

import Order from '../models/Order'

interface ReturnOrderDataProps {
  idx: number
  order: Omit<Order, 'packages'>
}

export default function ReturnOrderData({ idx, order }: ReturnOrderDataProps) {
  const { evtn, senderAddress, items } = order
  const shipFromAddress = [
    senderAddress.addressLine1,
    senderAddress.addressLine2,
    senderAddress.city,
    senderAddress.state,
    senderAddress.postalCode,
    senderAddress.countryCode,
  ]
    .filter((v) => !!v)
    .join(', ')

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '54px 146px 300px 1fr',
        gridGap: 20,
        mb: 2,
      }}
    >
      <Stack spacing={0.5}>
        <Typography variant="subtitle2" fontWeight="bold">
          Order
        </Typography>
        <Typography variant="h2">#{idx}</Typography>
      </Stack>

      <Stat>
        <StatLabel>EVTN</StatLabel>
        <StatValue variant="h1" color="blue.dark">
          {evtn}
        </StatValue>
        <Stack direction="row" spacing={1}>
          <Chip label="return" color="primary" size="small" role="status" />
        </Stack>
      </Stat>

      <Stack spacing={1.25}>
        <Stat>
          <StatLabel>Items</StatLabel>
          <ItemsList items={items} />
        </Stat>
      </Stack>
      <Stack spacing={1.25}>
        <Stat horizontal>
          <StatLabel sx={{ minWidth: 100 }}>Return From - Name</StatLabel>
          <StatValue variant="h5">{senderAddress.fullName}</StatValue>
        </Stat>

        <Stat horizontal>
          <StatLabel sx={{ minWidth: 100 }}>Return From - Address</StatLabel>
          <StatValue variant="h5">{shipFromAddress}</StatValue>
        </Stat>

        <Stat horizontal>
          <StatLabel sx={{ minWidth: 100 }}>Return From - Country</StatLabel>
          <StatValue variant="h5">{senderAddress.country}</StatValue>
        </Stat>
      </Stack>
    </Box>
  )
}
