import axios from 'axios'

import PreInspectionPackage from '@/preInspection/models/PreInspection'
import PreInspectionGateway from '@/preInspection/ports/preInspection'

import { mapFromApi } from './mappers/preInspectionMapper'
import { PreInspectionPackageResponse } from './models/PreInspection'

function getNextPreInspection(): Promise<PreInspectionPackage> {
  return axios
    .get<PreInspectionPackageResponse>(`/v1/packages/next_pre_inspection`)
    .then(({ data }) => data && mapFromApi(data.package))
}

function getPreviousPreInspection(): Promise<PreInspectionPackage> {
  return axios
    .get<PreInspectionPackageResponse>(`/v1/packages/previous_pre_inspection`)
    .then(({ data }) => data && mapFromApi(data.package))
}

function updatePreInspectionPackages(screeningFlagId: number, outcome: string): Promise<void> {
  return axios.put(`/v1/screening_flags/${screeningFlagId}/pre_inspection`, {
    outcome: outcome,
  })
}

export default function createApiPreInspectionGateway(): PreInspectionGateway {
  return {
    getNextPreInspection,
    updatePreInspectionPackages,
    getPreviousPreInspection,
  }
}
