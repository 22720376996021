import Stack, { StackProps } from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import COLORS from '@/colors'
import { courierBackgroundColor } from '@/core/models/Color'

import { CourierSlug } from './gateways/api/models/CourierSlug'

interface CourierStyleCardProp extends StackProps {
  courier: {
    slug: CourierSlug
    name: string
    number: number
  }
}

export function CourierStyleCard({ courier, ...props }: CourierStyleCardProp) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: courierBackgroundColor[courier.slug],
        p: 2,
        borderRadius: 2,
      }}
      {...props}
    >
      <Typography fontSize={200} color={COLORS.inkDark} fontWeight="bold">
        {courier.number}
      </Typography>
      <Typography fontSize={36} color={COLORS.inkDark} fontWeight="bold">
        {courier.name}
      </Typography>
    </Stack>
  )
}
