import { useMutation, useQueryClient } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface PackageDropMutationParams {
  courierSlug: string
  manifestReferenceId: string
  binIds: number[]
}

export default function usePackageDropMutation() {
  const { manifestV2Gateway } = useGateways()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ courierSlug, manifestReferenceId, binIds }: PackageDropMutationParams) =>
      manifestV2Gateway.markBinsDropped(courierSlug, manifestReferenceId, binIds),
    onError: (reason) => toastApiError(reason),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['manifestCouriers'] })
      queryClient.invalidateQueries({ queryKey: ['courierBins'] })
    },
  })
}
