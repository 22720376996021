import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

export default function useGetPackageMeasurementsQuery() {
  const { packagesGateway } = useGateways()

  const query = useQuery({
    queryKey: ['packagesPackageMeasurements'],
    queryFn: packagesGateway.getPackageMeasurements,
    cacheTime: 0,
    staleTime: 0,
    enabled: false,
  })

  return query
}
