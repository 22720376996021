import Box from '@mui/material/Box'

import Button from '@/components/Button'

import { useReturnPackage } from './ReturnPackageProvider'

export default function ReturnReceive() {
  const { pack, markAsReceived } = useReturnPackage()

  if (pack.warehouseState.value !== 'not_received') return null

  return (
    <Box display="flex" flexWrap="wrap" rowGap={2}>
      <Box key="return received" maxWidth="50%" flex="50%" pr={3}>
        <Button
          onClick={() => markAsReceived.mutate()}
          variant="contained"
          color="secondary"
          sx={{ height: 80 }}
          loading={markAsReceived.isLoading}
          disabled={markAsReceived.isLoading}
          fullWidth
        >
          return received
        </Button>
      </Box>
    </Box>
  )
}
