import { useQuery } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

export default function useGetNextPreInspectionQuery() {
  const { preInspectionGateway } = useGateways()

  const query = useQuery({
    queryKey: ['nextPreInspection'],
    queryFn: () => preInspectionGateway.getNextPreInspection(),
    cacheTime: 0,
    staleTime: 0,
    onError: toastApiError,
  })

  return query
}
