import Button from '@mui/material/Button'

import { useReturnPackage } from '../ReturnPackageProvider'
import { useInspectionQuestions } from './InspectionQuestionsProvider'

type ReturnInspectionConfirmButtonType = 'liquidate' | 'destroy'
interface buttonTypeProp {
  type: ReturnInspectionConfirmButtonType
}

export default function ConfirmButton({ type }: buttonTypeProp) {
  const { submitInspectionOutcome } = useReturnPackage()
  const { inspectionAnswers } = useInspectionQuestions()

  return (
    <>
      <Button
        variant="contained"
        color="error"
        sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
        onClick={() => {
          submitInspectionOutcome({ shouldDestroy: type === 'destroy', inspectionAnswers })
        }}
      >
        {type === 'destroy' ? 'confirm destroy' : 'confirm liquidate'}
      </Button>
    </>
  )
}
