import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface PreInspectionPackagesMutationParams {
  screeningFlagId: number
  outcome: string
}

export function useUpdatePreInspectionPackagesMutation() {
  const { preInspectionGateway } = useGateways()

  return useMutation({
    mutationFn: ({ screeningFlagId, outcome }: PreInspectionPackagesMutationParams) =>
      preInspectionGateway.updatePreInspectionPackages(screeningFlagId, outcome),
    onError: (reason) => toastApiError(reason),
  })
}
