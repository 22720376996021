import axios, { AxiosResponse } from 'axios'

import OrdersGateway from '@/core/ports/orders'
import { SearchFormData } from '@/pages/ScanPage/ScanPage'
import { OrderSearchPayload, OrdersResponse } from '@/searchResult/gateways/api/models/Order'
import Order from '@/searchResult/models/Order'

import { mapFromApi as mapOrderFromApi } from './mappers/orderMapper'

function search(formData: SearchFormData): Promise<Order[]> {
  return axios
    .post<OrdersResponse, AxiosResponse<OrdersResponse>, OrderSearchPayload>('/v1/orders/search', {
      evtn: formData.evtn || null,
      seller_first_name: formData.sellerFirstName || null,
      seller_last_name: formData.sellerLastName || null,
      buyer_first_name: formData.buyerFirstName || null,
      buyer_last_name: formData.buyerLastName || null,
    })
    .then(({ data }) => data.orders.map(mapOrderFromApi))
}

export default function createApiOrdersGateWay(): OrdersGateway {
  return {
    search,
  }
}
