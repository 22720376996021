import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'

import CourierCard from './CourierCard'
import useGetAllReadyForXRayQuery from './useGetAllReadyForXRayQuery'

export default function XRayPage() {
  const navigate = useNavigate()
  const { data: couriers = [], isFetching, refetch } = useGetAllReadyForXRayQuery()

  return (
    <>
      <AppBar title="X-Ray">
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <Stack spacing={2}>
          {isFetching ? (
            <Loading text="Fetching couriers ready for x-ray" />
          ) : (
            <>
              {couriers.length === 0 ? (
                <Typography variant="caption">No courier are waiting for x-ray</Typography>
              ) : (
                couriers.map((courier) => (
                  <CourierCard
                    key={courier.slug}
                    courier={courier}
                    onXRayCompleted={refetch}
                    onMarkAsCompleted={refetch}
                  />
                ))
              )}
            </>
          )}
        </Stack>
      </Container>
    </>
  )
}
