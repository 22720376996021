import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

import ReturnPackage, {
  SubmitInspectionOutcomeData,
  InspectionAnswers,
} from './models/ReturnPackage'

interface ReturnPackageContextData {
  pack: ReturnPackage
  markAsReceived: UseMutationResult<ReturnPackage, unknown, void, unknown>
  markForDestruction: UseMutationResult<ReturnPackage, unknown, void, unknown>
  markForLiquidation: UseMutationResult<ReturnPackage, unknown, void, unknown>
  confirmZendesk: (zendeskId: string) => Promise<void>
  submitInspectionOutcome: ({
    shouldDestroy,
    inspectionAnswers,
  }: {
    shouldDestroy: boolean
    inspectionAnswers: InspectionAnswers
  }) => Promise<void>
  printBarcode: (packageId: string) => Promise<void>
}

const ReturnPackageContext = createContext<ReturnPackageContextData | undefined>(undefined)

interface ReturnPackageProviderProps {
  initialPackage: ReturnPackage
  children: React.ReactNode
}

function ReturnPackageProvider({ initialPackage, children }: ReturnPackageProviderProps) {
  const [pack, setPack] = useState(initialPackage)

  const { returnPackagesGateway } = useGateways()

  const markAsReceived = useMutation({
    mutationFn: () => returnPackagesGateway.markAsReceived(pack.id),
    onSuccess: (data) => {
      setPack(data)
    },
  })

  const markForDestruction = useMutation({
    mutationFn: () => returnPackagesGateway.markForDestruction(pack.id),
    onSuccess: (data) => setPack(data),
  })

  const markForLiquidation = useMutation({
    mutationFn: () => returnPackagesGateway.markForLiquidation(pack.id),
    onSuccess: (data) => setPack(data),
  })

  async function confirmZendesk(zendeskId: string): Promise<void> {
    await returnPackagesGateway
      .confirmZendesk(pack.id, zendeskId)
      .then(setPack)
      .catch(toastApiError)
  }

  async function printBarcode(packageId: string): Promise<void> {
    await returnPackagesGateway
      .printBarcode(packageId)
      .then(() => {
        toast.success(`Barcode printed successfully`)
      })
      .catch((err) => toastApiError(err))
  }

  async function submitInspectionOutcome({
    shouldDestroy = false,
    inspectionAnswers,
  }: {
    inspectionAnswers: InspectionAnswers
    shouldDestroy: boolean
  }): Promise<void> {
    const packageId = pack.id

    const hasDescriptionComment =
      !inspectionAnswers.doesItemDescriptionMatch &&
      !!inspectionAnswers.comments?.packageDescription

    const hasDamageComment =
      inspectionAnswers.packageDamaged && !!inspectionAnswers.comments?.packageDamaged

    const comments =
      hasDescriptionComment || hasDamageComment
        ? {
            packageDescription: inspectionAnswers.comments?.packageDescription,
            packageDamaged: inspectionAnswers.comments?.packageDamaged,
          }
        : null

    const data: SubmitInspectionOutcomeData = {
      inspection: {
        ...inspectionAnswers,
        comments: comments,
      },
      destroyReturnPackage: !!shouldDestroy,
    }

    await returnPackagesGateway
      .submitInspectionOutcome(packageId, data)
      .then(setPack)
      .catch(toastApiError)
  }

  const data: ReturnPackageContextData = {
    pack,
    markAsReceived,
    markForDestruction,
    markForLiquidation,
    confirmZendesk,
    submitInspectionOutcome,
    printBarcode,
  }

  return <ReturnPackageContext.Provider value={data}>{children}</ReturnPackageContext.Provider>
}

function useReturnPackage() {
  const returnPackageContext = useContext(ReturnPackageContext)

  if (returnPackageContext === undefined) {
    throw new Error('useReturnPackage must be used within a ReturnPackageProvider')
  }

  return returnPackageContext
}

export { ReturnPackageProvider, useReturnPackage }
