import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup'

import FormLabel from './FormLabel'

interface RadioGroupProps extends MuiRadioGroupProps {
  label?: string
  disabled?: boolean
}

export default function RadioGroup({ label, disabled, ...props }: RadioGroupProps) {
  return (
    <FormLabel label={label} disabled={disabled} noControl>
      <MuiRadioGroup {...props} />
    </FormLabel>
  )
}
