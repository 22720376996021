import Chance from 'chance'

import Package from '@/core/models/Package'

const chance = new Chance()

export const packageStub = (): Package => ({
  id: chance.guid(),
  ebayPackageId: chance.string(),
  easyshipShipmentId: chance.string(),
  lastStationId: chance.integer({ min: 1, max: 99 }),
  scanReceived: chance.date().toISOString(),
  firstMileTrackingNumber: chance.string(),
  lastMileTrackingNumber: chance.string(),
  combinedShipment: chance.bool(),
  items: [],
  warehouseState: { value: 'ready_for_xray', displayName: 'ready_for_xray' },
  lastMileCourierName: chance.string(),
  flaggedFor: [],
  screeningFlags: [],
  inspections: [],
  batteryInspectionOutcome: {
    result: 'no_batteries',
    shippingDocumentInstructions: [],
    stickers: [],
    unshippableReasons: [],
    classifications: [],
  },
  labelState: 'not_created',
  evtn: chance.string(),
  senderName: chance.name(),
  receiverAddress: {
    fullName: chance.name(),
    addressLine1: chance.address(),
    addressLine2: chance.address(),
    city: chance.city(),
    state: chance.state(),
    postalCode: chance.postcode(),
    countryCode: chance.country(),
    country: chance.country({ full: true }),
  },
  bin: null,
  weight: { value: chance.natural(), unit: 'lb' },
  dimensions: {
    length: chance.natural(),
    width: chance.natural(),
    height: chance.natural(),
    unit: 'in',
  },
  courierReturn: null,
  repack: null,
  multipack: chance.bool(),
  packageTags: [],
})
