import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import Input from '@/components/Input'
import ManifestCourier from '@/core/models/ManifestCourier'

import CourierBin from './models/CourierBin'
import usePackageDropMutation from './usePackageDropMutation'

interface BinsSummaryProps {
  courierSelected: ManifestCourier
  binsSelected: CourierBin[]
  onBackClick(): void
  onSubmitSettled(): void
}

export default function BinsSummary({
  courierSelected,
  binsSelected,
  onBackClick,
  onSubmitSettled,
}: BinsSummaryProps) {
  const packageDropMutation = usePackageDropMutation()
  const [manifestReferenceId, setManifestReferenceId] = useState<string>()
  const totalWeight = binsSelected.reduce((acc, bin) => acc + bin.packagesWeight.value, 0)
  const totalPackageCount = binsSelected.reduce((acc, bin) => acc + bin.packagesCount, 0)
  const weightUnit = binsSelected[0].packagesWeight.unit

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    packageDropMutation.mutate(
      {
        manifestReferenceId: manifestReferenceId ?? '',
        courierSlug: courierSelected.slug,
        binIds: binsSelected.map((bin) => bin.id),
      },
      {
        onSuccess: handleSubmitSuccess,
        onSettled: onSubmitSettled,
      }
    )
  }
  function handleSubmitSuccess(): void {
    const binNames = binsSelected.map((bin) => bin.name).join(', ')
    toast.success(`All bins for ${binNames} have been marked as dropped`)
  }

  return (
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3" component="span">
                {courierSelected.displayName}
              </Typography>
              <Typography variant="body1">
                <strong>{totalPackageCount}</strong> pcs / <strong>{totalWeight.toFixed(2)}</strong>{' '}
                {weightUnit}s
              </Typography>
            </Stack>
            <Divider light />
            {binsSelected.map((bin) => (
              <Stack direction="row" key={bin.id} justifyContent="space-between">
                <Typography variant="body1">{bin.name}</Typography>
                <Typography variant="body1">
                  <strong>{bin.packagesCount}</strong>
                  {` pcs / `}
                  <strong>{bin.packagesWeight.value}</strong> {bin.packagesWeight.unit}s
                </Typography>
              </Stack>
            ))}
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {courierSelected.requiresManifestId && (
                  <Input
                    placeholder="Manifest Reference ID"
                    value={manifestReferenceId}
                    onChange={(e) => setManifestReferenceId(e.target.value)}
                  />
                )}
                <Stack direction="row" justifyContent="space-between">
                  <Button onClick={onBackClick} variant="text" sx={{ height: 80, width: 270 }}>
                    {'<'} back to previous
                  </Button>
                  <Button
                    disabled={courierSelected.requiresManifestId && !manifestReferenceId}
                    loading={packageDropMutation.isLoading}
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ height: 80, width: 270 }}
                  >
                    confirm dropped
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  )
}
