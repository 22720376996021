import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import COLORS from '@/colors'
import Dialog, { DialogProps } from '@/components/Dialog'
import PackingIcon from '@/components/icons/PackingIcon'
import ScanInput from '@/components/ScanInput'
import { Stat, StatLabel, StatValue } from '@/components/Stat'
import { toastApiError } from '@/components/Toastify'
import { PackageBin } from '@/core/models/Package'
import { CourierIdentifier } from '@/gateways/api/models/Package'

import { usePackage } from '../PackageProvider'

const courierBgColor: Record<CourierIdentifier, string> = {
  Asendia: COLORS.yellowLight,
  'DHL eCommerce': COLORS.greenLight,
  'Pitney Bowes': COLORS.pinkLight,
}

interface BinStyledProps {
  color?: string
  bgcolor: string
  children: React.ReactNode
}

function StyledBin({ color, bgcolor, children }: BinStyledProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        color,
        bgcolor,
        p: 2,
        borderRadius: 2,
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <PackingIcon htmlColor={color} sx={{ fontSize: 40 }} />
        {children}
      </Stack>
    </Stack>
  )
}

function RelabelPackageStep() {
  const { reprintLabel } = usePackage()

  return (
    <Stack spacing={1}>
      <Typography variant="h2">
        <strong>STEP 1: </strong>Relabel package
      </Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography>If no label has been printed,</Typography>
        <Button onClick={reprintLabel} size="small">
          click here to reprint label
        </Button>
      </Stack>
    </Stack>
  )
}

interface PlaceInBinStepProps {
  bin: PackageBin
}

function PlaceInBinStep({ bin }: PlaceInBinStepProps) {
  return (
    <Stack spacing={1}>
      <Typography variant="h2">
        <strong>STEP 2: </strong>Place in <strong>{bin.displayName}</strong> bin
      </Typography>
      <StyledBin bgcolor={courierBgColor[bin.displayName]} color={COLORS.inkDark}>
        <Typography variant="h2" color={COLORS.inkDark}>
          <strong>{bin.name}</strong>
        </Typography>
      </StyledBin>
    </Stack>
  )
}

type PlaceInBinDialogProps = Omit<DialogProps, 'open' | 'title'>

export default function PlaceInBinDialog(props: PlaceInBinDialogProps) {
  const navigate = useNavigate()
  const { pack, placeInBin } = usePackage()

  function handleScanChange(barcode: string) {
    if (barcode !== pack.bin?.barcode) {
      toast.error('Scanned barcode does not match the bin.', { autoClose: false })
      return
    }

    placeInBin(barcode)
      .then(() => {
        navigate('/scan')
        toast.success(
          `Package (${pack.lastMileTrackingNumber}) was successfully added to the bin: ${pack.bin?.displayName} (${pack.bin?.name})`
        )
      })
      .catch(toastApiError)
  }

  return (
    <Dialog
      title={
        pack.bin?.type === 'courier'
          ? `State: ${pack.warehouseState.displayName}`
          : 'Scan to Place Package in Bin'
      }
      open={!!pack.bin}
      {...props}
      maxWidth="xs"
      fullWidth
    >
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Stat horizontal>
            <StatLabel variant="h4">Tracking #</StatLabel>
            <StatValue>{pack.lastMileTrackingNumber}</StatValue>
          </Stat>
          <Stat horizontal>
            <StatLabel variant="h4">Package ID</StatLabel>
            <StatValue>{pack.ebayPackageId}</StatValue>
          </Stat>
        </Stack>
        <Divider light />
        {pack.bin?.type === 'courier' && <RelabelPackageStep />}
        {pack.bin &&
          (pack.bin.type === 'courier' ? (
            <PlaceInBinStep bin={pack.bin} />
          ) : (
            <StyledBin color={COLORS.redDark} bgcolor={COLORS.redLight}>
              <Typography variant="h2" color={COLORS.inkDark}>
                Place in <strong>{pack.bin.displayName}</strong> bin
                <br />
                <strong>{pack.bin.name}</strong>
              </Typography>
            </StyledBin>
          ))}
        <ScanInput
          placeholder="Scan bin ID to place package in bin"
          onChange={handleScanChange}
          autoFocus
        />
      </Stack>
    </Dialog>
  )
}
