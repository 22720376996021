import { Chance } from 'chance'

import { InductionExceptionResult, InductionPassResult } from '@/induction/models/Induction'

const chance = new Chance()

export const inductionPassResultStub = (): InductionPassResult => ({
  status: 'label_processing',
  label: 'Label Processing',
  package: {
    id: chance.guid(),
    firstMileTrackingNumber: '9730 0176 0376 0288 0196 53',
    warehouseState: {
      value: 'received',
      displayName: 'Received',
    },
    weight: {
      value: 0,
      unit: 'lb',
    },
  },
})

export const inductionExceptionResultStub = (): InductionExceptionResult => ({
  status: 'exception',
  label: 'Exception',
  message: 'Package could not be identified',
})
