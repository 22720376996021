import { PackageMeasurementsMetadata } from '@/core/models/Package'
import { MeasureFormData } from '@/pages/PackagePage/MeasureForm'

import { MeasurePayload } from '../models/Package'

export function mapToApi(
  formData: MeasureFormData,
  metadata: PackageMeasurementsMetadata | undefined
): MeasurePayload {
  const hasLength = formData.length !== ''
  const hasWidth = formData.width !== ''
  const hasHeight = formData.height !== ''
  const hasDimensions = hasLength || hasHeight || hasWidth

  return {
    package: {
      weight: { value: formData.weight, unit: 'lb' },
      dimensions: {
        length: formData.length !== '' ? formData.length : null,
        width: formData.width !== '' ? formData.width : null,
        height: formData.height !== '' ? formData.height : null,
        unit: hasDimensions ? 'in' : null,
      },
      metadata: {
        measurement_id: metadata?.measurementId ?? null,
        scanned_on: metadata?.scannedOn ?? null,
      },
    },
  }
}
