import { useQuery } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

export default function useCreateNewPackageQuery(orderId: string) {
  const { searchResultGateway } = useGateways()

  const query = useQuery({
    queryKey: ['createNewPackage', orderId],
    queryFn: () => searchResultGateway.createPackage(orderId),
    enabled: false,
    onError: toastApiError,
  })

  return query
}
