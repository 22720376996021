import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import COLORS from '@/colors'
import AppBar from '@/components/AppBar'
import ItemsList from '@/components/ItemsList'
import Spacer from '@/components/Spacer'
import Stack from '@/components/Stack'

import BarcodeIcon from '../../assets/barcode.svg'
import PackageDataList, { PackageDataItem } from '../pages/PackagePage/PackageDataList'
import InspectionQuestions from './InspectionQuestions'
import ReturnPackage from './models/ReturnPackage'
import ReturnInspectionFlag from './ReturnInspectionFlag'
import { ReturnPackageProvider, useReturnPackage } from './ReturnPackageProvider'
import ReturnReceive from './ReturnReceive'

interface PackageDataInfoProps {
  pack: ReturnPackage
}

function PackageDataInfo({ pack }: PackageDataInfoProps) {
  const packageListData: PackageDataItem[] = [
    { label: 'Return Tracking No.', value: pack.trackingNumber },
    { label: 'Easyship Shipment ID', value: pack.easyshipShipmentId },
    { label: 'Return EVTN', value: pack.returnEvtn },
    {
      label: 'Contents',
      value: <ItemsList items={pack.items} />,
    },
  ]

  return <PackageDataList data={packageListData} />
}

interface PackageOutboundDataInfoProps {
  pack: ReturnPackage
}

function PackageOutboundDataInfo({ pack }: PackageOutboundDataInfoProps) {
  const packageListData: PackageDataItem[] = [
    { label: 'Outbound EVTN', value: pack.outboundEvtn },
    { label: 'Outbound eBay Package ID', value: pack.outboundEbayPackageIds.join(', ') },
  ]

  return <PackageDataList data={packageListData} />
}

export type NavigationStateParams = Partial<{ package: ReturnPackage }> | undefined

function ReturnPackagePage() {
  const navigate = useNavigate()
  const { pack, printBarcode } = useReturnPackage()
  const showReceivedButton = pack.warehouseState.value === 'not_received'
  const showInspectionFlag = pack.warehouseState.value === 'received' && !pack.inspection
  const showInspectionSteps = pack.inspection?.status === 'to_inspect'
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl

  function handleClose() {
    setAnchorEl(null)
  }

  function handlePrintBarcode(packageId: string) {
    printBarcode(packageId)
    handleClose()
  }

  return (
    <>
      <AppBar>
        <Button variant="text" onClick={() => navigate('/scan')}>
          back to scan
        </Button>
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <Stack spacing={2} divider={<Divider light />}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <div>
              <Typography variant="h1" component="span">
                State:{' '}
              </Typography>
              <Typography variant="h2" component="span">
                {pack.warehouseState.displayName}
              </Typography>
            </div>
            <Chip label="Return" color="primary" size="small" />
          </Stack>
          <Stack direction="row">
            <Box flex={1}>
              <PackageDataInfo pack={pack} />
            </Box>
            {pack.warehouseState.value === 'to_be_liquidated' && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  Actions
                </Button>
                <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
                  <MenuItem
                    onClick={() => handlePrintBarcode(pack.id)}
                    sx={{ color: COLORS.black }}
                  >
                    <img
                      src={BarcodeIcon}
                      height={19}
                      style={{
                        maxWidth: '100%',
                        objectFit: 'contain',
                        marginRight: 10,
                        color: COLORS.black,
                      }}
                    />
                    Print barcode
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
          <div>
            <PackageOutboundDataInfo pack={pack} />
          </div>
          <div>
            {showReceivedButton && <ReturnReceive />}
            {showInspectionFlag && <ReturnInspectionFlag />}
            {showInspectionSteps && <InspectionQuestions />}
          </div>
        </Stack>
      </Container>
    </>
  )
}

export default function ReturnPackagePageWrapper() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams

  useEffect(() => {
    if (!navigationState?.package) {
      navigate('/scan')
    }
  }, [])

  if (!navigationState?.package) return null

  return (
    <ReturnPackageProvider initialPackage={navigationState.package}>
      <ReturnPackagePage />
    </ReturnPackageProvider>
  )
}
