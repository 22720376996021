import Chance from 'chance'

import { Repack } from '@/core/models/Repack'

const chance = new Chance()

export const repackStub = (): Repack => ({
  id: chance.guid(),
  ebayPackingMaterialId: chance.guid(),
  packageId: chance.guid(),
  upc: chance.string(),
  height: chance.integer(),
  width: chance.integer(),
  length: chance.integer(),
  unit: 'in',
  typeOf: 'box',
  reason: 'package_damaged',
})
