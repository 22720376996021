import WarningIcon from '@mui/icons-material/WarningAmberRounded'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import COLORS from '@/colors'
import Collapse from '@/pages/PackagePage/Collapse'
import PhotoInstruction from '@/pages/PackagePage/PackageInspection/PhotoInstruction'

export default function PhotoGuideLine({ Category }: { Category: string }) {
  return (
    <Box p={2} borderRadius={2} bgcolor={COLORS.pinkLight}>
      <Stack direction="row" spacing={1}>
        <WarningIcon fontSize="large" sx={{ color: COLORS.pinkDark }} />
        <Stack flex={1} spacing={1} divider={<Divider />}>
          <>
            <Typography variant="h3" textTransform="uppercase">
              INSPECTION
            </Typography>
            <Typography variant="h2">{Category}</Typography>
          </>
          <Collapse title="Photo Guidelines" defaultOpen>
            <PhotoInstruction sx={{ my: 1 }} />
          </Collapse>
        </Stack>
      </Stack>
    </Box>
  )
}
