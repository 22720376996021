import axios from 'axios'

import Session from '../../core/models/Session'
import SessionGateway from '../../core/ports/session'
import { SessionResponse } from './models/Session'

function login(stationId: string, password: string): Promise<Session> {
  return axios
    .post<SessionResponse>('/v1/sessions', { station_id: stationId, token: password })
    .then(({ data }) => {
      const { session } = data

      return {
        token: session.token,
        expiresAt: session.expires_at,
        stationId: session.station_id,
        user: {
          hub: {
            id: session.user.hub.id,
            name: session.user.hub.admin_name,
          },
          firstName: session.user.first_name,
          lastName: session.user.last_name,
          state: session.user.state,
        },
      }
    })
}

function logout(): Promise<void> {
  return axios.post('/v1/sessions/logout')
}

export default function createApiSessionGateway(): SessionGateway {
  return { login, logout }
}
