import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'

import { usePackage } from './PackageProvider'

interface ActionItem {
  label: string
  isVisible: boolean
  callback: () => void
}

export default function PackageDropdownActions() {
  const {
    pack,
    printBarcode,
    reprintLabel,
    resetToMeasureStep,
    markAsOverLimit,
    forceDestroyLiquidate,
  } = usePackage()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl
  const actions: ActionItem[] = [
    {
      label: 'Print Barcode',
      isVisible: pack.warehouseState.value !== 'not_received',
      callback: () => closeDropdownWithCallback(printBarcode),
    },
    {
      label: 'Print Shipping Label',
      isVisible: pack.labelState === 'generated',
      callback: () => closeDropdownWithCallback(reprintLabel),
    },
    {
      label: 'Re-measure',
      isVisible: pack.labelState === 'failed',
      callback: resetToMeasureStep,
    },
    {
      label: 'Mark as Over Limit',
      isVisible: true,
      callback: markAsOverLimit,
    },
    {
      label: 'Ready for destroy/liquidate',
      isVisible:
        pack.warehouseState.value === 'label_failed' ||
        pack.warehouseState.value === 'dropped' ||
        pack.warehouseState.value === 'xray_completed',
      callback: forceDestroyLiquidate,
    },
  ]
  const isDropdownEmpty = actions.every((action) => !action.isVisible)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function closeDropdownWithCallback(callback: () => void) {
    callback()
    setAnchorEl(null)
  }

  if (isDropdownEmpty) return null

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {actions.map(
          (action) =>
            action.isVisible && (
              <MenuItem key={action.label} onClick={action.callback}>
                {action.label}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  )
}
