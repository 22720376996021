import ErrorIcon from '@mui/icons-material/Error'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import cargoAircraftOnlyUrl from '../../../assets/cargo_aircraft_only.png'
import dangerousGoodsClass9Un3090Url from '../../../assets/dangerous_goods_class_9_un3090.png'
import dangerousGoodsClass9Un3091Url from '../../../assets/dangerous_goods_class_9_un3091.png'
import dangerousGoodsClass9Un3480Url from '../../../assets/dangerous_goods_class_9_un3480.png'
import dangerousGoodsClass9Un3481Url from '../../../assets/dangerous_goods_class_9_un3481.png'
import lithiumBatteryUn3090Url from '../../../assets/lithium_battery_un3090.png'
import lithiumBatteryUn3091Url from '../../../assets/lithium_battery_un3091.png'
import lithiumBatteryUn3480Url from '../../../assets/lithium_battery_un3480.png'
import lithiumBatteryUn3481Url from '../../../assets/lithium_battery_un3481.png'
import sectionIiPi966Url from '../../../assets/section_ii_pi966.svg'
import sectionIiPi967Url from '../../../assets/section_ii_pi967.svg'
import sectionIiPi969Url from '../../../assets/section_ii_pi969.svg'
import sectionIiPi970Url from '../../../assets/section_ii_pi970.svg'
import { BatteryInspectionSticker } from '../../gateways/api/models/Package'
import { usePackage } from './PackageProvider'

const batteryStickerUrlMap: Record<BatteryInspectionSticker, string> = {
  cargo_aircraft_only: cargoAircraftOnlyUrl,
  section_ii_pi966: sectionIiPi966Url,
  section_ii_pi967: sectionIiPi967Url,
  section_ii_pi969: sectionIiPi969Url,
  section_ii_pi970: sectionIiPi970Url,
  dangerous_goods_class_9_un3090: dangerousGoodsClass9Un3090Url,
  dangerous_goods_class_9_un3091: dangerousGoodsClass9Un3091Url,
  dangerous_goods_class_9_un3480: dangerousGoodsClass9Un3480Url,
  dangerous_goods_class_9_un3481: dangerousGoodsClass9Un3481Url,
  lithium_battery_un3090: lithiumBatteryUn3090Url,
  lithium_battery_un3091: lithiumBatteryUn3091Url,
  lithium_battery_un3480: lithiumBatteryUn3480Url,
  lithium_battery_un3481: lithiumBatteryUn3481Url,
}

interface AffixBatteryStickersProps {
  stickers: BatteryInspectionSticker[]
}

export default function AffixBatteryStickers({ stickers }: AffixBatteryStickersProps) {
  const { requestLabel } = usePackage()

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="stretch">
      <Stack direction="row" spacing={1}>
        <ErrorIcon color="error" />
        <Stack spacing={2}>
          <Typography variant="h1">Affix Battery Stickers</Typography>
          <div>The following stickers are required on this package:</div>
          <Grid container spacing={1}>
            {stickers.map((sticker) => (
              <Grid container item key={sticker} xs={6} justifyContent="center">
                <img
                  src={batteryStickerUrlMap[sticker]}
                  alt={sticker}
                  height={150}
                  style={{ maxWidth: '100%', objectFit: 'contain' }}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
      <Button onClick={requestLabel} variant="contained" sx={{ minWidth: 100, height: 50 }}>
        Done
      </Button>
    </Stack>
  )
}
