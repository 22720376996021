import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'

export const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.25,
  color: theme.palette.ink.normal,
  minWidth: 160,
}))

type StatValueProps = TypographyProps
export function StatValue({ children, ...typographyProps }: StatValueProps) {
  return (
    <Typography
      component="span"
      sx={{
        wordBreak: 'break-all',
      }}
      {...typographyProps}
    >
      {children}
    </Typography>
  )
}

interface StatProps {
  horizontal?: boolean
  children: React.ReactNode
  className?: string
}

export function Stat({ horizontal = false, children, className }: StatProps) {
  return (
    <Stack
      className={className}
      direction={horizontal ? 'row' : 'column'}
      spacing={horizontal ? 1.25 : 0.5}
    >
      {children}
    </Stack>
  )
}
