import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface BinValidationMutationParams {
  packageBarcode: string
  binBarcode: string
}

export function useBinValidationMutation() {
  const { courierSortGateway } = useGateways()

  return useMutation({
    mutationFn: ({ packageBarcode, binBarcode }: BinValidationMutationParams) =>
      courierSortGateway.validateBin(packageBarcode, binBarcode),
    onError: (reason) => toastApiError(reason),
  })
}
