import { Typography } from '@mui/material'
import Button from '@mui/material/Button'

interface BackButtonProp {
  onBackClick?: () => void
}

export default function BackButton({ onBackClick }: BackButtonProp) {
  return (
    <Button
      variant="text"
      color="secondary"
      aria-label="go back"
      sx={{ height: 80, alignItem: 'space-between' }}
      onClick={onBackClick}
    >
      <Typography variant="h3"> &lt; back</Typography>
    </Button>
  )
}
