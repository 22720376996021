import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import Input from '@/components/Input'
import { InspectionSteps } from '@/returnPackage/models/ReturnPackage'

import BackButton from '../BackButton'
import { useInspectionQuestions } from '../InspectionQuestionsProvider'

export const ConfirmIncorrectQuantity = () => {
  const { setCurrentStep, inspectionAnswers, setInspectionAnswers } = useInspectionQuestions()

  function handleBackClick() {
    setInspectionAnswers((prevState) => ({
      ...prevState,
      returnedItemQuantity: null,
    }))
    setCurrentStep(InspectionSteps.QUANTITY_MATCH)
  }

  const handleReturnedItemQuantity = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.target.value.match('^[1-9.,][0-9.,]*$')) {
      setInspectionAnswers((prevState) => ({
        ...prevState,
        returnedItemQuantity: parseInt(e.target.value),
      }))
    } else {
      setInspectionAnswers((prevState) => ({
        ...prevState,
        returnedItemQuantity: null,
      }))
    }
  }
  return (
    <Stack spacing={6}>
      <h1>Inspection Result: Incorrect Quantity</h1>
      <Input
        InputProps={{ inputProps: { min: 0 }, type: 'number' }}
        label="Returned Qty"
        type="number"
        autoFocus
        onChange={handleReturnedItemQuantity}
      />
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <BackButton onBackClick={handleBackClick} />
        <Button
          variant="contained"
          color="secondary"
          sx={{ height: 80, minWidth: 270, alignItem: 'space-between' }}
          disabled={!inspectionAnswers.returnedItemQuantity}
          onClick={() => {
            setCurrentStep(InspectionSteps.PACKAGE_DAMAGE)
          }}
        >
          next
        </Button>
      </Stack>
    </Stack>
  )
}
