import { useEffect, useRef, useState } from 'react'

import useDebounce from '@/hooks/useDebounce'

interface ScanParams {
  enabled?: boolean
}

export function useScan(params?: ScanParams): string {
  const { enabled = true } = params ?? {}
  const [scanValue, setScanValue] = useState('')
  const scanValueDebounced = useDebounce(scanValue, 300)
  const resetRef = useRef(false)

  useEffect(() => {
    function handleKeystroke(e: KeyboardEvent) {
      const character = e.key
      if (resetRef.current) {
        resetRef.current = false

        setScanValue(character)
      } else {
        setScanValue((scanValue) => scanValue + character)
      }
    }

    enabled && document.addEventListener('keydown', handleKeystroke)
    return () => document.removeEventListener('keydown', handleKeystroke)
  }, [enabled])

  useEffect(() => {
    resetRef.current = true
  }, [scanValueDebounced])

  return scanValueDebounced
}
