import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import React from 'react'

import FormLabel from './FormLabel'
import Pulse from './Pulse'

export interface InputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  label?: React.ReactNode
  unit?: string
  loading?: boolean
}

export default function Input({ label, unit, loading, disabled, ...props }: InputProps) {
  const endAdornment = unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined

  return (
    <FormLabel fullWidth label={label} disabled={disabled || loading}>
      <Box position="relative" display="flex" alignItems="center" justifyContent="center">
        <TextField
          inputProps={{ 'data-hj-allow': true }}
          variant="outlined"
          fullWidth
          autoComplete="off"
          InputProps={{ endAdornment }}
          disabled={disabled || loading}
          {...props}
        />
        {loading && <Pulse sx={{ position: 'absolute' }} />}
      </Box>
    </FormLabel>
  )
}
