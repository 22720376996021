import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

export default function useMarkAsReceivedMutation() {
  const { searchResultGateway } = useGateways()

  return useMutation({
    mutationFn: searchResultGateway.markAsReceived,
    onError: (reason) => toastApiError(reason),
  })
}
