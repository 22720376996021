import ErrorIcon from '@mui/icons-material/Error'
import { MenuItem } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import Button from '@/components/Button'
import Select from '@/components/Select'
import { InspectionCategory, InspectionCategoryDefined } from '@/gateways/api/models/Package'

import { usePackage } from '../PackageProvider'

const exceptionTypeOptions: Record<InspectionCategoryDefined, string> = {
  prohibited: 'Prohibited Good (PG)',
  dangerous: 'Dangerous Good (DG)',
  battery: 'Battery',
}

export default function PackageXRayInspection() {
  const { pack, bin, categorizeCurrentInspection, dismissCurrentInspection } = usePackage()
  const [exceptionType, setExceptionType] = useState<InspectionCategory>('')
  const areAllInspectionsCategorized = pack.inspections.every(
    (inspection) => inspection.status !== 'requested',
  )
  const hasPlaceInBinVisible: boolean = !!pack.bin || !!bin

  if (
    areAllInspectionsCategorized ||
    hasPlaceInBinVisible ||
    pack.warehouseState.value !== 'inspection_requested'
  )
    return null

  return (
    <>
      <Stack direction="row" spacing={1}>
        <ErrorIcon color="error" />
        <Typography variant="h1">Inspection Required</Typography>
      </Stack>
      <Select
        value={exceptionType}
        onChange={(e) => setExceptionType(e.target.value as InspectionCategoryDefined)}
        placeholder="Exception Type"
        aria-label="Exception Type"
      >
        {Object.entries(exceptionTypeOptions).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => dismissCurrentInspection()}
          variant="contained"
          color="secondary"
          sx={{ height: 80 }}
          fullWidth
        >
          process forward
        </Button>
        <Button
          onClick={() => categorizeCurrentInspection(exceptionType as InspectionCategoryDefined)}
          variant="contained"
          color="secondary"
          sx={{ height: 80 }}
          disabled={!exceptionType}
          fullWidth
        >
          continue inspection
        </Button>
      </Stack>
    </>
  )
}
