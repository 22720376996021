import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

interface PackageNotFoundPageProps {
  barcode: string
}

export default function PackageNotFoundResult({ barcode }: PackageNotFoundPageProps) {
  return (
    <Stack alignItems="center" justifyContent="center">
      <Typography fontSize={45} fontWeight="bold">
        Package Not Found
      </Typography>
      <Typography fontSize={30} fontWeight="bold">
        {barcode}
      </Typography>
    </Stack>
  )
}
