import Stack from '@mui/material/Stack'
import { useLocation, useNavigate } from 'react-router-dom'

import Package from '@/core/models/Package'

import Order from '../models/Order'
import { NavigationStateParams } from '../SearchResultPage'
import CreateNewPackageButton from './CreateNewPackageButton'
import RegularOrderData from './RegularOrderData'
import RegularPackageData from './RegularPackageData'

interface RegularPackageResultProp {
  order: Order
  index: number
}

export default function RegularPackageResult({ order, index }: RegularPackageResultProp) {
  const { state } = useLocation()
  const navigate = useNavigate()
  const navigationState = state as NavigationStateParams

  function routeToPackagePage(pack: Package) {
    const searchParams = navigationState?.searchParams
    navigate('/package', {
      state: { package: pack, searchParams },
    })
  }

  return (
    <Stack spacing={1.5}>
      <RegularOrderData order={order} idx={index + 1} />

      {order.packages.map((pack, index) => (
        <RegularPackageData key={pack.id} pack={pack} idx={index + 1} />
      ))}
      <CreateNewPackageButton orderId={order.id} onCreateNewPackageSuccess={routeToPackagePage} />
    </Stack>
  )
}
