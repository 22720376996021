import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

import Button from '@/components/Button'

import ScanInspectionForm from './ScanInspectionForm'

export interface XrayCompletionFormProps {
  onMarkAsCompleted(): Promise<void>
  onScanInspectionSubmit(barcodes: string[]): Promise<void>
}

export default function XrayCompletionForm({
  onMarkAsCompleted,
  onScanInspectionSubmit,
}: XrayCompletionFormProps) {
  const [isCompleteXRayVisible, setIsCompleteXRayVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function handleMarkAsCompleted() {
    setIsLoading(true)
    try {
      await onMarkAsCompleted()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Stack spacing={1}>
      {isCompleteXRayVisible ? (
        <>
          <Button onClick={handleMarkAsCompleted} variant="contained" loading={isLoading}>
            set as x-ray completed
          </Button>
          <Button onClick={() => setIsCompleteXRayVisible(false)} startIcon={<ChevronLeftIcon />}>
            back: mark packages for inspection
          </Button>
        </>
      ) : (
        <>
          <ScanInspectionForm onSubmit={onScanInspectionSubmit} />
          <Button onClick={() => setIsCompleteXRayVisible(true)} endIcon={<ChevronRightIcon />}>
            if no packages require inspection, complete the x-ray process
          </Button>
        </>
      )}
    </Stack>
  )
}
